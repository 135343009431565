import { IronButtonState } from '@polymer/iron-behaviors/iron-button-state.js'
import { IronControlState } from '@polymer//iron-behaviors/iron-control-state.js'
import MouseOverBehavior from '../../../utils/wolf-mouse-over-behavior.js'

const HomeMenuButtonBehaviorImpl = {
  hostAttributes: {
    role: 'button',
    tabindex: '0',
  },

  properties: {
    size: {
      type: String,
      value: 'large',
      reflectToAttribute: true,
    },
  },

  observers: ['_computeKeyboardClass(receivedFocusFromKeyboard)'],

  _computeKeyboardClass: function (receivedFocusFromKeyboard) {
    if (window.cordova && cordova.platformId === 'ios') {
      return
    }
    this.toggleClass('keyboard-focus', receivedFocusFromKeyboard)
  },
}

const HomeMenuButtonBehavior = [
  MouseOverBehavior,
  IronButtonState,
  IronControlState,
  HomeMenuButtonBehaviorImpl,
]
export default HomeMenuButtonBehavior
