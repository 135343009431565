import { PolymerElement, html } from '@polymer/polymer/polymer-element.js'
import '@polymer/app-layout/app-toolbar/app-toolbar.js'
import '@polymer/paper-icon-button/paper-icon-button.js'
import '@polymer/paper-dropdown-menu/paper-dropdown-menu.js'
import '@polymer/iron-selector/iron-selector.js'
import '@polymer/paper-listbox/paper-listbox.js'
import '@polymer/paper-item/paper-item.js'
import '@polymer/iron-icon/iron-icon.js'
import '../../utils/wolf-colors.js'
import '../../utils/wolf-icons.js'
import '../../utils/wolf-heading-bar.js'
import '../../utils/wolf-text.js'
import '../../utils/wolf-progress.js'
import '../../utils/fonts/wolf-fonts.js'
import '../../utils/wolf-app-toolbar'
import translated from '../../mixins/translated.js'
import '../../utils/wolf-watch-object.js'
import navigate from '../../mixins/navigate.js'
import { logEvent } from '../../utils/facebook-tracking/facebook-tracking.js'

class ErrorCodeInspectorPage extends navigate(translated(PolymerElement)) {
  static get template() {
    return html([require('./error-code-inspector-page.html')])
  }

  static get is() {
    return 'error-code-inspector-page'
  }

  static get properties() {
    return {
      routeState: { type: Object, notify: true },
      store: { type: Object },
      _loading: {
        type: Boolean,
        reflectToAttribute: true,
        value: true,
      },
      _selectedErrorCodeElement: {
        type: Object,
      },
      _selectedErrorCode: {
        type: String,
        value: null,
        computed: '_getItemPropertyFromElement(_selectedErrorCodeElement)',
      },
      _instructionsForErrorCode: {
        type: Array,
        value: [],
        computed: '_computeInstructionsForErrorCode(loading,' + '_selectedErrorCode)',
        observer: '_onInstructionChange',
      },
      _selectedInstructionElement: {
        type: Object,
      },
      _selectedInstruction: {
        type: String,
        value: null,
        computed: '_getItemPropertyFromElement(_selectedInstructionElement)',
      },
    }
  }

  ready() {
    super.ready()
    this.store.load(['errorCodes']).then(() => (this._loading = false))
    this.addEventListener('wolf-back', this._onBack)
    this.addEventListener('animated-pages-page-did-enter', this._didEnter)
  }

  _didEnter() {
    logEvent('visit_error_code_inspector')
  }

  _showDocument(event) {
    const page = event.currentTarget.getAttribute('data-document')
    this.dispatchEvent(
      new CustomEvent('wolf-show-error-codes-document', {
        detail: page,
        composed: true,
        bubbles: true,
      })
    )
  }

  _getItemPropertyFromElement(element) {
    return element && element.item
  }

  _computeInstructionsForErrorCode(loading, selectedErrorCode) {
    if (loading || !selectedErrorCode) {
      return []
    }
    return this.store.errorCodeInstructionsByErrorCode.get(selectedErrorCode)
  }

  _onInstructionChange(entries) {
    this.$.productListbox.selected = null
    setTimeout(
      function () {
        // Do it async, otherwise it wont update properly -.-
        if (entries && entries.length === 1) {
          // Select the first and only choice
          this.$.productListbox.selected = 0
        }
      }.bind(this)
    )
  }

  replaceTextPlaceholders(text) {
    if (typeof text !== 'string') {
      return
    }

    return text.replace(/{{sku ([^\s]*)}}/g, (match, sku) => {
      return `<a href="#${this.linkToSparePart(sku, 'error-code-inspector')}">${sku}</a>`
    })
  }

  _onBack() {
    this.navigateTo('/')
  }
}
customElements.define(ErrorCodeInspectorPage.is, ErrorCodeInspectorPage)
