const { Capacitor } = require('@capacitor/core')

document.addEventListener('WebComponentsReady', () => {
  // Patch for marked element (Polymer 3 version is currently broken)
  window.marked = require('marked')
  //TODO: Check how we can transform this for Capacitor
  if (window.isCordova) {
    // Set in index.html
    document.addEventListener('deviceready', () => {
      console.log('Device ready')

      if (Capacitor.getPlatform() === 'ios') {
        cordova.plugins.idfa.getInfo().then((info) => {
          if (info.trackingPermission !== cordova.plugins.idfa.TRACKING_PERMISSION_NOT_DETERMINED) {
            localStorage.setItem('decidedCookiesVersion', 'iOSTrackingDecisionMade')
            const acceptedCookies =
              info.trackingPermission === cordova.plugins.idfa.TRACKING_PERMISSION_AUTHORIZED
            localStorage.setItem('acceptedCookies', '' + acceptedCookies)
          }
          startApp()
        })
      } else {
        startApp()
      }
    })
  } else {
    startApp()
  }
})

function startApp() {
  require('./main/service-app.js')
}
