export default function xhrGet(url, progressCallback) {
  return new Promise(function (resolve, reject) {
    const xhr = new XMLHttpRequest()

    xhr.addEventListener('progress', (event) => {
      if (event.lengthComputable && progressCallback) {
        progressCallback((event.loaded / event.total) * 100)
      }
    })

    xhr.addEventListener('load', () => {
      if (xhr.status < 200 || xhr.status >= 300) {
        reject(`Unsuccessful XHR: Status ${xhr.status}`)
      } else {
        resolve(xhr.responseText)
      }
    })

    xhr.addEventListener('error', (error) => {
      reject(error)
    })

    xhr.addEventListener('abort', () => {
      reject()
    })

    xhr.open('GET', url)
    if (url.split('.').pop() === 'json') {
      xhr.setRequestHeader('Content-type', 'application/json')
    }
    xhr.send()
  })
}
