const $_documentContainer = document.createElement('div')
$_documentContainer.setAttribute('style', 'display: none;')

$_documentContainer.innerHTML = `<custom-style>
  <style is="custom-style">
    :root {
      --wolf-card-shadow: {
        border: 1px solid #ccc;
        box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
      };
    }
  </style>
</custom-style>`

document.head.appendChild($_documentContainer)
