import translations from '../translations/translations.js'

export const appLocales = [
  'cs-CZ',
  'de-AT',
  'de-BE',
  'de-CH',
  'de-DE',
  'en-DE',
  'es-ES',
  'en-UK',
  'fr-BE',
  'fr-CH',
  'fr-FR',
  'it-CH',
  'it-IT',
  'nl-BE',
  'nl-NL',
  'pl-PL',
  'ru-RU',
  'sk-SK',
  'zh-CN',
]

let chosenLocale = localStorage.getItem('locale')
if (appLocales.indexOf(chosenLocale) === -1) {
  localStorage.removeItem('locale')
  chosenLocale = 'auto'
}

const autoLocale = (function computeAutoLocale() {
  let autoLocale = 'en-DE' // Default
  let browserLocales = (navigator.languages || []).slice(0)
  browserLocales.push(navigator.language || navigator.userLanguage || '')

  for (let browserLocale of browserLocales) {
    if (browserLocale.indexOf('-') === -1) {
      browserLocale = browserLocale + '-' + browserLocale.toUpperCase()
    }

    if (appLocales.indexOf(browserLocale) !== -1) {
      autoLocale = browserLocale
      break
    }
  }

  return autoLocale
})()

const locale = chosenLocale === 'auto' ? autoLocale : chosenLocale
const language = locale.split('-')[0]

const mergedTranslations = Object.assign(
  {},
  translations['_'],
  translations[language],
  translations[locale]
)
for (let key of Object.keys(mergedTranslations)) {
  const value = mergedTranslations[key]
  if (typeof value === 'function') {
    mergedTranslations[key] = value.bind(mergedTranslations)
  }
}

const localeChoices = (function computeLocaleChoices() {
  const localeChoices = [
    {
      id: 'auto',
      locale: autoLocale,
      title: translations[autoLocale].autoLocale,
    },
  ]
  for (let appLocale of appLocales) {
    localeChoices.push({
      id: appLocale,
      locale: appLocale,
      title: translations[appLocale].localeTitle,
    })
  }
  localeChoices.sort((a, b) => a.title.localeCompare(b.title))
  return localeChoices
})()

export default function translated(Klass) {
  return class Translated extends Klass {
    chooseLocaleAndReload(locale) {
      if (locale === 'auto') {
        localStorage.removeItem('locale')
        location.reload()
      } else if (appLocales.indexOf(locale) !== -1) {
        localStorage.setItem('locale', locale)
        location.reload()
      }
    }

    get chosenLocale() {
      return chosenLocale
    }
    get locale() {
      return locale
    }
    get language() {
      return language
    }
    get translations() {
      return mergedTranslations
    }
    get t() {
      return mergedTranslations
    }
    get localeChoices() {
      return localeChoices
    }
  }
}
