import { PolymerElement, html } from '@polymer/polymer/polymer-element.js'
import { mixinBehaviors } from '@polymer/polymer/lib/legacy/class.js'
import { IronResizableBehavior } from '@polymer/iron-resizable-behavior/iron-resizable-behavior.js'
import MouseGesture from './mouse-gesture.js'
import TouchGesture from './touch-gesture.js'
import Snappy from './snappy.js'

/*
ToDo:
- Make contentX and contentY settable
- Enforce min and max scale when physics are used
*/

const SuperClass = mixinBehaviors([IronResizableBehavior], PolymerElement)

class ZoomView extends SuperClass {
  static get template() {
    return html([require('./zoom-view.html')])
  }

  static get is() {
    return 'zoom-view'
  }

  static get properties() {
    return {
      viewportWidth: { type: Number, readOnly: true, notify: true },
      viewportHeight: { type: Number, readOnly: true, notify: true },
      contentWidth: { type: Number, readOnly: true, notify: true },
      contentHeight: { type: Number, readOnly: true, notify: true },
      contentX: { type: Number, readOnly: true, notify: true, value: 0 },
      contentY: { type: Number, readOnly: true, notify: true, value: 0 },
      contentScale: { type: Number, notify: true, value: 1 },
      minContentScale: { type: Number, value: 1 },
      maxContentScale: { type: Number, value: 1 },
      paddingTop: { type: Number, value: 0, observer: '_onPadddingChange' },
      paddingRight: { type: Number, value: 0, observer: '_onPadddingChange' },
      paddingBottom: { type: Number, value: 0, observer: '_onPadddingChange' },
      paddingLeft: { type: Number, value: 0, observer: '_onPadddingChange' },
      grabbing: {
        type: Boolean,
        value: false,
        reflectToAttribute: true,
        readOnly: true,
        notify: true,
      },
    }
  }

  static get observers() {
    return [
      '_onTransformChange(contentX, contentY, contentScale)',
      '_onSizeChange(viewportWidth, viewportHeight, ' + 'contentWidth, contentHeight)',
    ]
  }

  constructor() {
    super()
    this._activeGesture = null
    this._snappies = [new Snappy(), new Snappy()]
    this._isSomethingVisible = 0
  }

  ready() {
    super.ready()
    this.addEventListener('iron-resize', this._updateSizes)
  }

  connectedCallback() {
    super.connectedCallback()

    this._gestures = [TouchGesture, MouseGesture].map((Gesture) => {
      const gesture = new Gesture(this, {
        startCallback: () => {
          if (!this._activeGesture) {
            this._activeGesture = gesture
            this._setGrabbing(true)
          }
        },
        updateCallback: (input) => {
          if (this._activeGesture !== gesture) {
            return
          }
          this._updateSizes()
          let deltaScale = input.deltaScale
          const deltaX = input.deltaPosition[0]
          const deltaY = input.deltaPosition[1]
          const centerX = input.center[0]
          const centerY = input.center[1]

          const oldScale = this.contentScale
          const newScale = this._clampScale(oldScale * deltaScale)
          this.contentScale = newScale
          deltaScale = newScale / oldScale

          this._setContentX(centerX - (centerX - this.contentX) * deltaScale + deltaX)
          this._setContentY(centerY - (centerY - this.contentY) * deltaScale + deltaY)
        },
        endCallback: (input) => {
          if (this._activeGesture !== gesture) {
            return
          }
          this._activeGesture = null
          this._setGrabbing(false)
          this._snappies[0].reset(this.contentX - this.paddingLeft, input.velocity[0])
          this._snappies[1].reset(this.contentY - this.paddingTop, input.velocity[1])
        },
      })
      return gesture
    })

    this._update()
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    cancelAnimationFrame(this._rafId)
    this._gestures.forEach((g) => g.destroy())
    this._gestures = []
  }

  _update() {
    this._rafId = requestAnimationFrame(this._update.bind(this))
    if (!this._activeGesture) {
      // Update using physics
      this._updateSizes()

      const paddingX = this.paddingLeft + this.paddingRight
      const snapX = computeSnapPosition(
        this._viewportWidthMinusPadding,
        this.contentWidth * this.contentScale,
        this.contentX
      )
      if (snapX != null) {
        this._snappies[0].snapPosition = snapX
      }
      const paddingLeft = Math.min(this.paddingLeft, this.viewportWidth / 2)
      this._setContentX(paddingLeft + this._snappies[0].update().position)

      const paddingY = this.paddingTop + this.paddingBottom
      const snapY = computeSnapPosition(
        this._viewportHeightMinusPadding,
        this.contentHeight * this.contentScale,
        this.contentY
      )
      if (snapY != null) {
        this._snappies[1].snapPosition = snapY
      }
      const paddingTop = Math.min(this.paddingTop, this.viewportHeight / 2)
      this._setContentY(paddingTop + this._snappies[1].update().position)
    }
  }

  _updateSizes() {
    const oldViewportWidth = this.viewportWidth
    const oldViewportHeight = this.viewportHeight
    const oldContentWidth = this.contentWidth
    const oldContentHeight = this.contentHeight
    const newViewportWidth = this.offsetWidth
    const newViewportHeight = this.offsetHeight
    const newContentWidth = this.$.content.offsetWidth
    const newContentHeight = this.$.content.offsetHeight
    this._setViewportWidth(newViewportWidth)
    this._setViewportHeight(newViewportHeight)
    this._setContentWidth(newContentWidth)
    this._setContentHeight(newContentHeight)
    if (
      oldViewportWidth !== newViewportWidth ||
      oldViewportHeight !== newViewportHeight ||
      oldContentWidth !== newContentWidth ||
      oldContentHeight !== newContentHeight ||
      this._paddingWasChanged
    ) {
      this._paddingWasChanged = false
      this.dispatchEvent(new CustomEvent('zoom-view-resize'))
    }
  }

  _onPadddingChange() {
    this._paddingWasChanged = true
  }

  _onTransformChange(cx, cy, cs) {
    const transform =
      'translate3d(' + cx + 'px, ' + cy + 'px, 0px) ' + 'scale3d(' + cs + ', ' + cs + ', 1) '
    this.$.content.style.transform = transform
  }

  _onSizeChange(viewportWidth, viewportHeight, contentWidth, contentHeight) {
    const oldIsSomethingVisible = this._isSomethingVisible
    const newIsSomethingVisible = viewportWidth * viewportHeight * contentWidth * contentHeight > 0
    this._isSomethingVisible = newIsSomethingVisible
    if (newIsSomethingVisible && !oldIsSomethingVisible) {
      this.dispatchEvent(new CustomEvent('zoom-view-appear'))
    }
  }

  get fitScaleFactor() {
    const scaleX = this._viewportWidthMinusPadding / this.contentWidth
    const scaleY = this._viewportHeightMinusPadding / this.contentHeight
    return Math.min(scaleX, scaleY)
  }

  get _viewportWidthMinusPadding() {
    const paddingX = this.paddingLeft + this.paddingRight
    return Math.max(0, this.viewportWidth - paddingX)
  }

  get _viewportHeightMinusPadding() {
    const paddingY = this.paddingTop + this.paddingBottom
    return Math.max(0, this.viewportHeight - paddingY)
  }

  centerContent() {
    if (!this._snappies) {
      return
    }
    this._updateSizes()

    const scaledContentWidth = this.contentWidth * this.contentScale
    const spaceX = this._viewportWidthMinusPadding - scaledContentWidth
    this._snappies[0].reset(0.5 * spaceX, 0)

    const scaledContentHeight = this.contentHeight * this.contentScale
    const spaceY = this._viewportHeightMinusPadding - scaledContentHeight
    this._snappies[1].reset(0.5 * spaceY, 0)
  }

  _clampScale(scale) {
    scale = Math.min(this.maxContentScale, scale)
    return Math.max(this.minContentScale, scale)
  }
}
customElements.define(ZoomView.is, ZoomView)

function computeSnapPosition(viewportSize, scaledContentSize, position) {
  var space = viewportSize - scaledContentSize
  if (space > 0) {
    // Content smaller than viewport
    return space * 0.5
  } else {
    // Content bigger than viewport
    if (position < space) {
      return space
    }
    if (position > 0) {
      return 0
    }
  }
  return null
}
