import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
export default async function openExternalLink(href, { openInSameTab } = {}) {
  openInSameTab = !!openInSameTab
  if (Capacitor.getPlatform() !== 'web') {
    cordova.InAppBrowser.open(href, '_system')
  } else {
    if (/^mailto/.test(href)) {
      document.location.href = href
    } else {
      window.open(href, openInSameTab ? '_self' : '_blank')
    }
  }
}
export async function openExternalBrowserLink(href, { openInSameTab } = {}) {
  openInSameTab = !!openInSameTab
  if (Capacitor.getPlatform() !== 'web') {
    await Browser.open({ url: href })
   
  } else {
    if (/^mailto/.test(href)) {
      document.location.href = href
    } else {
      window.open(href, openInSameTab ? '_self' : '_blank')
    }
  }
}