import { Capacitor } from "@capacitor/core"

const MouseOverBehavior = {
  properties: {
    hover: { type: Boolean, value: false, reflectToAttribute: true, readOnly: true },
  },

  listeners: {
    mouseover: '_onMouseOver',
    mouseout: '_onMouseOut',
  },

  _onMouseOver() {
    if (Capacitor.getPlatform() === 'ios') {
      return
    }
    this._setHover(true)
  },
  _onMouseOut() {
    if (Capacitor.getPlatform() === 'ios') {
      return
    }
    this._setHover(false)
  },
}
export default MouseOverBehavior
