export default function navigate(Klass) {
  return class Navigate extends Klass {
    navigateTo(url, restore = false) {
      if (url[0] !== '/') {
        throw new Error('URL needs to start with a "/"')
      }
      this.dispatchEvent(
        new CustomEvent('wolf-navigate', {
          detail: { url, restore },
          bubbles: true,
          composed: true,
        })
      )
    }

    linkToFolder(folder, sparePart) {
      if (typeof folder === 'object') {
        folder = folder.path
      }
      if (typeof sparePart === 'object') {
        sparePart = sparePart.id
      }
      let url = '/catalog/folders/' + folder
      if (sparePart) {
        url += '?spare-part=' + sparePart
      }
      return url
    }

    linkToSearchInFolder(folder) {
      if (typeof folder === 'object') {
        folder = folder.path
      }
      let url = '/catalog/search'
      if (folder) {
        url += '?folder=' + folder
      }
      return url
    }

    linkToSparePart(sparePart, from) {
      if (typeof sparePart === 'object') {
        sparePart = sparePart.id
      }
      let url = '/catalog/spare-parts/' + sparePart
      if (from) {
        url += '?from=' + from
      }
      return url
    }

    navigateToFolder(folder, sparePart) {
      this.navigateTo(this.linkToFolder(folder, sparePart))
    }

    async navigateToSparePart(sparePart, from) {
      this.navigateTo(this.linkToSparePart(sparePart, from))
    }

    navigateToSearch(folder) {
      this.navigateTo(this.linkToSearchInFolder(folder))
    }
  }
}
