import { PolymerElement, html } from '@polymer/polymer/polymer-element.js'
import '@polymer/iron-image/iron-image.js'
import '@polymer/paper-icon-button/paper-icon-button.js'
import '../../elements/zoom-view/zoom-view.js'
import '../../utils/wolf-colors.js'
import '../../utils/wolf-icons.js'
import './exploded-view-point.js'
import '../../elements/rows/spare-part-row.js'
import navigate from '../../mixins/navigate.js'

class ExplodedView extends navigate(PolymerElement) {
  static get template() {
    return html([require('./exploded-view.html')])
  }

  static get is() {
    return 'exploded-view'
  }

  static get properties() {
    return {
      folder: { type: Object },
      sparePart: { type: Object, notify: true },
      _points: { type: Array, computed: '_computePoints(folder)' },
      paddingTop: { type: Number },
    }
  }

  _onZoomViewAppear() {
    const zoomView = this.$['zoom-view']
    zoomView.contentScale = zoomView.fitScaleFactor
    zoomView.centerContent()
  }

  _onZoomViewResize() {
    const zoomView = this.$['zoom-view']
    const [minScale, maxScale] = [1, zoomView.fitScaleFactor].sort()
    zoomView.minContentScale = minScale
    zoomView.maxContentScale = maxScale
    zoomView.contentScale = Math.max(minScale, Math.min(maxScale, zoomView.contentScale))
  }

  _computePoints(folder) {
    if (!folder || !folder.explodedView || !folder.explodedView.pointPositions) {
      return []
    }

    const pointPositionsHash = folder.explodedView.pointPositions
    const mapping = folder.explodedViewMapping || {}

    const width = folder.explodedView.image.size[0]
    const height = folder.explodedView.image.size[1]
    const diameterInPx = folder.explodedView.pointSize * width

    const labels = Object.keys(pointPositionsHash)
    const points = []
    for (let label of labels) {
      const positionsForLabel = pointPositionsHash[label]
      for (let position of positionsForLabel) {
        const sparePart = folder.store.getSparePart(mapping[label]) || null
        points.push({
          x: position[0] * width - diameterInPx * 0.5,
          y: position[1] * height - diameterInPx * 0.5,
          diameter: diameterInPx,
          sparePart: sparePart,
        })
      }
    }
    return points
  }

  _close() {
    this.dispatchEvent(
      new CustomEvent('wolf-hide-exploded-view', {
        composed: true,
        bubbles: true,
      })
    )
  }

  _deselectSparePart() {
    this.sparePart = undefined
  }

  _onExplodedViewPointTap(event) {
    const sparePart = event.currentTarget.sparePart
    if (this.sparePart === sparePart) {
      this.navigateToSparePart(sparePart)
    } else {
      this.sparePart = sparePart
    }
  }

  _equal(a, b) {
    return a === b
  }

  _onTappedSparePart(event) {
    const sparePart = event.currentTarget.sparePart
    this.navigateToSparePart(sparePart)
  }
}
customElements.define(ExplodedView.is, ExplodedView)
