import Folder from '../models/folder.js'
import xhrGet from '../../utils/xhr-get.js'
import dirname from '../../utils/dirname.js'
import ErrorCodeInstruction from '../models/error-code-instruction.js'
import alphanumSort from '../../utils/alphanum-sort.js'

export default async function laodErrorCodes(store, progressCallback) {
  await store.load('definition')

  const file = store._files.errorCodes
  const json = JSON.parse(await xhrGet(file, progressCallback))

  const errorCodes = []
  const errorCodeInstructionsByErrorCode = new Map()
  for (let data of json.errorCodeInstructions) {
    const instruction = new ErrorCodeInstruction(store, data)

    const array = errorCodeInstructionsByErrorCode.get(data.errorCode)
    if (array) {
      array.push(instruction)
    } else {
      errorCodes.push(data.errorCode)
      errorCodeInstructionsByErrorCode.set(data.errorCode, [instruction])
    }
  }

  errorCodeInstructionsByErrorCode.forEach((arr) => {
    arr.sort((a, b) => alphanumSort(a.productsString, b.productsString))
  })

  errorCodes.sort(alphanumSort)

  store.errorCodes = errorCodes
  store.errorCodeInstructionsByErrorCode = errorCodeInstructionsByErrorCode

  store.notifyChange('errorCodes')
  store.notifyChange('errorCodeInstructionsByErrorCode')
}
