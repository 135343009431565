import watchable from '../../mixins/watchable.js'

export default class SparePart extends watchable(class {}) {
  constructor(store, data, basePath, currency, pricesIncludeVAT) {
    super()

    this.store = store

    this.id = data.id
    this.name = data.name
    this.ean = data.ean
    this.price = data.price
    this.priceIncludesVAT = pricesIncludeVAT
    this.currency = currency
    this.proTip = data.proTip

    this.usedIn = [] // Folders

    if (data.image) {
      this.image = data.image
      this.image.path = basePath + '/' + this.image.path

      store.sparePartPhotoCount += 1
    }

    this.discontinued = data.discontinued

    store._sparePartMap.set(this.id, this)
  }

  get quantityInCart() {
    return this.store._getQuantityInCart(this.id)
  }

  set quantityInCart(count) {
    this.store._setQuantityInCart(this.id, count)
    this.notifyChange('quantityInCart')
  }

  get isFavorite() {
    return this.store._getIsFavorite('sparePart', this.id)
  }

  addToFavorites() {
    this.store._addToFavorites('sparePart', this.id)
    this.notifyChange('isFavorite')
  }

  deleteFromFavorites() {
    this.store._deleteFromFavorites('sparePart', this.id)
    this.notifyChange('isFavorite')
  }

  get priceString() {
    if (!this.price) {
      return ''
    }
    let s = this.store.translations.formatPrice(this.price, this.currency)
    if (!this.priceIncludesVAT) {
      s += ' ' + this.store.translations['exclVAT']
    }
    return s
  }
}
