import { PolymerElement, html } from '@polymer/polymer/polymer-element.js'
import '@polymer/paper-ripple/paper-ripple.js'
import translated from '../../mixins/translated.js'

class ReadBarcodeButton extends translated(PolymerElement) {
  static get template() {
    return html([require('./read-barcode-button.html')])
  }

  static get is() {
    return 'read-barcode-button'
  }
}
customElements.define(ReadBarcodeButton.is, ReadBarcodeButton)
