import { PolymerElement, html } from '@polymer/polymer/polymer-element.js'
import '@polymer/iron-icon/iron-icon.js'
import '@polymer/paper-ripple/paper-ripple.js'
import '@polymer/paper-icon-button/paper-icon-button.js'
import '../../utils/wolf-icons.js'
import './row-shared-styles.js'
import translated from '../../mixins/translated.js'

class RecentSearchTermRow extends translated(PolymerElement) {
  static get template() {
    return html([require('./recent-search-term-row.html')])
  }

  static get is() {
    return 'recent-search-term-row'
  }

  static get properties() {
    return {
      searchTerm: { value: '' },
      actionMode: { type: Boolean, reflectToAttribute: true, value: false },
    }
  }

  ready() {
    super.ready()
    this.addEventListener('tap', this._onTap)
  }

  _onTap(event) {
    if (this.actionMode) {
      event.stopPropagation()
      event.preventDefault()
      return
    }
    this.$.ripple.uiDownAction(event)
    this.$.ripple.uiUpAction(event)
  }
}
customElements.define(RecentSearchTermRow.is, RecentSearchTermRow)
