let enabled = false
let state = 'notLoaded' // 'notLoaded' | 'loading' | 'loaded'

let resolveLoadingPromise
const loadedPromise = new Promise((r) => {
  resolveLoadingPromise = r
})

window.fbAsyncInit = function () {
  FB.init({
    appId: '170762780283873',
    autoLogAppEvents: true,
    xfbml: true,
    version: 'v3.0',
  })
  FB.AppEvents.setAppVersion(window.WolfServiceAppInfo.version)

  console.log('facebook API initialized')

  state = 'loaded'
  resolveLoadingPromise()
}

function load() {
  if (!enabled) {
    return
  }
  if (state === 'notLoaded') {
    console.log('Starting facebook API')
    state = 'loading'
    ;(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')
  }
}

export function setEnabled(value) {
  enabled = value
  if (enabled) {
    load()
  }
}

export function logEvent(eventName, valueToSum, parameters) {
  if (!enabled) {
    return
  }
  loadedPromise.then(() => {
    FB.AppEvents.logEvent(eventName, valueToSum, parameters)
  })
}
