const $_documentContainer = document.createElement('div')
$_documentContainer.setAttribute('style', 'display: none;')

$_documentContainer.innerHTML = `<dom-module id="wolf-scrollbar">
  <template>
    <style is="custom-style">
      .wolf-scrollbar,
      :host(.wolf-scrollbar) {
        -ms-overflow-style: -ms-autohiding-scrollbar;
      }
      .wolf-scrollbar::-webkit-scrollbar,
      :host(.wolf-scrollbar)::-webkit-scrollbar {
        width: 6px;
      }
      .wolf-scrollbar::-webkit-scrollbar-thumb,
      :host(.wolf-scrollbar)::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        background-color: var(--wolf-scrollbar-color, rgba(0, 0, 0, 0.4));
        border: 2px solid transparent;
        border-left: none;
      }
    </style>
  </template>
</dom-module>`

document.head.appendChild($_documentContainer)
