import { PolymerElement, html } from '@polymer/polymer/polymer-element.js'
import navigate from '../mixins/navigate.js'
import '@polymer/paper-button/paper-button.js'
import '@polymer/paper-progress/paper-progress.js'
import translated from '../mixins/translated.js'
import '../utils/fonts/wolf-fonts'
import '../utils/wolf-heading-bar'

class LoadingOverlay extends translated(navigate(PolymerElement)) {
  static get template() {
    return html([require('./loading-overlay.html')])
  }

  static get is() {
    return 'loading-overlay'
  }

  static get properties() {
    return {
      store: { type: Object },
      _loadingItems: {
        type: Array,
        computed: '_computeLoadingItems(store.status, store.status.*)',
      },
    }
  }

  ready() {
    super.ready()
    this.addEventListener('wolf-back', this._onBack)
  }

  _onBack() {
    this.navigateTo('/')
  }

  _translationFor(key) {
    return this.t[key]
  }
  _computeLoadingItems(status) {
    const loadingItems = []
    for (let sectionName of Object.keys(status)) {
      const { progress, loading } = status[sectionName]
      if (loading) {
        loadingItems.push({ sectionName, progress })
      }
    }
    return loadingItems
  }
}
customElements.define(LoadingOverlay.is, LoadingOverlay)
