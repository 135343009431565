import { PolymerElement, html } from '@polymer/polymer/polymer-element.js'
import '@polymer/paper-icon-button/paper-icon-button.js'
import '@polymer/app-layout/app-toolbar/app-toolbar.js'
import '../../../utils/wolf-icons.js'
import '../../../utils/wolf-colors.js'
import '../../../utils/wolf-app-toolbar'
import translated from '../../../mixins/translated.js'
import navigate from '../../../mixins/navigate.js'
import '../../../data/elements/get-folder.js'
import '../../../data/elements/get-spare-part.js'
import '../../../elements/exploded-view/exploded-view.js'

class CatalogExplodedViewPage extends navigate(translated(PolymerElement)) {
  static get template() {
    return html([require('./catalog-exploded-view-page.html')])
  }

  static get is() {
    return 'catalog-exploded-view-page'
  }

  static get properties() {
    return {
      folderId: { type: String },
      sparePartId: { type: String, notify: true },
      _folder: { type: Object },
      _sparePart: { type: Object, notify: true },
      isExplodedViewShown: { type: Boolean, notify: true },
    }
  }

  ready() {
    super.ready()
    this.store.load(['folders', 'spareParts']).then(() => (this._loading = false))
    this.addEventListener('wolf-back', this._onBack)
  }

  _onBack() {
    this.isExplodedViewShown = false
  }
}
customElements.define(CatalogExplodedViewPage.is, CatalogExplodedViewPage)
