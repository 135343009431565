/**
The `ZoomViewTouchGesture` class handles the touchstart, touchmove and
touchstart events. It takes three callback methods:
- `start()` Called when a new touch gesture starts
- `update({ deltaPosition, deltaScale, center })` Called everytime the touch
  points on screen move. The `center` can and should be used as a fixpoint
  when scaling the content.
- `end({ velocity })` Called at the end of the touch gesture. The callback
  provides the `velocity` (in px/ms) which can be used to animate the content.
*/
export default class TouchGesture {
  constructor(element, callbacks) {
    this._callbacks = callbacks
    this._element = element

    var e = element
    this._listeners = [
      { target: e, type: 'touchstart', fn: this._onTouchStart.bind(this) },
      { target: e, type: 'touchmove', fn: this._onTouchMove.bind(this) },
      { target: e, type: 'touchend', fn: this._onTouchEnd.bind(this) },
      { target: e, type: 'touchcancel', fn: this._onTouchEnd.bind(this) },
    ]
    for (let l of this._listeners) {
      l.target.addEventListener(l.type, l.fn)
    }

    this._touchMap = new Map()
  }

  destroy() {
    for (let l of this._listeners) {
      l.target.removeEventListener(l.type, l.fn)
    }
  }

  _onTouchStart(event) {
    event.preventDefault()
    event.stopPropagation()
    const prevNumTouches = this._touchMap.size

    if (prevNumTouches === 0) {
      this._velocity = [0, 0]
      this._time = currentTimeInSeconds()
      this._firstTouchId = undefined
      this._firstTouchStartPosition = undefined
      this._mightBeTap = true
      this._callbacks.startCallback()
    }

    const changedTouches = event.changedTouches
    for (let i = 0; i < changedTouches.length; ++i) {
      const touch = changedTouches[i]
      const rect = this._element.getBoundingClientRect()
      const position = [touch.clientX - rect.left, touch.clientY - rect.top]
      this._touchMap.set(touch.identifier, position)

      if (!this._firstTouchId) {
        this._firstTouchId = touch.identifier
        this._firstTouchStartPosition = position
      }
    }

    if (this._mightBeTap) {
      this._mightBeTap = this._touchMap.size <= 1
    }
  }

  _onTouchMove(event) {
    event.preventDefault()
    event.stopPropagation()
    const changedTouches = event.changedTouches
    for (let i = 0; i < changedTouches.length; ++i) {
      const touch = changedTouches[i]
      const rect = this._element.getBoundingClientRect()
      const position = [touch.clientX - rect.left, touch.clientY - rect.top]
      if (this._touchMap.has(touch.identifier)) {
        this._touchMap.set(touch.identifier, position)
      }
    }

    const numTouches = this._touchMap.size
    const time = currentTimeInSeconds()

    // Compute center
    const sum = [0, 0]
    this._touchMap.forEach((position) => {
      for (let dim = 0; dim < 2; ++dim) {
        sum[dim] += position[dim]
      }
    })
    var center = [sum[0] / numTouches, sum[1] / numTouches]

    // Compute distance
    let distance = 0
    this._touchMap.forEach((position) => {
      const a = position[0] - center[0]
      const b = position[1] - center[1]
      distance += Math.sqrt(a * a + b * b)
    })

    // Load previous values
    const prevCenter = this._center
    const prevDistance = this._distance
    const prevTime = this._time
    const prevNumTouches = this._numTouches

    // Store current values
    this._center = center
    this._distance = distance
    this._time = time
    this._numTouches = numTouches

    if (numTouches === prevNumTouches) {
      let deltaScale = 1
      if (distance > 0 && prevDistance > 0) {
        deltaScale = distance / prevDistance
      }
      const deltaPosition = [center[0] - prevCenter[0], center[1] - prevCenter[1]]

      const deltaTime = time - prevTime
      this._velocity = [deltaPosition[0] / deltaTime, deltaPosition[1] / deltaTime]

      this._callbacks.updateCallback({
        deltaPosition,
        deltaScale,
        center: prevCenter,
      })
    }

    if (this._mightBeTap) {
      this._mightBeTap = this._touchMap.size <= 1

      const firstTouchPosition = this._touchMap.get(this._firstTouchId)
      if (firstTouchPosition) {
        const a = this._firstTouchStartPosition[0] - firstTouchPosition[0]
        const b = this._firstTouchStartPosition[1] - firstTouchPosition[1]
        const movedDistance = Math.sqrt(a * a + b * b)
        if (movedDistance > 10) {
          this._mightBeTap = false
        }
      } else {
        this._mightBeTap = false
      }
    }
  }

  _onTouchEnd(event) {
    event.preventDefault()
    event.stopPropagation()

    const changedTouches = event.changedTouches
    for (let i = 0; i < changedTouches.length; ++i) {
      const touch = changedTouches[i]
      this._touchMap.delete(touch.identifier)
    }

    const numTouches = this._touchMap.size

    this._numTouches = 0

    if (numTouches === 0) {
      this._callbacks.endCallback({ velocity: this._velocity })

      if (this._mightBeTap) {
        // Trigger tap event
        event.target.dispatchEvent(new CustomEvent('tap'), {
          bubbles: true,
          composed: true,
          detail: event,
        })
      }
    }
  }
}

function currentTimeInSeconds() {
  return performance.now() / 1000
}
