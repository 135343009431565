import { PolymerElement, html } from '@polymer/polymer/polymer-element.js'
import '@polymer/iron-icon/iron-icon.js'
import '@polymer/paper-ripple/paper-ripple.js'
import '../../utils/wolf-icons.js'
import '../../utils/wolf-selectable-text.js'
import '../../utils/wolf-watch-object.js'
import './row-shared-styles.js'
import translated from '../../mixins/translated.js'
import navigate from '../../mixins/navigate.js'

class SparePartRow extends navigate(translated(PolymerElement)) {
  static get template() {
    return html([require('./spare-part-row.html')])
  }

  static get is() {
    return 'spare-part-row'
  }

  static get properties() {
    return {
      sparePart: Object,
      fromQueryParam: String,
      isQuantityInCartShown: { type: Boolean, value: false },
      discontinued: {
        type: Boolean,
        reflectToAttribute: true,
        computed: '_computeDisconitued(sparePart)',
      },
      disabled: {
        type: Boolean,
        reflectToAttribute: true,
        value: false,
        computed: '_computeDisabled(sparePart)',
        readOnly: true,
      },
      truncate: { type: Boolean, reflectToAttribute: true, value: false },
      active: { type: Boolean, reflectToAttribute: true, value: false },
      actionMode: { type: Boolean, reflectToAttribute: true, value: false },
    }
  }

  ready() {
    super.ready()
    this.addEventListener('tap', this._onTap)
  }

  _onTap(event) {
    if (this.actionMode) {
      event.stopPropagation()
      event.preventDefault()
      return
    }
    this.$.ripple.uiDownAction(event)
    this.$.ripple.uiUpAction(event)
  }

  _onIconTap(event) {
    event.stopPropagation()
    if (typeof this.sparePart !== 'object') {
      return
    }
    this.dispatchEvent(new CustomEvent('wolf-select-spare-part'))
  }

  _computeDisconitued(sparePart) {
    return !!(sparePart && sparePart.discontinued)
  }

  _isObject(obj) {
    return typeof obj === 'object'
  }
  _isString(string) {
    return typeof string === 'string'
  }
  _computeDisabled(obj) {
    return typeof obj !== 'object'
  }
}
customElements.define(SparePartRow.is, SparePartRow)
