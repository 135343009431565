import Folder from '../models/folder.js'
import xhrGet from '../../utils/xhr-get.js'
import dirname from '../../utils/dirname.js'

export default async function loadFolders(store, progressCallback) {
  await store.load('definition')

  const file = store._files.folders
  const json = JSON.parse(await xhrGet(file, progressCallback))
  const basePath = dirname(file)

  await store.load('spareParts')
  store.rootFolder = new Folder(store, json.rootFolder, null, '', basePath)

  store.notifyChange('rootFolder')
  store.notifyChange('explodedViewCount')
  store.notifyChange('favorites')
}
