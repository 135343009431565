export default function scrollTo(options) {
  const { element, position: [posX, posY] = [], duration = 300 } = options

  if (typeof posX === 'number') {
    _scrollTo(element, duration, posX, 'x')
  }
  if (typeof posY === 'number') {
    _scrollTo(element, duration, posY, 'y')
  }
}

function _scrollTo(element, duration, position, axis) {
  const start = axis === 'x' ? element.scrollLeft : element.scrollTop
  const delta = position - start

  let startTime
  function tick(currentTime) {
    if (!startTime) {
      startTime = currentTime
    }
    const lambda = Math.min((currentTime - startTime) / duration, 1)
    const value = start + delta * easeInOutSin(lambda)

    if (axis === 'x') {
      element.scrollLeft = value
    } else {
      element.scrollTop = value
    }

    if (lambda < 1) {
      requestAnimationFrame(tick)
    }
  }
  requestAnimationFrame(tick)
}

function easeInOutSin(t) {
  return (1 + Math.sin(Math.PI * t - Math.PI / 2)) / 2
}
