export default [
  {
    id: 'bach',
    img: require('./images/bach.png'),
    makeURL: (order) => `https://online.m-bach.de/wshop_wolf/add_to_cart/${order}`,
  },
  {
    id: 'bach-gws-integrationsumgebung',
    img: require('./images/bach.png'),
    makeURL: (order) => `https://9999latest-inte.gwsshop.de/wshop_wolf/add_to_cart/${order}`,
  },
  {
    id: 'Balzer16224',
    img: require('./images/balzernassauer.png'),
    makeURL: (order) => `https://16224.gwsshop.de/wshop_wolf/add_to_cart/${order}`,
  },
  {
    id: 'Balzer17213',
    img: require('./images/balzernassauer.png'),
    makeURL: (order) => `https://17213.gwsshop.de/wshop_wolf/add_to_cart/${order}`,
  },
  {
    id: 'holtzmann',
    img: require('./images/holtzmann.jpg'),
    makeURL: (order) => `https://215516.gwsshop.de/wshop_wolf/add_to_cart/${order}`,
  },
  {
    id: 'lange-test',
    img: require('./images/flange.jpg'),
    makeURL: (order) => `https://shop.friedrich-lange.de/cgi-bin/vmgtst/Anmeldung.sd/0/0/${order}`,
  },
  {
    id: 'flange',
    img: require('./images/flange.jpg'),
    makeURL: (order) => `https://shop.friedrich-lange.de/cgi-bin/vmgtst/Anmeldung.sd/0/0/${order}`,
  },
  {
    id: 'kuester-test',
    img: require('./images/kuester.jpg'),
    makeURL: (order) => `https://shop.friedrich-lange.de/cgi-bin/vmgtst/Anmeldung.sd/0/0/${order}`,
  },
  {
    id: 'kuester',
    img: require('./images/kuester.jpg'),
    makeURL: (order) => `https://shop.friedrich-lange.de/cgi-bin/vmgtst/Anmeldung.sd/0/0/${order}`,
  },
]

export const allowedFromShopURLDomains = [
  // Elmer
  'online.elmer.de',
  'onlineshop.elmer.loc',
  'onlineshop-dev.elmer.loc',
  'elmer.sct.de',
  'elmer-dev.sct.de',
  'online.bechem.co',
  'shop.bechemco.de',
  'bechem.sct.de',
  'bechem-dev.sct.de',
  'online.reinshagen-schroeder.de',
  'rhs.sct.de',
  'rhs-dev.sct.de',
  'online.tiggesundzepke.de',
  'tuz.sct.de',
  'tuz-dev.sct.de',
  'online.vetter-engels.de',
  'shop.vetter-engels.de',
  've.sct.de',
  've-dev.sct.de',
  'wolf-ersatzteile.sct.de',
  'wolf-ersatzteile-dev.sct.de',

  // Lange / Küster
  'shop.friedrich-lange.de',
  'lange.sct.de',
  'lange-dev.sct.de',
  'wolf-ersatzteile.sct.de',
  'wolf-ersatzteile-dev.sct.de',
  'shop.sanikue.de',
  'kuester.sct.de',
  'kuester-dev.sct.de',

  // Holtzmann
  'shop.holtzmann.net',
  'webshop.holtzmann.net',
  '215516.gwsshop.de',

  // Bach
  'online.m-bach.de',
  '230219.gwsshop.de',

  // Nerlich
  '110179.gwsshop.de',
  'nl24.de',

  // GWS
  '9999latest-inte.gwsshop.de',
  '215516-inte.gwsshop.de',
  '230219-inte.gwsshop.de',
  '110179-inte.gwsshop.de',
  
  // Zander
  'zander.online',
  'heix.online',
  'zander.online/konfigurator',

  // Mainmetall
  'portal.mainmetall.de',
  'preview.mainmetall.de',
  'sellsite-test.mainmetall.de',

  // Sievers
  '14835.gwsshop.de',
  'ishop.rudolf-sievers.de',

  // Handelshof Stendal
   '17242.gwsshop.de',
   'ishop.pft-stendal.de',
   
   // Handelshof Bitterfeld
  '17245.gwsshop.de',
  'ishop.pft-bitterfeld.de',

   // Handelshof Vorpommern
   '17249.gwsshop.de',
   'ishop.pft-vorpommern.de',

  // Handelshof Bautzen
  '17256.gwsshop.de',
  'ishop.pft-bautzen.de', 

  // Handelshof Cottbus
  '20927.gwsshop.de',
  'ishop.pft-cottbus.de',

  // Handelshof Riesa
  '20928.gwsshop.de',
  'ishop.pft-riesa.de',

  // Ufer
  'prod.cgrd.net',
  'stage.cgrd.net',
  'dev.cgrd.net',
  'ufer24.de',
  'shop.eugen-koenig.de',
  'koenigufer-matthias-shop-eugen-koenig.dev.cgrd.net',
  'https://koenigufer-shop-eugen-koenig.stage.cgrd.net/',
  'koenigufer-shop-eugen-koenig.stage.cgrd.net',
  'kundenportal.ufer24.de',
  'kundenportal.eugen-koenig.de',

  // Ludendorff
  'shop.ludendorff.de',
  'shop8.ludendorff.de',

  // Eisen Fischer Nördlingen
  'profiportal.eisenfischer.de',
  'profiportal.eisenfischer.de/ersatzteil-service',
  'profiportal-staging.eisenfischer.de',
  'profiportal-prod.eisenfischer.de',

  // Peter Jensen
  'petershop.de',
  'petershop.stage.cgrd.net',
  'petershop-matthias.dev.cgrd.net',
  'peterjensen.de',
  'www.petershop.de',
  'https://www.petershop.de',
  'peterjensen.stage.cgrd.net',

  // Wolf Spanien
  'test.wolf-pro.es/shopping',

  // Schindele
  'schindele.shop',
  'www.schindele.shop',
  'schindele.stage.cgrd.net',
  'schindele-mateusz.dev.cgrd.net',

   // Ottenbruch
   'shop.ottenbruch.de/de',
   "www.ottenbruch.de",
   "ottenbruch.de",
   "shop.ottenbruch.de",
   "https://www.ottenbruch.de",
   "https://ottenbruch.de",
   "https://shop.ottenbruch.de"
 ]
