import { PolymerElement, html } from '@polymer/polymer/polymer-element.js'
import { mixinBehaviors } from '@polymer/polymer/lib/legacy/class.js'
import { IronResizableBehavior } from '@polymer/iron-resizable-behavior/iron-resizable-behavior.js'
import '@polymer/paper-button/paper-button.js'
import '../../../utils/wolf-colors.js'
import '../../../utils/wolf-scrollbar.js'
import '../../../utils/fonts/wolf-fonts.js'
import '../../../utils/wolf-heading-bar.js'
import '../../../utils/wolf-card-shadow.js'
import '../../../elements/rows/folder-row.js'
import '../../../elements/rows/spare-part-row.js'
import '../../../elements/read-barcode-button/read-barcode-button.js'
import '../../../utils/wolf-icons.js'
import '../../../utils/wolf-selectable-text.js'
import '../../../utils/wolf-watch-object.js'
import scrollTo from '../../../utils/scroll-to.js'
import translated from '../../../mixins/translated.js'
import navigate from '../../../mixins/navigate.js'
import { canReadBarcode, readBarcode } from '../../../utils/barcode-reading.js'

const SuperClass = mixinBehaviors([IronResizableBehavior], PolymerElement)

class CatalogFolderList extends navigate(translated(SuperClass)) {
  static get template() {
    return html([require('./catalog-folder-list.html')])
  }

  static get is() {
    return 'catalog-folder-list'
  }

  static get properties() {
    return {
      folder: { type: Object, observer: '_onFolderChange', notify: true },
      sparePart: { type: Object, observer: '_onSparePartChange', notify: true },
      isExplodedViewShown: { type: Boolean, notify: true },
      headingRows: Array,
      wide: { type: Boolean, reflectToAttribute: true },
      isExplodedViewButtonShown: {
        type: Boolean,
        value: true,
        observer: '_onIsExplodedViewButtonShownChange',
      },
    }
  }

  ready() {
    super.ready()
    this.classList.add('wolf-scrollbar')
    this.addEventListener('iron-resize', this._onIronResize)
  }

  hasDepth2(folder) {
    return folder && folder.style.depth === 2
  }

  connectedCallback() {
    super.connectedCallback()
    this._onIronResize()
    requestAnimationFrame(() => this._onIronResize()) // Hack
  }

  _onIronResize() {
    this.wide = this.offsetWidth >= 500
  }

  _onShowExplodedView() {
    this.isExplodedViewShown = true
  }

  _onIsExplodedViewButtonShownChange(shown) {
    this.$.explodedViewButton.style.display = shown ? '' : 'none'
  }

  _onFolderChange(folder) {
    if (folder.id === 'root') {
      // Get the language from the service-app element
      const lang = document.querySelector('service-app').getAttribute('lang')
      if (lang === 'fr') {
        this.addRecyclingImage()
      }
    }
    this.headingRows = [folder.title, folder.infoSubtitle, folder.subtitle].filter((x) => x)
    setTimeout(() => this._scrollToSparePart(this.sparePart), 100)
  }

  addRecyclingImage() {
    // Create the container for the Image
    const container = document.createElement('div')
    container.style.display = 'flex'
    container.style.width = '100%'
    container.style.height = '70px'
    container.style.justifyContent = 'flex-end'
    container.style.position = 'absolute'
    //Create the Image
    const img = document.createElement('img')
    img.src = require('./FR-recycle-logo.jpg')
    img.style.objectFit = 'contain'
    img.style.margin = '0 10% 0 0'
    // Append the Image to container
    container.appendChild(img)
    // // Add the logo to the top of the page catalog folder page
    this.root.prepend(container)
  }

  _showStats(folder, t) {
    if (!t.stats) {
      return false
    }
    if (!folder || folder.parent) {
      return false
    }
    return true
  }

  _showBarcodeButton(folder) {
    if (!folder || folder.parent) {
      return false
    }
    return canReadBarcode()
  }

  _computeStats(store, t) {
    if (!store) {
      return ''
    }

    return t.stats
      .replace('{{sparePartCount}}', store.sparePartCount)
      .replace('{{sparePartPhotoCount}}', store.sparePartPhotoCount)
      .replace('{{folderCount}}', store.folderCount)
      .replace('{{explodedViewCount}}', store.explodedViewCount)
  }

  _equal(a, b) {
    return a === b
  }

  _onSparePartChange(sparePart) {
    this._scrollToSparePart(sparePart)
  }

  _scrollToSparePart(sparePart) {
    const children = this.root.querySelectorAll('spare-part-row')
    for (let i = 0; i < children.length; i += 1) {
      const child = children[i]
      if (child.sparePart === sparePart) {
        const childTop = child.offsetTop - this.offsetTop
        const childBottom = childTop + child.offsetHeight

        // Only scroll when required
        if (childTop < this.scrollTop || childBottom > this.scrollTop + this.offsetHeight) {
          const childCenter = 0.5 * (childTop + childBottom)
          const center = 0.5 * this.offsetHeight
          let pos = childCenter - center
          pos = Math.max(0, Math.min(this.scrollHeight - this.offsetHeight, pos))

          scrollTo({ element: this, position: [undefined, pos] })
        }

        break
      }
    }
  }

  _onTappedSparePart(event) {
    const sparePart = event.currentTarget.sparePart
    this.sparePart = sparePart
    this.navigateToSparePart(sparePart)
  }

  _onSelectSparePart(event) {
    const sparePart = event.currentTarget.sparePart
    this.sparePart = sparePart
  }

  _onTappedFolder(event) {
    const folder = event.currentTarget.folder
    this.navigateToFolder(folder)
  }

  _onBarcodeButtonTap() {
    readBarcode()
      .then((code) => {
        this.navigateTo('/catalog/search?q=' + encodeURIComponent(code))
      })
      .catch((error) => console.error(error))
  }

  _addToFavorites() {
    if (!this.folder) {
      return
    }
    this.folder.addToFavorites()
  }

  _deleteFromFavorites() {
    if (!this.folder) {
      return
    }
    this.folder.deleteFromFavorites()
  }

  replaceTextPlaceholders(text) {
    if (typeof text !== 'string') {
      return
    }

    return text.replace(/{{sku ([^\s]*)}}/g, (_match, sku) => {
      return `<a href="#${this.linkToSparePart(sku)}">${sku}</a>`
    })
  }
}
customElements.define(CatalogFolderList.is, CatalogFolderList)
