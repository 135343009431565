import { PolymerElement, html } from '@polymer/polymer/polymer-element.js'
import { mixinBehaviors } from '@polymer/polymer/lib/legacy/class.js'
import { IronResizableBehavior } from '@polymer/iron-resizable-behavior/iron-resizable-behavior.js'
import './catalog-folder-list.js'
import '../../../elements/exploded-view/exploded-view.js'
import '../../../utils/wolf-card-shadow.js'

const SuperClass = mixinBehaviors([IronResizableBehavior], PolymerElement)

class CatalogFolder extends SuperClass {
  static get template() {
    return html([require('./catalog-folder.html')])
  }

  static get is() {
    return 'catalog-folder'
  }

  static get properties() {
    return {
      folder: { type: Object, notify: true },
      sparePart: { type: Object, notify: true },
      isExplodedViewShown: { type: Boolean, notify: true },
      dualPane: { type: Boolean, reflectToAttribute: true, value: false },
      isExplodedViewButtonShown: { type: Boolean, value: true },
    }
  }

  static get observers() {
    return ['_onNeedToUpdateResponsiveDesign(folder)']
  }

  ready() {
    super.ready()
    this.addEventListener('iron-resize', this._onNeedToUpdateResponsiveDesign)
    this._onNeedToUpdateResponsiveDesign()
  }

  _onNeedToUpdateResponsiveDesign() {
    const hasExplodedView = !!(this.folder && this.folder.explodedViewMapping)
    this.dualPane = hasExplodedView && this.offsetWidth > 700
    this.isExplodedViewButtonShown = !this.dualPane && hasExplodedView
  }
}
customElements.define(CatalogFolder.is, CatalogFolder)
