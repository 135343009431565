import { PolymerElement, html } from '@polymer/polymer/polymer-element.js'
import { mixinBehaviors } from '@polymer/polymer/lib/legacy/class'
import '../../../utils/fonts/wolf-fonts.js'
import translated from '../../../mixins/translated.js'

class SummiteerLink extends translated(PolymerElement) {
  static get template() {
    return html([require('./summiteer-link.html')])
  }

  static get is() {
    return 'summiteer-link'
  }
}
customElements.define(SummiteerLink.is, SummiteerLink)
