import { PolymerElement, html } from '@polymer/polymer/polymer-element.js'
import '@polymer/app-layout/app-toolbar/app-toolbar.js'
import '@polymer/paper-button/paper-button.js'
import '@polymer/paper-icon-button/paper-icon-button.js'
import '../../../utils/wolf-colors.js'
import '../../../utils/wolf-icons.js'
import '../../../utils/wolf-app-toolbar'
import '../../../elements/rows/spare-part-row.js'
import translated from '../../../mixins/translated.js'
import navigate from '../../../mixins/navigate.js'

class CatalogCartPage extends navigate(translated(PolymerElement)) {
  static get template() {
    return html([require('./catalog-cart-page.html')])
  }

  static get is() {
    return 'catalog-cart-page'
  }

  static get properties() {
    return {
      store: Object,
      _loading: {
        type: Boolean,
        reflectToAttribute: true,
        value: true,
      },
    }
  }

  ready() {
    super.ready()
    this.store.load(['folders', 'spareParts']).then(() => (this._loading = false))
    this.addEventListener('wolf-back', this._onBack)
  }

  _onTappedClearButton() {
    if (this.store) {
      this.store.clearCart()
    }
  }

  _onOrder() {
    this.navigateTo('/catalog/order')
  }

  _onBack() {
    this.navigateTo('/catalog')
  }

  _onTappedSparePart(event) {
    const sparePart = event.currentTarget.sparePart
    this.navigateToSparePart(sparePart, 'cart')
  }

  _restoreCart() {
    this.store.restoreCart()
  }
}
customElements.define(CatalogCartPage.is, CatalogCartPage)
