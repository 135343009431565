import { Capacitor } from '@capacitor/core'
import { Device } from '@capacitor/device';
import '@polymer/app-layout/app-toolbar/app-toolbar.js'
import '@polymer/marked-element/marked-element.js'
import '@polymer/paper-button/paper-button.js'
import '@polymer/paper-icon-button/paper-icon-button.js'
import { html, PolymerElement } from '@polymer/polymer/polymer-element.js'
import platform from 'platform'
import navigate from '../../mixins/navigate.js'
import translated from '../../mixins/translated.js'
import openExternalLink from '../../utils/open-external-link.js'
import '../../utils/wolf-app-toolbar'
import '../../utils/wolf-colors.js'
import '../../utils/wolf-icons.js'

class ReportProblemPage extends navigate(translated(PolymerElement)) {
  static get template() {
    return html([require('./report-problem-page.html')])
  }

  static get is() {
    return 'report-problem-page'
  }

  ready() {
    super.ready()
    this.addEventListener('wolf-back', this._onBack)
  }

  async _sendEmail() {
    const info = await Device.getInfo();
    let deviceInfo = '';
    if(Capacitor.getPlatform() !== 'web'){
      deviceInfo = `Capacitor on ${platform} with OS Version: ${info.osVersion} and Model: ${info.model}`;
    } else {
      deviceInfo = `Capacitor on ${platform} with Web Browser Version: ${info.webViewVersion} running on: ${info.operatingSystem}`;
    }
    const appVersion = window.WolfServiceAppInfo.version;
    const subject = `Service App ${appVersion} Feedback [${deviceInfo}]`
    const link =
    'mailto:' +
    encodeURIComponent(this.t.feedbackEmail) +
    '?subject=' +
    encodeURIComponent(subject);
    openExternalLink(link)
  }

  _showHome() {
    this.navigateTo('/')
  }

  _onBack() {
    this.navigateTo('/')
  }
}
customElements.define(ReportProblemPage.is, ReportProblemPage)
