export default function watchable(Klass) {
  return class Watchable extends Klass {
    constructor() {
      super()
      this._callbacks = new Set()
    }

    watch(cb) {
      this._callbacks.add(cb)
    }

    unwatch(cb) {
      this._callbacks.delete(cb)
    }

    notifyChange(changedPath) {
      this._callbacks.forEach(function (cb) {
        cb([changedPath])
      })
    }
  }
}
