import { PolymerElement, html } from '@polymer/polymer/polymer-element.js'
import '@polymer/iron-icon/iron-icon.js'
import '../../../utils/wolf-colors.js'
import '../../../utils/wolf-icons.js'
import navigate from '../../../mixins/navigate.js'

class CatalogToolbarBreadcrumbsItem extends navigate(PolymerElement) {
  static get template() {
    return html([require('./catalog-toolbar-breadcrumbs-item.html')])
  }

  static get is() {
    return 'catalog-toolbar-breadcrumbs-item'
  }

  static get properties() {
    return {
      folder: Object,
    }
  }

  ready() {
    super.ready()
    this.addEventListener('tap', this._onTap)
  }

  _onTap() {
    this.navigateToFolder(this.folder)
  }
}
customElements.define(CatalogToolbarBreadcrumbsItem.is, CatalogToolbarBreadcrumbsItem)
