import { Capacitor } from '@capacitor/core'
import { SplashScreen } from '@capacitor/splash-screen'
import '@polymer/iron-location/iron-location.js'
import { html, PolymerElement } from '@polymer/polymer/polymer-element.js'
import 'animated-pages/lib/animated-pages.js'
import { PopSegue, PushSegue } from 'animated-pages/lib/segues/stack-segues'
import SwapSegue from 'animated-pages/lib/segues/swap-segue.js'
import Store from '../data/store.js'
import translated from '../mixins/translated.js'
import '../pages/about/about-page.js'
import '../pages/accept-cookies/accept-cookies-page.js'
import '../pages/catalog/cart/catalog-cart-page.js'
import '../pages/catalog/catalog-page.js'
import '../pages/catalog/exploded-view/catalog-exploded-view-page.js'
import '../pages/catalog/favorites/catalog-favorites-page.js'
import '../pages/catalog/order/catalog-order-page.js'
import '../pages/catalog/search/catalog-search-page.js'
import '../pages/catalog/spare-part/catalog-spare-part-page.js'
import '../pages/error-code-inspector/error-code-inspector-page.js'
import '../pages/home/home-page.js'
import '../pages/report-problem/report-problem-page.js'
import * as facebookTracking from '../utils/facebook-tracking/facebook-tracking.js'
import '../utils/fonts/wolf-fonts.js'
import * as googleAnalytics from '../utils/google-analytics.js'
import { openExternalBrowserLink } from '../utils/open-external-link.js'
import '../utils/wolf-colors.js'
import '../utils/wolf-watch-object.js'
import './loading-overlay.js'
import createRoutes from './routes'

const COOKIES_VERSION = 1 // To be incremented if additional cookies are added which need consent

class ServiceApp extends translated(PolymerElement) {
  static get template() {
    return html([require('./service-app.html')])
  }

  static get is() {
    return 'service-app'
  }

  static get properties() {
    return {
      _url: { type: String },
      _mainPage: { type: String },
      _overlayPage: { type: String },
      _loadingOverlayPage: {
        type: String,
        computed: '_computeLoadingOverlayPage(_loadingOverlayVisible)',
      },
      _overlayVisible: { type: Boolean, value: false, reflectToAttribute: true },
      _overlayBackdropVisible: { type: Boolean, value: false, reflectToAttribute: true },
      _loadingOverlayVisibleIfLoading: { type: Boolean, value: false },
      _loadingOverlayVisible: {
        type: Boolean,
        value: false,
        computed: ' _and(_loadingOverlayVisibleIfLoading, store.loading)',
      },
      routeState: { type: Object, notify: true },
      rootRoute: { type: Object, value: createRoutes },
      selectedSparePartId: { value: undefined, notify: true },
      isSparePartShown: { value: false, notify: true },
      store: { type: Object },

      _madeCookieDecision: {
        type: Boolean,
        value() {
          if (Capacitor.getPlatform() === 'ios') {
            return localStorage.getItem('decidedCookiesVersion') === 'iOSTrackingDecisionMade'
          }

          const decidedVersion = parseInt(localStorage.getItem('decidedCookiesVersion'))
          return decidedVersion === COOKIES_VERSION
        },
      },

      _acceptedCookies: {
        type: Boolean,
        value() {
          return this._madeCookieDecision && localStorage.getItem('acceptedCookies') === 'true'
        },
        observer: '_acceptedCookiesChange',
      },
    }
  }

  static get observers() {
    return [
      '_updatePages(routeState, _madeCookieDecision, routeState.*)',
      '_onFromShopChanged(routeState.queryParams.fromShop)',
      '_onFromShopURLChanged(routeState.queryParams.fromShopURL)',
      '_onURLChanged(_url)',
    ]
  }

  constructor() {
    super()
    this.store = new Store()
    this._onKeydownFn = this._onKeydown.bind(this)
    this._onBackButtonFn = this._onBackButton.bind(this)

    this.setAttribute('lang', this.language)
  }

  ready() {
    super.ready()

    this.addEventListener('wolf-navigate', this._onNavigate)
    this.addEventListener('tap', this._onTap)

    if (Capacitor.getPlatform() !== 'web') {
      // Make sure that background has loaded before removing splash screen
      const image = new Image()
      image.src = require('./sunrise-background.jpg')
      image.onload = () => {
        SplashScreen.show(
          {
            showDuration: 400,
            autoHide: true,
          }
        )
      }
    }
  }

  connectedCallback() {
    super.connectedCallback()
    window.addEventListener('keydown', this._onKeydownFn)
    document.addEventListener('backbutton', this._onBackButtonFn)
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    window.removeEventListener('keydown', this._onKeydownFn)
    document.addEventListener('backbutton', this._onBackButtonFn)
  }

  _isEqual(value1, value2) {
    return value1 === value2
  }

  _onTap(event) {
    const a = event.composedPath()[0]

    let anchor
    for (let element of event.composedPath()) {
      if (element.tagName === 'A') {
        anchor = element
        break
      }
    }
    if (!anchor) {
      return
    }

    if ((anchor.host && anchor.host !== location.host) || /^mailto/.test(anchor.href)) {
      // External web links and email links
      console.log('Opening external link: ' + anchor.href)
      openExternalBrowserLink(anchor.href)
      event.preventDefault()
      event.stopPropagation()
    } else {
      this.url = anchor.href
    }
  }

  _updatePages(routeState, madeCookieDecision) {
    if (routeState == null || madeCookieDecision == null) {
      return
    }

    let mainPage = undefined
    let overlayPage = undefined
    let loadingOverlayVisibleIfLoading = false

    const activeChild = routeState.activeChild
    if (activeChild === 'catalog') {
      if (madeCookieDecision) {
        mainPage = 'catalog'
        loadingOverlayVisibleIfLoading = true

        let subActiveChild = routeState.children['catalog'].activeChild
        if (!subActiveChild) {
          Promise.resolve().then(() => {
            this.set('routeState.children.catalog.activeChild', 'folders')
          })
          return
        } else if (subActiveChild === 'folders') {
          const isExplodedViewShown =
            routeState.children['catalog'].children['folders'].queryParams.isExplodedViewShown
          if (isExplodedViewShown) {
            overlayPage = 'catalog-exploded-view'
          }
        } else if (subActiveChild === 'spare-parts') {
          overlayPage = 'catalog-spare-part'
          const from = routeState.children['catalog'].children['spare-parts'].queryParams.from
          if (from === 'error-code-inspector') {
            mainPage = undefined
          }
        } else if (subActiveChild === 'search') {
          overlayPage = 'catalog-search'
        } else if (subActiveChild === 'cart') {
          overlayPage = 'catalog-cart'
        } else if (subActiveChild === 'favorites') {
          overlayPage = 'catalog-favorites'
        } else if (subActiveChild === 'order') {
          overlayPage = 'catalog-order'
        }
      } else {
        overlayPage = 'accept-cookies'
      }
    } else if (activeChild === 'error-code-inspector') {
      if (madeCookieDecision) {
        overlayPage = 'error-code-inspector'
        loadingOverlayVisibleIfLoading = true
      } else {
        overlayPage = 'accept-cookies'
      }
    } else if (activeChild === 'about') {
      overlayPage = 'about'
    } else if (activeChild === 'report-problem') {
      overlayPage = 'report-problem'
    } else if (!activeChild) {
      mainPage = 'home'
    }

    this._mainPage = mainPage
    this._overlayPage = overlayPage
    this._overlayVisible = !!overlayPage
    this._overlayBackdropVisible = mainPage && !!overlayPage
    this._loadingOverlayVisibleIfLoading = loadingOverlayVisibleIfLoading

    // if (Capacitor.getPlatform() === 'ios') {
    //   if (mainPage === 'catalog') {
    //     StatusBar.styleDefault() // Black text
    //   } else {
    //     StatusBar.styleLightContent() // White text
    //   }
    // }
  }

  _onNavigate(event) {
    event.stopPropagation()
    const { url, restore } = event.detail
    if (restore) {
      this.$['radiant-router'].restoreURL(url)
    } else {
      this._url = url
    }
  }

  _toJSON(obj) {
    return JSON.stringify(obj, null, 2)
  }

  _createMainSegue({ initialRender, templateNameA, templateNameB }) {
    if (initialRender) {
      return
    }
    if (templateNameA === 'home') {
      return new PushSegue()
    }
    if (templateNameB === 'home') {
      return new PopSegue()
    }
    if (templateNameA === undefined) {
      return new PushSegue()
    }
    if (templateNameB === undefined) {
      return new PopSegue()
    }
    return new SwapSegue({ both: true })
  }

  _createOverlaySegue({ initialRender, templateNameA, templateNameB }) {
    if (initialRender) {
      return
    }

    if (templateNameB === undefined) {
      return new PopSegue()
    }

    if (templateNameA === undefined) {
      return new PushSegue()
    } else if (templateNameA === 'accept-cookies') {
      return new PopSegue()
    } else if (templateNameA === 'catalog-cart') {
      if (templateNameB === 'catalog-spare-part' || templateNameB === 'catalog-order') {
        return new PushSegue()
      }
    } else if (templateNameA === 'catalog-order') {
      return new PopSegue()
    } else if (templateNameA === 'catalog-favorites') {
      if (templateNameB === 'catalog-spare-part') {
        return new PushSegue()
      }
    } else if (templateNameA === 'catalog-search') {
      if (templateNameB === 'catalog-spare-part') {
        return new PushSegue()
      }
    } else if (templateNameA === 'error-code-inspector') {
      if (templateNameB === 'catalog-spare-part') {
        return new PushSegue()
      }
    } else if (templateNameA === 'catalog-spare-part') {
      return new PopSegue()
    } else if (templateNameA === 'catalog-exploded-view') {
      if (templateNameB === 'catalog-spare-part') {
        return new PushSegue()
      }
    }

    return new SwapSegue({ both: true })
  }

  _back() {
    let animatedPages = this._overlayVisible
      ? this.$['overlay-animated-pages']
      : this.$['main-animated-pages']

    const elements = []
    for (let child of Array.from(animatedPages.children)) {
      if (child.tagName !== 'TEMPLATE' && !child.hidden) {
        elements.push(child)
      }
    }

    if (elements.length === 1) {
      elements[0].dispatchEvent(new CustomEvent('wolf-back'))
    }
  }

  _onKeydown(event) {
    if (event.key === 'Escape') {
      this._back()
    }
  }

  _onBackButton() {
    this._back()
  }

  _onOverlayTap(event) {
    const element = event.composedPath()[0]
    if (element.parentElement === this.$['overlay-animated-pages']) {
      if (this._mainPage === 'catalog') {
        this._url = '/catalog'
      }
    }
  }

  _and(a, b) {
    return a && b
  }

  _computeLoadingOverlayPage(show) {
    return show ? 'loading-overlay' : undefined
  }

  _createLoadingOverlaySegue({ initialRender, templateNameA, templateNameB }) {
    if (initialRender) {
      return
    }
    if (templateNameA === 'loading-overlay') {
      return new PopSegue()
    }
    if (templateNameB === 'loading-overlay') {
      return new PushSegue()
    }
  }

  _acceptCookies() {
    console.log('accepting cookies')
    localStorage.setItem('decidedCookiesVersion', COOKIES_VERSION)
    localStorage.setItem('acceptedCookies', 'true')
    this._madeCookieDecision = true
    this._acceptedCookies = true
  }

  _denyCookies() {
    console.log('denying cookies')
    localStorage.setItem('decidedCookiesVersion', COOKIES_VERSION)
    localStorage.setItem('acceptedCookies', 'false')
    this._madeCookieDecision = true
    this._acceptedCookies = false
  }

  _acceptedCookiesChange(value) {
    console.log(`Tracking is ${value ? 'enabled' : 'disabled'}`)
    facebookTracking.setEnabled(value)
    googleAnalytics.setEnabled(value)
  }

  _onFromShopChanged(fromShop) {
    if (typeof fromShop === 'string') {
      console.log(`Detected from-shop query parameter with value "${fromShop}"`)

      const selectedShopId = fromShop || 'france.wolf.shop'
      console.log(
        `Using shop "${selectedShopId}". Email order method is now disabled for the duration of this session`
      )
      this.store.selectedShopAndDisableEmailOrderMethodForCurrentSession({ id: selectedShopId })

      this.set('routeState.queryParams.fromShop', null)
    }
  }

  _onFromShopURLChanged(url) {
    if (typeof url === 'string') {
      console.log(`Detected from-shop-url query parameter with value "${url}"`)

      this.store.selectedShopAndDisableEmailOrderMethodForCurrentSession({ url })

      this.set('routeState.queryParams.fromShopURL', null)
    }
  }

  _onURLChanged(url) {
    const urlWithoutQueryParams = url.split('?')[0]
    if (this._lastSeenURLWithoutQueryParams !== urlWithoutQueryParams) {
      googleAnalytics.onPageChange(url)
    }
    this._lastSeenURLWithoutQueryParams = urlWithoutQueryParams
  }
}

customElements.define(ServiceApp.is, ServiceApp)
