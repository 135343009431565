import { PolymerElement, html } from '@polymer/polymer/polymer-element.js'
import 'animated-pages/lib/animated-pages.js'
import SwapSegue from 'animated-pages/lib/segues/swap-segue.js'
import { PopSegue, PushSegue } from 'animated-pages/lib/segues/stack-segues'
import './about-index-section.js'
import './about-document-section.js'
import navigate from '../../mixins/navigate.js'

class AboutPage extends navigate(PolymerElement) {
  static get template() {
    return html([require('./about-page.html')])
  }

  static get is() {
    return 'about-page'
  }

  static get properties() {
    return {
      document: { type: String, observer: '_onDocumentChange' },
      _page: { type: String },
      acceptedCookies: { type: Boolean },
    }
  }

  ready() {
    super.ready()
    this.addEventListener('wolf-back', this._onBack)
  }

  _showDocument(event) {
    const document = event.detail
    this.navigateTo('/about/' + document)
  }

  _showIndex() {
    this.navigateTo('/about')
  }

  _onDocumentChange(document) {
    if (document) {
      this._page = { templateName: 'document', data: document }
    } else {
      this._page = 'index'
    }
  }

  _createSegue({ initialRender, templateNameA, templateNameB }) {
    if (templateNameA === 'index') {
      return new PushSegue()
    }
    if (templateNameB === 'index') {
      return new PopSegue()
    }
    return new SwapSegue({ both: true })
  }

  _onBack() {
    if (this.document) {
      this.navigateTo('/about')
    } else {
      this.navigateTo('/')
    }
  }
}
customElements.define(AboutPage.is, AboutPage)
