const $_documentContainer = document.createElement('div')
$_documentContainer.setAttribute('style', 'display: none;')

$_documentContainer.innerHTML = `<custom-style>
  <style is="custom-style">
    :root {
      --wolf-red: #E4002B;
      --wolf-dark-text-color: #333333;
      --wolf-light-gray-background-color: #f3f3f3;
    }
  </style>
</custom-style>`

document.head.appendChild($_documentContainer)
