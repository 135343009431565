import { PolymerElement, html } from '@polymer/polymer/polymer-element.js'
import '@polymer/iron-icon/iron-icon.js'
import '@polymer/paper-ripple/paper-ripple.js'
import '../../utils/wolf-icons.js'
import './row-shared-styles.js'
import navigate from '../../mixins/navigate.js'

class FolderRow extends navigate(PolymerElement) {
  static get template() {
    return html([require('./folder-row.html')])
  }

  static get is() {
    return 'folder-row'
  }

  static get properties() {
    return {
      showPath: { value: false, type: Boolean, reflectToAttribute: true },
      folder: { type: Object, observer: '_onFolderChange' },
      isHeading: { value: false, type: Boolean, reflectToAttribute: true },
      disabled: { value: false, type: Boolean },
      noIcon: { value: false, type: Boolean, reflectToAttribute: true },
      _headingRows: Array,
      actionMode: { type: Boolean, reflectToAttribute: true, value: false },
    }
  }

  ready() {
    super.ready()
    this.addEventListener('tap', this._onTap)
  }

  _onTap(event) {
    if (this.actionMode) {
      event.stopPropagation()
      event.preventDefault()
      return
    }
    this.$.ripple.uiDownAction(event)
    this.$.ripple.uiUpAction(event)
  }

  _onFolderChange(folder) {
    this._headingRows = [folder.title, folder.infoSubtitle, folder.subtitle].filter(function (x) {
      return x
    })
  }
}
customElements.define(FolderRow.is, FolderRow)
