import { PolymerElement, html } from '@polymer/polymer/polymer-element.js'
import { mixinBehaviors } from '@polymer/polymer/lib/legacy/class'
import '../../../utils/fonts/wolf-fonts.js'
import translated from '../../../mixins/translated.js'

class AssemblyInstructionsLink extends translated(PolymerElement) {
  static get template() {
    return html([require('./assembly-instructions-link.html')])
  }

  static get is() {
    return 'assembly-instructions-link'
  }
}
customElements.define(AssemblyInstructionsLink.is, AssemblyInstructionsLink)
