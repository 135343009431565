import { PolymerElement, html } from '@polymer/polymer/polymer-element.js'
import { mixinBehaviors } from '@polymer/polymer/lib/legacy/class.js'
import { IronResizableBehavior } from '@polymer/iron-resizable-behavior/iron-resizable-behavior.js'
import '@polymer/neon-animation/neon-animated-pages.js'
import 'animated-pages/lib/animated-pages.js'
import { PushSegue, PopSegue } from 'animated-pages/lib/segues/stack-segues.js'
import FadeSegue from 'animated-pages/lib/segues/fade-segue.js'
import '../../utils/wolf-colors.js'
import '../../data/elements/get-folder.js'
import '../../data/elements/get-spare-part.js'
import translated from '../../mixins/translated.js'
import './toolbar/catalog-toolbar.js'
import './folders/catalog-folder.js'
import navigate from '../../mixins/navigate.js'
import { logEvent } from '../../utils/facebook-tracking/facebook-tracking.js'

const SuperClass = mixinBehaviors([IronResizableBehavior], PolymerElement)
let id = 1

class CatalogPage extends navigate(translated(SuperClass)) {
  static get template() {
    return html([require('./catalog-page.html')])
  }

  static get is() {
    return 'catalog-page'
  }

  static get properties() {
    return {
      routeState: { type: Object, notify: true },
      folder: { type: String },
      folderId: { type: String, notify: true },
      sparePart: { type: Object },
      sparePartId: { type: String, notify: true },
      isOverlayOnScreen: { type: Boolean, value: false },
      isExplodedViewShown: { type: Boolean, notify: true },
      store: { type: Object },
      _loading: {
        type: Boolean,
        reflectToAttribute: true,
        value: true,
      },
      _selectedPage: { type: Object },
    }
  }

  constructor() {
    super()
    this._lastFolderBackNavigation = 0
  }

  ready() {
    super.ready()
    this.store.load(['folders', 'spareParts']).then(() => (this._loading = false))
    this.addEventListener('wolf-back', this._onBack)
    this.addEventListener('animated-pages-page-did-enter', this._didEnter)
  }

  static get observers() {
    return ['_onFolderChange(folder)']
  }

  _didEnter() {
    logEvent('visit_catalog')
  }

  _onFolderChange(folder) {
    let selectedPage
    if (folder) {
      selectedPage = { templateName: 'folder', id: '' + id++, data: folder }
    }
    this._selectedPage = selectedPage
  }

  _computeSelectedPage(folder) {
    return folder ? { templateName: 'folder', id: '' + id++, data: folder } : undefined
  }

  _createSegue({ dataA, dataB, initialRender }) {
    if (initialRender || !dataA || !dataB) {
      return
    } else if (dataB.hasAnchestor(dataA)) {
      return new PushSegue()
    } else if (dataA.hasAnchestor(dataB)) {
      return new PopSegue()
    } else {
      return new FadeSegue({ both: true })
    }
  }

  _onBack() {
    if (this.folder && this.folder.parent) {
      var backFolder = this.folder.parent
      if (backFolder.parent && backFolder.parent.style.depth === 2) {
        backFolder = backFolder.parent
      }
      this.navigateToFolder(backFolder)
      this._lastFolderBackNavigation = Date.now()
    } else {
      // Ensure that 500ms have passed since last navigation
      // Otherwise it's annoying to the user
      if (Date.now() - this._lastFolderBackNavigation > 500) {
        this.navigateTo('/')
      }
    }
  }
}
customElements.define(CatalogPage.is, CatalogPage)
