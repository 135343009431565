/**
The `MouseGesture` class handles the mouse events. It takes three
callback methods:
- `start()` Called when a new mouse gesture starts
- `update({ deltaPosition, deltaScale, center })` Called everytime the mouse
  cursor is dragged or the wheel was turned. The `center` can and should be
  used as a fixpoint when scaling the content.
- `end({ velocity })` Called at the end of the mouse gesture. The callback
  provides the `velocity` (in px/ms) which can be used to animate the content.
*/
export default class MouseGesture {
  constructor(element, callbacks) {
    this._callbacks = callbacks
    this._element = element

    const e = element
    const w = window
    this._listeners = [
      { target: e, type: 'mousedown', fn: this._onMouseDown.bind(this) },
      { target: e, type: 'wheel', fn: this._onWheel.bind(this) },
      { target: e, type: 'dragstart', fn: this._onDragStart.bind(this) },
      { target: w, type: 'mousemove', fn: this._onGlobalMouseMove.bind(this) },
      { target: w, type: 'mouseup', fn: this._onGlobalMouseUp.bind(this) },
    ]
    for (let l of this._listeners) {
      l.target.addEventListener(l.type, l.fn)
    }

    this._position = [0, 0]
    this._velocity = [0, 0]
    this._isMouseDown = false
    this._wasWheelTurned
    this._wheelTimeoutId = 0
  }

  destroy() {
    for (let l of this._listeners) {
      l.target.removeEventListener(l.type, l.fn)
    }
  }

  _onMouseDown(event) {
    this._isMouseDown = true
    this._time = currentTimeInSeconds()
    this._startGestureIfAppropriate()
  }

  _onWheel(event) {
    this._wasWheelTurned = true
    this._startGestureIfAppropriate()

    var multiplier = 1
    if (event.deltaMode === 1) {
      multiplier = 10
    }

    var deltaScale = Math.exp(-0.002 * event.deltaY * multiplier)
    this._callbacks.updateCallback({
      deltaPosition: [0, 0],
      deltaScale: deltaScale,
      center: this._position,
    })

    clearTimeout(this._wheelTimeoutId)
    this._wheelTimeoutId = setTimeout(
      function () {
        this._wasWheelTurned = false
        this._endGestureIfAppropriate()
      }.bind(this),
      150
    )
  }

  _onDragStart(event) {
    event.preventDefault()
  }

  _onGlobalMouseMove(event) {
    const prevPosition = this._position

    const rect = this._element.getBoundingClientRect()
    const position = [event.clientX - rect.left, event.clientY - rect.top]

    this._position = position

    const time = currentTimeInSeconds()
    const prevTime = this._time
    this._time = time

    if (this._isMouseDown) {
      const deltaPosition = [position[0] - prevPosition[0], position[1] - prevPosition[1]]

      const deltaTime = time - prevTime
      this._velocity = [deltaPosition[0] / deltaTime, deltaPosition[1] / deltaTime]

      this._callbacks.updateCallback({
        deltaPosition: deltaPosition,
        deltaScale: 1,
        center: position, // Not really need, 'cause scale is 1
      })
    }
  }

  _onGlobalMouseUp() {
    this._isMouseDown = false
    this._endGestureIfAppropriate()
    this._velocity = [0, 0]
  }

  _startGestureIfAppropriate() {
    if (!this._isStarted) {
      this._isStarted = true
      this._callbacks.startCallback()
    }
  }

  _endGestureIfAppropriate() {
    if (this._isStarted && !this._wasWheelTurned && !this._isMouseDown) {
      this._isStarted = false
      this._callbacks.endCallback({ velocity: this._velocity })
    }
  }
}

function currentTimeInSeconds() {
  return performance.now() / 1000
}
