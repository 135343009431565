const $_documentContainer = document.createElement('div')
$_documentContainer.setAttribute('style', 'display: none;')

$_documentContainer.innerHTML = `<dom-module id="wolf-app-toolbar">
  <template>
    <style is="custom-style">
      .wolf-app-toolbar {
        padding: 0 5px;
      }
      .wolf-app-toolbar > paper-icon-button {
        border: 1px solid #ccc;
        background: white;
        border-radius: 50%;
        flex: 0 0 auto;
      }

      .wolf-app-toolbar > [main-title] {
        text-align: center;
        flex-basis: 0px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0 12px;
      }

      .wolf-app-toolbar iron-icon {
        color: var(--wolf-red);
        flex: 0 0 auto;
      }

      .wolf-app-toolbar > #back-button {
        padding-left: 4px;
      }

      .wolf-app-toolbar > paper-button {
        border: 1px solid #ccc;
        background: white;
        border-radius: 10px;
        padding: 7px 12px;
        text-transform: none;
        white-space: nowrap;
        min-width: 0;
      }
    </style>
  </template>
</dom-module>`

document.head.appendChild($_documentContainer)
