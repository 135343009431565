import { Capacitor } from '@capacitor/core';
import '@polymer/paper-button/paper-button.js';
import '@polymer/paper-checkbox/paper-checkbox.js';
import { html, PolymerElement } from '@polymer/polymer/polymer-element.js';
import { AppTrackingTransparency } from "capacitor-ios-app-tracking";
import navigate from '../../mixins/navigate.js';
import translated from '../../mixins/translated.js';
import '../../utils/wolf-colors.js';

class AcceptCookiesPage extends navigate(translated(PolymerElement)) {
  static get template() {
    return html([require('./accept-cookies-page.html')])
  }

  static get is() {
    return 'accept-cookies-page'
  }

  static get properties() {
    return {
      _iOSClass: {
        type: String,
        value() {
          return Capacitor.getPlatform() === 'ios' ? 'ios' : ''
        },
      },
    }
  }

  ready() {
    super.ready()
    this.addEventListener('wolf-back', this._onBack)
    this.addEventListener('animated-pages-page-did-enter', this._didEnter)
  }

  _didEnter() {
    if (Capacitor.getPlatform() === 'ios') {
      AppTrackingTransparency.requestPermission().then((result) => {
        console.log('User made tracking decision')
        if (result.status === 'authorized') {
          this.dispatchEvent(new CustomEvent('wolf-accept-cookies'))
        } else {
          this.dispatchEvent(new CustomEvent('wolf-deny-cookies'))
        }
      })
    }
  }

  _acceptCookies() {
    this.dispatchEvent(new CustomEvent('wolf-accept-cookies'))
  }

  _denyCookies() {
    this.dispatchEvent(new CustomEvent('wolf-deny-cookies'))
  }

  _onBack() {
    this.navigateTo('/')
  }

  _replacePrivacyPolicyLink(text) {
    return text.replace('{{privacyPolicyLink}}', '/about/privacy-policy')
  }
}
customElements.define(AcceptCookiesPage.is, AcceptCookiesPage)
