let enabled = false
let loaded = false

function load() {
  if (!enabled) {
    return
  }
  if (!loaded) {
    console.log('Starting Google Analytics')
    ;(function (i, s, o, g, r, a, m) {
      i['GoogleAnalyticsObject'] = r
      ;(i[r] =
        i[r] ||
        function () {
          ;(i[r].q = i[r].q || []).push(arguments)
        }),
        (i[r].l = 1 * new Date())
      ;(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0])
      a.async = 1
      a.src = g
      m.parentNode.insertBefore(a, m)
    })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga')

    ga('create', 'UA-84088976-13', 'auto')
    ga('set', 'anonymizeIp', true)
    loaded = true
  }
}

export function setEnabled(value) {
  enabled = value
  if (enabled) {
    load()
  }
}

export function onPageChange(url) {
  if (!enabled) {
    return
  }
  ga('set', 'page', url)
  ga('send', 'pageview')
}

