import { Capacitor } from '@capacitor/core'
import { IronResizableBehavior } from '@polymer/iron-resizable-behavior/iron-resizable-behavior.js'
import { mixinBehaviors } from '@polymer/polymer/lib/legacy/class.js'
import { html, PolymerElement } from '@polymer/polymer/polymer-element.js'
import navigate from '../../mixins/navigate.js'
import translated from '../../mixins/translated.js'
import '../../utils/fonts/wolf-fonts.js'
import '../../utils/wolf-colors.js'
import '../../utils/wolf-heading-bar.js'
import '../../utils/wolf-icons.js'
import '../../utils/wolf-scrollbar.js'
import './links/assembly-instructions-link.js'
import './links/shk-link.js'
import './links/summiteer-link.js'
import './menu-buttons/wolf-home-catalog-menu-button.js'
import './menu-buttons/wolf-home-error-code-inspector-menu-button.js'
import './wolf-home-overflow-menu.js'

const SuperClass = mixinBehaviors([IronResizableBehavior], PolymerElement)

class HomePage extends navigate(translated(SuperClass)) {
  static get template() {
    return html([require('./home-page.html')])
  }

  static get is() {
    return 'home-page'
  }

  static get properties() {
    return {
      lessFeatures: {
        type: Boolean,
        reflectToAttribute: true,
        value: true,
        computed: '_computeLessFeatures(t)',
      },
      _menuButtonSize: { type: String, value: 'large' },
      _useSmallImages: { type: String, value: 'large' },
    }
  }

  ready() {
    super.ready()
    this.addEventListener('iron-resize', this._onIronResize)
    this.addEventListener('wolf-back', this._onBack)
  }

  _onIronResize() {
    if (this.clientWidth === 0) {
      return
    }

    if (this.clientWidth >= 500) {
      this._menuButtonSize = 'large'
    } else if (this.clientWidth >= 200) {
      this._menuButtonSize = 'medium'
    } else {
      this._menuButtonSize = 'small'
    }

    this._useSmallImages = this.clientWidth < 500
  }

  _showCatalog() {
    this.navigateTo('/catalog')
  }

  _showErrorCodes() {
    this.navigateTo('/error-code-inspector')
  }

  _computeLessFeatures(t) {
    var langsWithErrorCodeInspector = [
    'de',
    'en', 
    'es', 
    'fr', 
    'it', 
    'pl', 
    'ru', 
    'cs', 
    'sk', 
    'nl',
    'zh'
    ]
    return langsWithErrorCodeInspector.indexOf(this.language) === -1
  }

  _computeFiveYearWarrantyImage(locale) {
    return {
      'de-DE': require('./images/five-year-warranty-de.svg'),
      'fr-FR': require('./images/five-year-warranty-fr.svg'),
      'it-IT': require('./images/five-year-warranty-it.svg'),
    }[locale]
  }

  _onBack() {
    if (Capacitor.getPlatform() !== 'web') {
      navigator.app.exitApp()
    }
  }
}
customElements.define(HomePage.is, HomePage)
