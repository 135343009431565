import { PolymerElement } from '@polymer/polymer/polymer-element.js'

class GetFolder extends PolymerElement {
  static get is() {
    return 'get-folder'
  }

  static get properties() {
    return {
      store: { type: Object },
      folderId: { type: String, notify: true },
      folder: { type: Object, notify: true },
    }
  }

  static get observers() {
    return ['_onFolderIdChange(folderId, store.status.folders.loaded)', '_onFolderChange(folder)']
  }

  _onFolderIdChange(folderId, loaded) {
    if (this.store && loaded) {
      if (typeof folderId === 'string') {
        this.folder = this.store.rootFolder.get(folderId) || null
      } else {
        this.folder = null
      }
    }
  }

  _onFolderChange(folder) {
    let folderId = null
    if (folder) {
      folderId = folder.pathRelativeTo(this.store.rootFolder)
    }
    this.folderId = folderId
  }
}
customElements.define(GetFolder.is, GetFolder)
