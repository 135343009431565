import { PolymerElement, html } from '@polymer/polymer/polymer-element.js'
import './catalog-toolbar-breadcrumbs-item.js'

class CatalogToolbarBreadcrumbs extends PolymerElement {
  static get template() {
    return html([require('./catalog-toolbar-breadcrumbs.html')])
  }

  static get is() {
    return 'catalog-toolbar-breadcrumbs'
  }

  static get properties() {
    return {
      folder: { type: Object, observer: '_onFolderChange' },
    }
  }

  constructor() {
    super()
    this._elements = []
  }

  _onFolderChange(newFolder) {
    var oldElements = this._elements

    var anchestors = []
    var current = newFolder
    while (current) {
      anchestors.push(current)
      current = current.parent
    }

    const newElements = anchestors.map((folder) => {
      const element = document.createElement('catalog-toolbar-breadcrumbs-item')
      element.folder = folder
      this.root.appendChild(element)
      return element
    })

    oldElements.forEach((element) => {
      this.root.removeChild(element)
    })

    this._elements = newElements
  }
}
customElements.define(CatalogToolbarBreadcrumbs.is, CatalogToolbarBreadcrumbs)
