export default {
  _: {
    formatDate,
    feedbackEmail: 'Sascha.Muehl@wolf.eu',
  },
  'de-DE': {
    localeTitle: 'Deutsch - Deutschland',
    autoLocale: 'Auto (Deutsch - Deutschland)',
    fiveYearWarrantyLink: 'https://www.wolf.eu/service/wolf-5-jahre-garantie/',
    orderEmailRecipient: null,
    summiteerSlogan: ['Gipfelstürmer werden', 'doppelt punkten'],
    summiteerLink:
      'https://www.wolf.eu/shk-profi/service-fuer-profis/wolf-partnerprogramm-gipfelstuermer/',
    formatPrice: (price, currency) => formatPrice(price, currency, ','),
  },
  'de-CH': {
    localeTitle: 'Deutsch - Schweiz',
    autoLocale: 'Auto (Deutsch - Schweiz)',
    orderEmailRecipient: 'info@wolf-klimatechnik.ch',
    formatPrice: (price, currency) => formatPrice(price, currency, ','),
  },
  'de-AT': {
    localeTitle: 'Deutsch - Österreich',
    autoLocale: 'Auto (Deutsch - Österreich)',
    orderEmailRecipient: 'heizung@wolfnet.at',
    profiPortalLink: 'https://www.wolf-heiztechnik.at/',
    assemblyInstructionsLink:
      'https://www.wolf.eu/de-de/professional/downloads',
    formatPrice: (price, currency) => formatPrice(price, currency, ','),
  },
  'de-BE': {
    localeTitle: 'Deutsch - Belgien',
    autoLocale: 'Auto (Deutsch - Belgien)',
    formatPrice: (price, currency) => formatPrice(price, currency, ','),
  },
  de: {
    appName: 'Wolf Service App',
    appDescription: 'Der Wolf Ersatzteilkatalog für ihr Handy, Tablet oder ihren PC.',
    compatibilityNote:
      'iOS Version erfordert iPhone 4S, iPad 2 oder höher. Android Version erfordet Android 4.3 oder höher. Web Version erfordet Chrome, Firefox oder Safari.',
    tryItOut: 'Probieren Sie mal…',
    spareParts: 'Ersatzteile',
    explodedView: 'Explosionszeichnung',
    noImage: 'Kein Bild',
    sku: 'Art.-Nr.',
    ean: 'EAN',
    price: 'Preis',
    searchCatalog: 'Katalog durchsuchen...',
    cancel: 'Abbrechen',
    cart: 'Einkaufswagen',
    clear: 'Leeren',
    cartIsEmpty: 'Ihr Einkaufswagen ist leer',
    order: 'Bestellen',
    orderNote: 'Per E-Mail an Ihren Händler',
    proceedToEmailApp: 'Weiter zur Email-App',
    sparePartsCatalog: 'Ersatzteilkatalog',
    imprintAndPolicies: 'Impressum und Rechtliches',
    termsOfUse: 'Nutzungsbedingungen',
    imprint: 'Impressum',
    generalTermsAndConditions: 'AGB',
    needToAcceptTermsOfUse: 'Sie müssen den Nutzungsbedingungen zustimmen.',
    accept: 'Zustimmen',
    decline: 'Ablehnen',
    readTermsOfUse: 'Nutzungsbedingungen lesen',
    scanBarcode: 'Strichcode lesen',
    aboutServiceApp: 'Was ist die Service App?',
    createdWith: 'Erstellt mit der Wolf Service App.',
    search: 'Suche',
    pieces: 'Stück',
    personalInfo: 'Persönliche Angaben',
    personalInfoNote: 'Die persönlichen Angaben erscheinen im E-Mail-Text über der Ersatzteilliste',
    recipientEmailAddress: 'E-Mail-Adresse des Empfängers',
    emailSubject: 'Wolf Ersatzteilbestellung',
    optional: 'optional',
    loadingCatalog: 'Lade den Katalog',
    reportProblem: 'Feedback geben',
    priceNote:
      'Alle Preise sind unverbindliche Angaben des Herstellers. Die Preisgestaltung obliegt dem jeweiligen Händler. Stand {{date}}.',
    since: 'ab',
    until: 'bis',
    january: 'Januar',
    february: 'Februar',
    march: 'März',
    april: 'April',
    may: 'Mai',
    june: 'Juni',
    july: 'Juli',
    august: 'August',
    september: 'September',
    october: 'Oktober',
    november: 'November',
    december: 'Dezember',
    sparePartsCatalog: 'Ersatzteilkatalog',
    languageAndRegion: 'Sprache und Region',
    reportProblemHeading: 'Haben Sie ein Problem mit der App?',
    reportProblemNote: 'Senden Sie uns doch eine E-Mail. Wir helfen Ihnen gerne weiter.',
    reportProblemSideNote:
      'Bitte verfassen Sie Ihre E-Mail auf Deutsch oder Englisch. Bei Fragen zu Produkten oder Preisen, wenden Sie sich bitte an den [Wolf Kundendienst](https://www.wolf.eu/service/kundendienstbeauftragung/).',
    sendEmail: 'E-Mail senden',
    developedBy: 'Entwickelt von Josef Brandl Software',
    privacyPolicy: 'Datenschutzerklärung',
    errorCodeInspector: 'Fehlercodeinspektor',
    errorCodeInspectorSegmented: ['Fehlercode-', 'inspektor'],
    sparePartsCatalogSegmented: ['Ersatzteil-', 'katalog'],
    slogan: 'VOLL AUF MICH EINGESTELLT.',
    errorCode: 'Fehlercode',
    product: 'Produkt',
    issue: 'Problem',
    cause: 'Ursache',
    solution: 'Lösung',
    loadingErrorCodeInspector: 'Lade den Fehlercodeinspektor',
    discontinuedSparePartNotes: {
      unchanged: 'Material ist gleich geblieben, nur die Artikelnummer wurde umgestellt',
      changed:
        'Artikel hat sich technisch geändert, bitte ausscheiden und ggf. durch neuen Artikel ersetzen',
      discontinued: 'Material ersatzlos ausgeschieden',
      unchangedPlusAccessories:
        'Material ist gleich geblieben, nur die Artikelnummer wurde umgestellt. Neue Artnr. enthält Zubehörartikel',
    },
    wolfYouTubeChannel: 'Wolf YouTube Kanal',
    wolfYouTubeChannelLink: 'https://www.youtube.com/user/wolfmainburg',
    discontinuedSparePart: 'Dieses Ersatzteil gibt es nicht mehr',
    sparePartUnavailable: 'Ersatzteil nicht verfügbar',
    stats:
      'Ersatzteilkatalog mit {{sparePartCount}} Ersatzteilen, {{sparePartPhotoCount}} Ersatzteilfotos, {{folderCount}} Ordnern und {{explodedViewCount}} Explosionszeichnungen',
    yourPhoneNumber: 'Ihre Telefonnummer',
    yourCustomerIdAtTheVendor: 'Ihre Kundennummer beim Händler',
    additionalInformation: 'Weitere Angaben',
    customerId: 'Kundennummer',
    phoneNumber: 'Telefonnummer',
    notAllMandatoryFieldsFilledOut: 'Es wurden nicht alle Pflichtfelder asugefüllt.',
    profiPortal: 'SHK-Profi',
    profiPortalLink: 'https://www.wolf.eu/shk-profi/',
    usedIn: 'Verwendet in',
    numFolders: '{{numFolders}} Geräten',
    numFolders1: '1 Gerät',
    orderEmailRecipientButton: 'Auf {{emailRecipient}} zurücksetzen',
    loading: 'Lade...',
    definition: 'Definition',
    folders: 'Ordner',
    errorCodes: 'Fehlercodes',
    backToHome: 'Zurück zur Startseite',
    noResults: 'Keine Treffer',
    maySendAnalytics: 'Nutzungsdaten dürfen übertragen werden, Cookies werden verwendet (optional)',
    assemblyInstructions: 'Montageanleitungen',
    assemblyInstructionsLink:
      'https://www.wolf.eu/de-de/professional/downloads',
    settings: 'Einstellungen',
    exclVAT: 'exkl. MwSt.',
    searchedRecently: 'Kürzlich gesucht',
    edit: 'Bearbeiten',
    done: 'Fertig',
    deleteAll: 'Alle löschen',
    favorites: 'Favoriten',
    addToFavorites: 'Favorisieren',
    inFavorites: 'In Favoriten',
    noFavoritesYet: 'Noch keine Favoriten',
    favoritesNote:
      'Ordner und Ersatzteile können über den Button mit dem Stern als Favorit markiert werden',
    openCart: 'Einkaufswagen öffnen',
    restoreCartContent: 'Einkaufswageninhalt wiederherstellen',
    searchingWithin: 'Suche innerhalb:',
    back: 'Zurück',
    share: 'Teilen',
    orderViaShop: 'Über den Shop bestellen',
    continueToShop: 'Warenkorb an Partnershop übergeben',
    orderViaEmail: 'Per Email bestellen',
    proTip: 'Profi-Tipp',
    cookieDialog: {
      title: 'Wir verwenden Cookies',
      note: 'Um die Funktion der Website zu gewährleisten und Ihnen unter anderem Informationen zu Ihren Interessen anzuzeigen. Mit dem Klick auf den Button "Zustimmen" erklären Sie sich mit der Verwendung von Cookies einverstanden. Für weitere Informationen über die Nutzung von Cookies oder für Änderung und Widerruf Ihrer Einstellungen klicken Sie bitte auf <a href="#{{privacyPolicyLink}}">Datenschutzerklärung</a>.',
      onlyNecessaryCookies: 'Nur notwendige Cookies',
      acceptAllCookies: 'Zustimmen',
    },
  },
  'es-ES': {
    localeTitle: 'Español - España',
    autoLocale: 'Auto (Español - España)',
    orderEmailRecipient: 'pedidos.repuestos@wolf.eu',
    formatPrice: (price, currency) => formatPrice(price, currency, ','),
  },
  es: {
    appName: 'Wolf Service App',
    appDescription: 'Su catalogo de repuestos Wolf para movil, tablet su PC.',
    compatibilityNote:
      'Versiones necesarias iOS iPhone 4S, iPad 2 o mayor. Version Android necesaria Android 4.3 o mayor. necesario version Web Chrome, Firefox o Safari.',
    tryItOut: 'Por favor pruebe...',
    spareParts: 'Repuestos',
    explodedView: 'Dibujo Explosionado',
    noImage: 'Sin imagen',
    sku: 'Nº de articulo',
    ean: 'EAN',
    price: 'Precio',
    searchCatalog: 'Busqueda catalogo...',
    cancel: 'Interrrupir',
    cart: 'Carro de compra',
    clear: 'Vaciar',
    cartIsEmpty: 'Su carro de compra esta vacio',
    order: 'Pedido',
    orderNote: 'Por E-Mail a distribuidor',
    proceedToEmailApp: 'Seguir a Email de App',
    sparePartsCatalog: 'Catálogo de repuestos',
    imprintAndPolicies: 'Impreso y condiciones generales',
    termsOfUse: 'Condiciones de uso',
    imprint: 'Impreso',
    generalTermsAndConditions: 'AGB',
    needToAcceptTermsOfUse: 'Debe aceptar las Condiciones de uso.',
    accept: 'Aceptar',
    decline: 'Rechazar',
    readTermsOfUse: 'Leer Condiciones de uso',
    scanBarcode: 'Leer el código de barras',
    aboutServiceApp: 'øQue es el servicio App?',
    createdWith: 'Elaborado con la Wolf Service App.',
    search: 'Buscar',
    pieces: 'Unidad',
    personalInfo: 'Datos personales',
    personalInfoNote:
      'Los datos personales aparecen en el texto del e-mail sobre la lista de repuestos',
    recipientEmailAddress: 'Dirección e-mail del destinatario',
    emailSubject: 'Pedido de repuestos Wolf',
    optional: 'opcional',
    loadingCatalog: 'Cargando catálogo',
    reportProblem: 'Dar Feedback',
    priceNote:
      'Todos los precios no son vinculantes. El precio es responsabilidad del distribuidor respectivo. Tarifa {{date}}.',
    since: 'desde',
    until: 'hasta',
    january: 'Enero',
    february: 'Febrero',
    march: 'Marzo',
    april: 'Abril',
    may: 'Mayo',
    june: 'Junio',
    july: 'Julio',
    august: 'Agosto',
    september: 'Septiembre',
    october: 'Octubre',
    november: 'Noviembre',
    december: 'Diciembre',
    languageAndRegion: 'Idioma y Región',
    reportProblemHeading: '¿Tiene un problema con la App?',
    reportProblemNote: 'Envíenos un e-mail. Estaremos encantados de ayudarle.',
    reportProblemSideNote:
      'Por favor, escriba su e-mail en español o inglés. Para consultas sobre productos o precios, envíe un e-mail a <wisa@wolfiberica.es>',
    sendEmail: 'Enviar e-mail',
    developedBy: 'Desarrollado por Josef Brandl Software',
    privacyPolicy: 'Política de privacidad',
    errorCodeInspector: 'Inspector de código de error',
    errorCodeInspectorSegmented: ['Inspector-', 'código de error'],
    sparePartsCatalogSegmented: ['Catálogo-', 'de repuestos'],
    slogan: 'PENSADO PARA TI.',
    errorCode: 'Código de error',
    product: 'Producto',
    issue: 'Fallo',
    cause: 'Causa',
    solution: 'Remedio',
    loadingErrorCodeInspector: 'Cargando buscador de códigos de error',
    discontinuedSparePartNotes: {
      unchanged: 'El material es el mismo, solo cambia el numero de articulo',
      changed: 'El articulo ha sufrido modificaciones tecnicas, sustituir por articulo nuevo',
      discontinued: 'Material obsoleto sin sustitucion',
      unchangedPlusAccessories:
        'El material es el mismo, solo cambia el numero de articulo. El nuevo articulo contiene accesorios.',
    },
    wolfYouTubeChannel: null,
    wolfYouTubeChannelLink: null,
    discontinuedSparePart: 'Repuesto descatalogado',
    sparePartUnavailable: 'Repuesto no disponible',
    stats:
      'Catálogo de piezas de recambio con {{sparePartCount}} piezas de recambio, {{sparePartPhotoCount}} fotos de las piezas, {{folderCount}} despiece y {{explodedViewCount}} piezas.',
    yourPhoneNumber: 'Su número de teléfono',
    yourCustomerIdAtTheVendor: 'Su número de cliente en el concesionario',
    additionalInformation: 'Más información',
    customerId: 'Número de cliente',
    phoneNumber: 'Número de teléfono',
    notAllMandatoryFieldsFilledOut: 'No todos los campos obligatorios fueron completados.',
    profiPortal: 'Descargas',
    profiPortalLink: 'https://spain.wolf.eu/portalparaprofesionales/descargasparaprofesionales/',
    usedIn: 'Usado en',
    numFolders: '{{numFolders}} dispositivos',
    numFolders1: '1 dispositivo',
    orderEmailRecipientButton: 'Reiniciar {{emailRecipient}}',
    definition: 'Definition',
    loading: 'Cargando...',
    folders: 'Carpetas',
    errorCodes: 'Códigos de error',
    backToHome: 'Inicio',
    noResults: 'Sin resultados',
    maySendAnalytics: '“¿Permite el envío de estadísticas de uso, se utilizan cookies? (opcional)',
    assemblyInstructions: 'Instrucciones de instalación',
    assemblyInstructionsLink:
      'https://www.wolf.eu/es-es/profesional/descargas/calefaccion',
    settings: 'Ajustes',
    exclVAT: 'excl. IVA',
    searchedRecently: 'Última búsqueda',
    edit: 'Editar',
    done: 'Hecho',
    deleteAll: 'Borrar todo',
    favorites: 'Favoritos',
    addToFavorites: 'Añadir a favoritos',
    inFavorites: 'En favoritos',
    noFavoritesYet: 'No está en favoritos todavía',
    favoritesNote:
      'Las carpetas y los repuestos pueden ser marcados como favoritos utilizando el botón estrella',
    openCart: 'Abrir cesta de la compra',
    restoreCartContent: 'Restaurar cesta de la compra',
    searchingWithin: 'Buscar dentro:',
    back: 'Atrás',
    share: 'Compartir',
    cookieDialog: {
      title: 'Usamos cookies',
      note: 'Utilizamos las cookies para personalizar el contenido y la publicidad, para proporcionar funciones de medios sociales y para analizar el tráfico de nuestro sitio web. También compartimos información sobre el uso de nuestro sitio web con nuestros socios de medios sociales, publicidad y análisis. Nuestros socios pueden combinar esta información con otra información que usted les haya proporcionado o que hayan recopilado como parte de su uso de los servicios. Puede revocar su consentimiento para el uso de cookies en cualquier momento en la <a href="#{{privacyPolicyLink}}">política de privacidad</a>.',
      onlyNecessaryCookies: 'Sólo las cookies necesarias',
      acceptAllCookies: 'Aceptar todas las cookies',
    },
  },
  'en-DE': {
    localeTitle: 'English - Germany',
    autoLocale: 'Auto (English - Germany)',
    orderEmailRecipient: null,
    formatPrice: (price, currency) => formatPrice(price, currency, ','),
  },
  'en-UK': {
    localeTitle: 'English - United Kingdom',
    autoLocale: 'Auto (English - United Kingdom)',
    orderEmailRecipient: null,
    formatPrice: (price, currency) => formatPrice(price, currency, '.'),
  },
  en: {
    appName: 'Wolf Service App',
    appDescription: 'The Wolf spare parts catalog for your mobile phone, tablet, or PC.',
    compatibilityNote:
      'The iOS version requires an iPhone 4S, iPad 2 or better. The Android version requires Android 4.3 or higher. The web version requires Chrome, Firefox, Edge or Safari.',
    tryItOut: 'Give it a try…',
    spareParts: 'Spare parts',
    explodedView: 'Exploded view',
    noImage: 'No image',
    sku: 'SKU',
    ean: 'EAN',
    price: 'Price',
    searchCatalog: 'Search catalog...',
    cancel: 'Cancel',
    cart: 'Cart',
    clear: 'Clear',
    cartIsEmpty: 'Your cart is empty',
    order: 'Order',
    orderNote: 'Via email to your vender',
    proceedToEmailApp: 'Proceed to email app',
    sparePartsCatalog: 'Spare parts catalog',
    imprintAndPolicies: 'Imprint and policies',
    termsOfUse: 'Terms of use',
    imprint: 'Imprint',
    generalTermsAndConditions: 'General terms and conditions',
    needToAcceptTermsOfUse: 'You are required to accept the terms of use.',
    accept: 'Accept',
    decline: 'Decline',
    readTermsOfUse: 'Read terms of use',
    scanBarcode: 'Scan barcode',
    aboutServiceApp: 'What is the Service App?',
    createdWith: 'Created with Wolf Service App.',
    search: 'Search',
    pieces: 'article(s)',
    personalInfo: 'Personal info',
    personalInfoNote: 'Your personal info is added to the email above the spare parts list.',
    recipientEmailAddress: 'Email address of the recipient',
    emailSubject: 'Wolf Spare Parts Order',
    optional: 'optional',
    loadingCatalog: 'Loading Catalog',
    reportProblem: 'Send Feedback',
    priceNote:
      'Prices are without obligation. The pricing is the responsibility of the respective dealer. Prices as of {{date}}.',
    since: 'since',
    until: 'until',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'Juli',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    sparePartsCatalog: 'Spare parts catalog',
    languageAndRegion: 'Language and region',
    reportProblemHeading: 'Encountered a problem with the app?',
    reportProblemNote: "Let as know via email. We're happy to help you out.",
    reportProblemSideNote:
      'Please write your email in either English or German. For questions regarding products or pricing, please reach out to our [customer service](https://www.wolf.eu/service/kundendienstbeauftragung/).',
    sendEmail: 'Send email',
    developedBy: 'Developed by Josef Brandl Software',
    privacyPolicy: 'Privacy policy',
    errorCodeInspector: 'Error code inspector',
    errorCodeInspectorSegmented: ['Error code', 'inspector'],
    sparePartsCatalogSegmented: ['Spare parts-', 'catalog'],
    slogan: 'PERFECTLY IN TUNE WITH YOU.',
    errorCode: 'Error code',
    product: 'Product',
    issue: 'Problem',
    cause: 'Cause',
    solution: 'Solution',
    loadingErrorCodeInspector: 'Loading error code inspector',
    discontinuedSparePartNotes: {
      unchanged: 'Indentical material, only SKU was changed',
      changed: 'Article underwent technical changes. Please use the revised of the article.',
      discontinued: 'Article has been discontinued without replacement',
      unchangedPlusAccessories:
        'Indentical material, only SKU was changed. New SKU contains accessories.',
    },
    wolfYouTubeChannel: 'Wolf YouTube Channel',
    wolfYouTubeChannelLink: 'https://www.youtube.com/user/wolfmainburg',
    discontinuedSparePart: 'This spare part has been discontinued',
    sparePartUnavailable: 'Spare part is unavailable',
    stats:
      'Catalog with {{sparePartCount}} spare parts, {{sparePartPhotoCount}} spare part photos, {{folderCount}} folders and {{explodedViewCount}} exploded views',
    yourPhoneNumber: 'Your phone number',
    yourCustomerIdAtTheVendor: 'Your customer id at the vendor',
    additionalInformation: 'Additional information',
    customerId: 'Customer ID',
    phoneNumber: 'Phone number',
    notAllMandatoryFieldsFilledOut: 'Not all mandatory fields were filled out.',
    profiPortal: null,
    profiPortalLink: null,
    usedIn: 'Used in',
    numFolders: '{{numFolders}} devices',
    numFolders1: '1 device',
    orderEmailRecipientButton: 'Reset to {{emailRecipient}}',
    definition: 'Definition',
    loading: 'Loading...',
    folders: 'Folders',
    errorCodes: 'Error codes',
    backToHome: 'Back to home',
    noResults: 'No results',
    maySendAnalytics: 'Allow transmission of usage data, using cookies (optional)',
    assemblyInstructions: 'Assembly instructions',
    assemblyInstructionsLink: 'https://www.wolf.eu/en-de/professional/downloads',
    settings: 'Settings',
    exclVAT: 'excl. VAT',
    searchedRecently: 'Searched recently',
    edit: 'Edit',
    done: 'Done',
    deleteAll: 'Delete all',
    favorites: 'Favorites',
    addToFavorites: 'Add to favoristes',
    inFavorites: 'In favorites',
    noFavoritesYet: 'No favorites yet',
    favoritesNote: 'Folders and spare parts can be marked as favorites by using the star button.',
    openCart: 'Open shopping cart',
    restoreCartContent: 'Restore shopping cart',
    searchingWithin: 'Searching within:',
    back: 'Back',
    share: 'Share',
    orderViaShop: 'Order via shop',
    continueToShop: 'Transfer cart to partner shop',
    orderViaEmail: 'Order via email',
    cookieDialog: {
      title: 'We use cookies',
      note: 'To ensure the functioning of the website and to display information about your interests. By clicking the "Accept all cookies" button, you agree to the use of cookies. For further information on the use of cookies or to change and revoke your settings, please click on <a href="#{{privacyPolicyLink}}">privacy policy</a>.',
      onlyNecessaryCookies: 'Only allow necessary cookies',
      acceptAllCookies: 'Accept All Cookies',
    },
  },
  'ru-RU': {
    localeTitle: 'Русский - Россия',
    autoLocale: 'Авто (Русский - Россия)',
    orderEmailRecipient: 'serviceapp@wolfrus.ru',
    formatPrice: (price, currency) => formatPrice(price, currency, ','),
  },
  ru: {
    appName: 'Wolf Service App',
    appDescription:
      'Каталог запасных частей Wolf для вашего мобильного телефона, планшета, или ПК.',
    compatibilityNote:
      'iOS требуется версия iPhone 4S, iPad 2 и выше. Android требуется версия Android 4.3 и выше. Браузер Chrome, Firefox или Safari.',
    tryItOut: 'Просто попробуйте…',
    spareParts: 'Запасные части',
    explodedView: 'Покомпонентный вид',
    noImage: 'нет фото',
    sku: 'Арт.-№',
    ean: 'EAN',
    price: 'Цена',
    searchCatalog: 'Поиск по каталогу ...',
    cancel: 'Отменить',
    cart: 'Корзина',
    clear: 'Очистить',
    cartIsEmpty: 'Ваша корзина пуста',
    order: 'Заказать',
    orderNote: 'Заказ по электронной почте вашему дилеру',
    proceedToEmailApp: 'Перейти в почтовый клиент',
    sparePartsCatalog: 'Каталог запасных частей',
    imprintAndPolicies: 'Официальное уведомление и ограничение ответственности',
    termsOfUse: 'Условия использования',
    imprint: 'Официальное уведомление',
    generalTermsAndConditions: 'AGB',
    needToAcceptTermsOfUse: 'Вы должны принять условия использования.',
    accept: 'Подтвердить',
    decline: 'Отказаться',
    readTermsOfUse: 'Ознакомиться с условиями использования',
    scanBarcode: 'Считать штрих-код',
    aboutServiceApp: 'Что такое Service App?',
    createdWith: 'Произведено компанией Wolf, Service App',
    search: 'Поиск',
    pieces: 'штук',
    personalInfo: 'Личная информация',
    personalInfoNote:
      'Личная информация появится в тексте электронного письма над списком запчастей',
    recipientEmailAddress: 'E-Mail адрес получателя',
    emailSubject: 'Wolf заказ запасных частей',
    optional: 'опционально',
    loadingCatalog: 'Загрузить каталог',
    reportProblem: 'Обратная связь',
    priceNote:
      'Все цены не являются публичной офертой. Ценообразование является обязанностью соответствующего дилера. Цены на {{date}}.',
    since: 'с',
    until: 'до',
    january: 'Январь',
    february: 'Февраль',
    march: 'Март',
    april: 'Апрель',
    may: 'Май',
    june: 'Июнь',
    july: 'Июль',
    august: 'Август',
    september: 'Сентябрь',
    october: 'Октябрь',
    november: 'Ноябрь',
    december: 'Декабрь',
    sparePartsCatalog: 'Каталог запасных частей',
    languageAndRegion: ' Язык и регион ',
    reportProblemHeading: 'Проблемы с приложением?',
    reportProblemNote: 'Отправьте нам сообщение по электронной почте. Мы здесь, чтобы помочь вам.',
    reportProblemSideNote:
      'Пожалуйста, напишите письмо на английском или немецком языке. По вопросам о продуктах или ценах, пожалуйста, позвоните в сервисную службу Wolf по номеру [+7(495) 287-49-40](tel:+74952874940).',
    sendEmail: 'Отправить по E-Mail',
    developedBy: 'Приложение разработано Josef Brandl Software',
    privacyPolicy: 'Политика конфиденциальности',
    errorCodeInspector: 'Навигатор по неисправностям',
    errorCodeInspectorSegmented: ['Навигатор', 'по неисправностям'],
    sparePartsCatalogSegmented: ['Каталог', 'запасных частей'],
    slogan: 'НАСТРОЕН НА ТЕБЯ.',
    errorCode: 'Код неисправности',
    product: 'Продукт',
    issue: 'Неисправность',
    cause: 'Причина',
    solution: ' Устранение ',
    loadingErrorCodeInspector: 'Навигатор по неисправностям',
    discontinuedSparePartNotes: {
      unchanged: 'Материал не изменился, изменился товарный номер',
      changed: 'Артикул конструктивно изменён. Пожалуйста удалите или замените на новый',
      discontinued: 'Материал полностью снят с производства',
      unchangedPlusAccessories:
        'Материал не изменился, изменился товарный номер. Новый номер содержит дополнительные позиции',
    },
    wolfYouTubeChannel: null,
    wolfYouTubeChannelLink: null,
    discontinuedSparePart: 'Данная запасная часть не поставляется',
    sparePartUnavailable: 'Запасные части недоступны',
    stats:
      'Каталог запасных частей c {{sparePartCount}} запасные части, {{sparePartPhotoCount}} фотографий запчасти, {{folderCount}} файлов и {{explodedViewCount}} покомпонентных видов.',
    yourPhoneNumber: 'Ваш номер телефона',
    yourCustomerIdAtTheVendor: 'Номер клиента у дилера',
    additionalInformation: 'Дополнительная информация',
    customerId: 'Номер клиента',
    phoneNumber: 'Номер телефона',
    notAllMandatoryFieldsFilledOut: 'Не все обязательные поля были заполнены.',
    profiPortal: 'КУПИТЬ ЗАПЧАСТИ',
    profiPortalLink: 'https://wolfrus.ru/service/zapchasti/regionalnyy-sklad-zapchastey/',
    usedIn: 'Используется в',
    numFolders: '{{numFolders}} устройствах',
    numFolders1: '1 устройство',
    orderEmailRecipientButton: 'Сбросить на {{emailRecipient}}',
    definition: 'Definition',
    loading: 'загрузка',
    folders: 'папки',
    errorCodes: 'Коды ошибок',
    backToHome: 'Возврат в главное меню',
    noResults: 'Нет результатов',
    maySendAnalytics: 'Пересылаются данные аналитики, используются файлы cookie',
    assemblyInstructions: 'Документация',
    assemblyInstructionsLink:'https://wolfrus.ru/pomoshch/dokumenty/-/',
    settings: 'Hастройки',
    exclVAT: 'без. НДС',
    searchedRecently: 'Последние запросы',
    edit: 'Редактировать',
    done: 'Выполнено',
    deleteAll: 'Удалить все',
    favorites: 'Избранное',
    addToFavorites: 'Добавить в избранное',
    inFavorites: 'В избранных',
    noFavoritesYet: 'Еще нет избранных',
    favoritesNote:
      'Папки и запасные части можно пометить как избранные с помощью кнопки звездочка.»,.',
    openCart: 'Открыть корзину',
    restoreCartContent: 'Восстановить корзину',
    searchingWithin: 'Искать внутри:',
    back: 'Назад',
    share: 'Поделиться',
    cookieDialog: {
      // ToDo: Translate these texts into Russian
      title: 'We use cookies',
      note: 'To ensure the functioning of the website and to display information about your interests. By clicking the "Accept all cookies" button, you agree to the use of cookies. For further information on the use of cookies or to change and revoke your settings, please click on <a href="#{{privacyPolicyLink}}">privacy policy</a>.',
      onlyNecessaryCookies: 'Only allow necessary cookies',
      acceptAllCookies: 'Accept All Cookies',
    },
  },
  'fr-FR': {
    localeTitle: 'Français - France',
    autoLocale: 'Auto (Français - France)',
    fiveYearWarrantyLink: 'https://france.wolf.eu/service/wolf-5-ans-de-garantie/',
    orderEmailRecipient: 'commande.pieces@wolf-france.fr',
    formatPrice: (price, currency) => formatPrice(price, currency, ','),
    priceNote:
      'Les prix affichés sont exprimés en Euro Hors Taxe. Tous les prix sont sans engagement. Le prix est la responsabilité du concessionnaire respectif. Etat {{date}}.',
  },
  'fr-CH': {
    localeTitle: 'Français - Suisse',
    autoLocale: 'Auto (Français - Suisse)',
    orderEmailRecipient: 'info@wolf-energies.ch',
    formatPrice: (price, currency) => formatPrice(price, currency, ','),
  },
  'fr-BE': {
    localeTitle: 'Français - Belgique',
    autoLocale: 'Auto (Français - Belgique)',
    formatPrice: (price, currency) => formatPrice(price, currency, ','),
  },
  fr: {
    appName: 'Wolf Service App',
    appDescription:
      'Le catalogue des pièces détachées Wolf pour votre téléphone portable, votre tablette ou votre PC',
    compatibilityNote:
      'La version iOS nécessite iPhone 4S, iPad 2 ou supérieur. La version Android nécessite un Android 4.3 ou supérieur. Version Web requise Chrome, Firefox ou Safari',
    tryItOut: 'Essayez-le…',
    spareParts: 'Pièces détachées',
    explodedView: 'Vue Éclatée',
    noImage: "Pas d'image",
    sku: "Numéro d'article",
    ean: 'EAN',
    price: 'Prix',
    searchCatalog: 'Chercher dans le catalogue',
    cancel: 'Abandonner',
    cart: 'Chariot',
    clear: 'Vider',
    cartIsEmpty: 'Votre chariot est vide',
    order: 'Commander',
    orderNote: 'Par courrier électronique à votre revendeur',
    proceedToEmailApp: "Continuer vers l'application de messagerie",
    sparePartsCatalog: 'Catalogue pièces détachées',
    imprintAndPolicies: 'Mentions légales',
    termsOfUse: "Conditions d'utilisation",
    imprint: 'Mentions légales',
    generalTermsAndConditions: 'Conditions générales de vente: CGV',
    needToAcceptTermsOfUse: "Vous devez accepter les conditions d'utilisation.",
    accept: 'Accepter',
    decline: 'Refuser',
    readTermsOfUse: "Lire les termes d'utilisation",
    scanBarcode: 'Lire le code à barres',
    aboutServiceApp: "Quelle est l'application de service?",
    createdWith: "Créé avec l'application Wolf Service.",
    search: 'Recherche',
    pieces: 'Pièce',
    personalInfo: 'Informations personnelles',
    personalInfoNote:
      'Les détails personnels apparaissent dans le message électronique au-dessus de la liste des pièces détachées.',
    recipientEmailAddress: 'Adresse e-mail du destinataire',
    emailSubject: 'Commande des pièces détachées Wolf',
    optional: 'En option',
    loadingCatalog: 'Télécharger le catalogue',
    reportProblem: 'Donner des commentaires',
    priceNote:
      'Tous les prix sont sans engagement. Le prix est la responsabilité du concessionnaire respectif. Etat {{date}}.',
    since: 'depuis',
    until: "jusqu'à",
    january: 'Janvier',
    february: 'Février',
    march: 'Mars',
    april: 'Avril',
    may: 'Mai',
    june: 'Juin',
    july: 'Juillet',
    august: 'Août',
    september: 'Septembre',
    october: 'Octobre',
    november: 'Novembre',
    december: 'Décembre',
    sparePartsCatalog: 'Catalogue pièces détachées',
    languageAndRegion: 'Langue et région',
    reportProblemHeading: "Avez-vous un problème avec l'application?",
    reportProblemNote: 'Veuillez nous envoyer un e-mail. Nous serons heureux de vous aider.',
    reportProblemSideNote:
      'Écrivez votre e-mail en allemand ou en anglais. Pour des questions sur les produits ou les prix, veuillez contacter le Service Wolf (https://www.wolf.eu/service/kundendienstbeauftragung/)',
    sendEmail: 'Envoyer un e-mail',
    developedBy: 'Développé par Josef Brandl Software',
    privacyPolicy: 'Avis de protection des données',
    errorCodeInspector: "Inspecteur de code d'erreur",
    errorCodeInspectorSegmented: ['inspecteur-', "code d'erreur"],
    sparePartsCatalogSegmented: ['catalogue-', 'pièces détachées'],
    slogan: 'REGLE A MON DIAPASON.',
    errorCode: "Code d'erreur",
    product: 'Produit',
    issue: 'Problème',
    cause: 'Cause',
    solution: 'Solution',
    loadingErrorCodeInspector: "Télécharger l'inspecteur des codes d'erreur",
    discontinuedSparePartNotes: {
      unchanged: "Le matériel est resté le même, seul le numéro de l'article a été changé",
      changed:
        "L'article a changé techniquement, veuillez laisser et remplacer par un nouvel article",
      discontinued: 'Matériel supprimé sans remplacement',
      unchangedPlusAccessories:
        "Le matériel est resté le même, seul le numéro de l'article a été changé. Le nouveau numéro d'article contient des accessoires",
    },
    wolfYouTubeChannel: null,
    wolfYouTubeChannelLink: null,
    discontinuedSparePart: "Cette pièce détachée n'est plus disponible",
    sparePartUnavailable: 'Pièce détachées non disponible',
    stats:
      'Catalogue de pièces détachées avec {{sparePartCount}} pièces détachées, {{sparePartPhotoCount}} photos de pièces détachées, {{folderCount}} fichiers et {{explodedViewCount}} vues éclatées',
    yourPhoneNumber: 'Votre numéro de téléphone',
    yourCustomerIdAtTheVendor: 'Votre numéro de client chez le concessionnaire',
    additionalInformation: 'Informations supplémentaires',
    customerId: 'Numéro de client',
    phoneNumber: 'Numéro de téléphone',
    notAllMandatoryFieldsFilledOut: "Tous les champs obligatoires n'ont pas été remplis.",
    profiPortal: 'Portail Professionnels',
    profiPortalLink: 'https://france.wolf.eu/portailprofessionnels/',
    usedIn: 'Utilisé dans',
    numFolders: '{{numFolders}} appareils',
    numFolders1: '1 appareil',
    orderEmailRecipientButton: 'Réinitialiser à {{emailRecipient}}',
    definition: 'Definition',
    definition: 'Definition',
    loading: 'Chargement...',
    folders: 'Dossiers',
    errorCodes: 'Codes erreur',
    backToHome: 'Retour à l’accueil',
    noResults: 'Pas de résultats',
    maySendAnalytics:
      "Les données d'utilisation peuvent être envoyées, des cookies sont utilisés (facultatif)",
    assemblyInstructions: "Instructions d'installation",
    assemblyInstructionsLink:
      'https://www.wolf.eu/fr-fr/professionnel/telechargements/documents-chauffage',
    settings: 'Paramètres',
    exclVAT: 'HT',
    searchedRecently: 'Recherche récente',
    edit: 'Editer',
    done: 'Fait',
    deleteAll: 'Supprimer tout',
    favorites: 'Favoris',
    addToFavorites: 'Ajouter aux favoris',
    inFavorites: 'Mes favoris',
    noFavoritesYet: 'Pas encore de favoris',
    favoritesNote:
      'Les dossiers ou les pièces détachées peuvent être marqués en favori en utilisant le bouton étoile',
    openCart: 'Ouvrir le panier',
    restoreCartContent: 'Restaurer le panier',
    searchingWithin: 'Chercher dans:',
    back: 'Continuer mes achats',
    share: 'Partager',
    orderViaShop: 'Commander sur la boutique en ligne',
    continueToShop: 'Commander en ligne',
    orderViaEmail: 'Commande par email',
    cookieDialog: {
      title: 'Nous utilisons des cookies',
      note: 'pour améliorer votre expérience, personnaliser le contenu et les annonces et analyser le trafic sur notre site internet. Nous partageons également des informations sur votre utilisation de notre site avec des partenaires pour vous proposer des contenus et services adaptés à vos centres d\'intérêts et vous permettre de partager des informations sur les réseaux sociaux. En poursuivant la navigation, vous acceptez l’utilisation des cookies. Vous pourrez toujours les désactiver ultérieurement en consultant la <a href="#{{privacyPolicyLink}}">politique de confidentialité</a>.',
      onlyNecessaryCookies: "N'autoriser que les cookies nécessaires",
      acceptAllCookies: 'Autoriser tous les cookies',
    },
  },
  'it-IT': {
    localeTitle: 'Italiano - Italia',
    autoLocale: 'Auto (Italiano - Italia)',
    fiveYearWarrantyLink: 'https://italia.wolf.eu/5-anni-di-garanzia/',
    orderEmailRecipient: 'ordini@wolf.eu',
    formatPrice: (price, currency) => formatPrice(price, currency, ','),
  },
  'it-CH': {
    localeTitle: 'Italiano - Svizzera',
    autoLocale: 'Auto (Italiano - Svizzera)',
    orderEmailRecipient: 'info@wolf-klimatechnik.ch',
    formatPrice: (price, currency) => formatPrice(price, currency, ','),
  },
  it: {
    appName: 'Wolf Service App',
    appDescription: 'Il catalogo dei ricambi Wolf per il tuo cellulare, tablet o PC',
    compatibilityNote:
      'La versione iOS richiede iPhone 4S, iPad 2 o superiore. La versione Android richiede Android 4.3 o versione successiva. La versione Web richiede Chrome, Firefox o Safari',
    tryItOut: 'Basta provarlo…',
    spareParts: 'I pezzi di ricambio',
    explodedView: 'Vista Esplosa',
    noImage: 'Nessuna foto',
    sku: 'Codice articolo',
    ean: 'EAN',
    price: 'Prezzo',
    searchCatalog: 'Cerca nel catalogo',
    cancel: 'Abortire',
    cart: 'Carrello della spesa',
    clear: 'Vuoto',
    cartIsEmpty: 'Il tuo carrello è vuoto',
    order: 'Ordine',
    orderNote: 'Per e-mail al rivenditore',
    proceedToEmailApp: "Continua all'applicazione di posta elettronica",
    sparePartsCatalog: 'Parts Catalog',
    imprintAndPolicies: 'Impronta e legge',
    termsOfUse: 'Condizioni di utilizzo',
    imprint: 'Impronta',
    generalTermsAndConditions: 'Termini e condizioni',
    needToAcceptTermsOfUse: 'Devi accettare i termini di utilizzo.',
    accept: 'Accettare',
    decline: 'Rifiutare',
    readTermsOfUse: 'Leggi i termini di utilizzo',
    scanBarcode: 'Leggete il codice a barre',
    aboutServiceApp: "Qual è l'applicazione di servizio?",
    createdWith: "Creato con l'applicazione Wolf Service.",
    search: 'Ricerca',
    pieces: 'Pezzo',
    personalInfo: 'Informazioni personali',
    personalInfoNote:
      "I dati personali vengono visualizzati nel testo di posta elettronica sopra l'elenco delle parti di ricambio.",
    recipientEmailAddress: 'Indirizzo e-mail del destinatario',
    emailSubject: 'Ordine dei pezzi di ricambio Wolf',
    optional: 'Opzionale',
    loadingCatalog: 'Scarica il catalogo',
    reportProblem: 'Dare un feedback',
    priceNote:
      "I prezzi di listino indicati non sono vincolanti. Prezzi esposti sono comprensivi dell’aumento dal 1. agosto 2021. Il prezzo può variare da un paese all'altro, per maggiori informazioni scrivere all'indirizzo: service-italia@wolf.eu.",
    since: 'da',
    until: 'fino a',
    january: 'Gennaio',
    february: 'Febbraio',
    march: 'Marzo',
    april: 'Aprile',
    may: 'Maggio',
    june: 'Giugno',
    july: 'Luglio',
    august: 'Agosto',
    september: 'Settembre',
    october: 'Ottobre',
    november: 'Novembre',
    december: 'Dicembre',
    sparePartsCatalog: 'Catalogo ricambi',
    languageAndRegion: 'Lingua e regione',
    reportProblemHeading: "Hai un problema con l'applicazione?",
    reportProblemNote: 'Vi preghiamo di inviarci una e-mail. Saremo lieti di aiutarti.',
    reportProblemSideNote:
      'Scrivi la tua e-mail in tedesco o in inglese. Per domande su prodotti o prezzi, contattateci Servizio Wolf (https://www.wolf.eu/service/kundendienstbeauftragung/)',
    sendEmail: 'Inviare una e-mail',
    developedBy: 'Sviluppato da Josef Brandl Software',
    privacyPolicy: 'Privacy Policy',
    errorCodeInspector: 'Ispettore di codice di errore',
    errorCodeInspectorSegmented: ['Ispettore-', 'di codice di errore'],
    sparePartsCatalogSegmented: ['Catalogo-', 'ricambi'],
    slogan: 'IN PERFETTA SINTONIA.',
    errorCode: 'Codice di errore',
    product: 'Prodotto',
    issue: 'Problema',
    cause: 'Causa',
    solution: 'Soluzione',
    loadingErrorCodeInspector: 'Scaricare la finestra di ispezione del codice di errore',
    discontinuedSparePartNotes: {
      unchanged: "Il materiale rimase lo stesso, solo il numero dell'articolo è stato modificato",
      changed:
        "L'articolo è cambiato tecnicamente, si prega di lasciare e sostituire con un nuovo articolo",
      discontinued: 'Materiale escreto senza sostituzione',
      unchangedPlusAccessories:
        "Il materiale rimase lo stesso, solo il numero dell'articolo è stato modificato. Il nuovo numero di articolo contiene accessori",
    },
    wolfYouTubeChannel: null,
    wolfYouTubeChannelLink: null,
    discontinuedSparePart: 'Questa parte di ricambio non è più disponibile',
    sparePartUnavailable: 'Pezzo di ricambio non disponibile',
    stats:
      'Catalogo delle ricambi con {{sparePartCount}} i pezzi di ricambio, {{sparePartPhotoCount}} pezzi di ricambio foto, {{folderCount}} file e {{explodedViewCount}} disegno di esplosione',
    yourPhoneNumber: 'Il tuo numero di telefono',
    yourCustomerIdAtTheVendor: 'Il tuo numero di cliente presso il rivenditore',
    additionalInformation: 'Ulteriori informazioni',
    customerId: 'Numero di cliente',
    phoneNumber: 'Numero di telefono',
    notAllMandatoryFieldsFilledOut: 'Non tutti i campi obbligatori sono stati compilati.',
    profiPortal: 'Area download',
    profiPortalLink: 'https://italia.wolf.eu/portaleprofessionisti/downloadperprofessionisti/',
    usedIn: 'Usato in',
    numFolders: '{{numFolders}} dispositivi',
    numFolders1: '1 dispositivo',
    orderEmailRecipientButton: 'Ripristinare a {{emailRecipient}}',
    definition: 'Definition',
    definition: 'Definition',
    loading: 'Carico...',
    folders: 'Cartelle',
    errorCodes: 'Codici errore',
    backToHome: 'Tornare indietro',
    noResults: 'Nessun risultato',
    maySendAnalytics:
      'I dati di utilizzo possono essere trasmessi, vengono utilizzati cookies (non obbligatorio)',
    assemblyInstructions: "Istruzioni per l'installazione",
    assemblyInstructionsLink:
      'https://www.wolf.eu/it-it/professionisti/downloads/riscaldamento',
    settings: 'Impostazioni',
    exclVAT: 'escl. I.V.A.',
    searchedRecently: 'Richerche recenti',
    edit: 'Modifica',
    done: 'Fatto',
    deleteAll: 'Elimina tutto',
    favorites: 'Preferiti',
    addToFavorites: 'Aggiungi ai preferiti',
    inFavorites: 'Nei preferiti',
    noFavoritesYet: 'Nessun preferito ancora',
    favoritesNote:
      'Cartelle e pezzi di ricambio possono essere contrassegnati come preferiti usando il pulsante stella.',
    openCart: 'Apri il carrello',
    restoreCartContent: 'Ripristina il carrello',
    searchingWithin: 'Cerca:',
    back: 'Indietro',
    share: 'Condividi',
    cookieDialog: {
      title: 'Utilizziamo i cookie',
      note: "Per personalizzare i contenuti e gli annunci, fornire funzionalità di social media e analizzare il traffico sul nostro sito. Condividiamo anche informazioni sul vostro utilizzo del nostro sito con i nostri partner di social media, pubblicità e analisi. I nostri partner possono combinare queste informazioni con altre informazioni che avete fornito loro o che hanno raccolto nell'ambito dell'utilizzo dei servizi. Potete revocare il vostro consenso all'uso dei cookie in qualsiasi momento nella <a href=\"#{{privacyPolicyLink}}\">politica sulla privacy</a>.",
      onlyNecessaryCookies: 'Solo i cookie necessari',
      acceptAllCookies: 'Accetta tutti i cookie',
    },
  },
  'pl-PL': {
    localeTitle: 'Polska - Polski',
    autoLocale: 'Auto (Polska - Polski)',
    orderEmailRecipient: 'zamowienia@wolf-polska.pl',
    formatPrice: (price, currency) => formatPrice(price, currency, ','),
  },
  pl: {
    appName: 'Wolf Service App',
    appDescription:
      'Katalog części zamiennych marki Wolf - Aplikacja dla Twojego telefonu komórkowego, tabletu lub komputera',
    compatibilityNote:
      "Wersja na iOS dostępna dla iPhone'a 4S, iPada 2 lub nowszego. Wersja Android dostepna Androida 4.3 lub nowszego. Wersja sieci Web dostepna Chrome, Firefoxa lub Safari",
    tryItOut: 'Po prostu spróbuj…',
    spareParts: 'Części Zamienne',
    explodedView: 'Rysunek złożeniowy',
    noImage: 'Brak obrazu',
    sku: 'Numer artykułu',
    ean: 'EAN',
    price: 'Cena',
    searchCatalog: 'Szukaj w katalogu',
    cancel: 'Przerwij',
    cart: 'Koszyk',
    clear: 'Pusty',
    cartIsEmpty: 'Twój koszyk jest pusty',
    order: 'Zamówienie',
    orderNote: 'Wyslij e-mail z zamówieniem',
    proceedToEmailApp: 'Przejdź do aplikacji poczty e-mail',
    sparePartsCatalog: 'Części zamienne',
    imprintAndPolicies: 'Kontakt i Regulamin',
    termsOfUse: 'Warunki korzystania',
    imprint: 'Kontakt',
    generalTermsAndConditions: 'Ogólne warunki',
    needToAcceptTermsOfUse: 'Musisz zaakceptowac warunki użytkowania',
    accept: 'Zgadzam się',
    decline: 'Anuluj',
    readTermsOfUse: 'Przeczytaj warunki użytkowania',
    scanBarcode: 'Skanuj kod kreskowy',
    aboutServiceApp: 'Co to jest aplikacja serwisowa?',
    createdWith: 'Utworzone w Wolf Service App.',
    search: 'Szukaj',
    pieces: 'Sztuk',
    personalInfo: 'Dane osobowe',
    personalInfoNote:
      'Dane osobowe pojawiają się w tekście adresu e-mail nad listą części zamiennych.',
    recipientEmailAddress: 'Adres e-mail odbiorcy',
    emailSubject: 'Zamówienie części zamiennych Wolf',
    optional: 'Opcja',
    loadingCatalog: 'Pobierz katalog',
    reportProblem: 'Prześlij opinię',
    priceNote:
      'Wszystkie ceny są niewiążące. Ceną jest odpowiedzialność danego dealera. Stan na {{date}}.',
    since: 'od',
    until: 'aż do',
    january: 'Styczeń',
    february: 'Luty',
    march: 'Marzec',
    april: 'Kwiecień',
    may: 'Maj',
    june: 'Czerwiec',
    july: 'Lipiec',
    august: 'Sierpień',
    september: 'Wrzesień',
    october: 'Październik',
    november: 'Listopad',
    december: 'Grudzień',
    sparePartsCatalog: 'Część zamienna',
    languageAndRegion: 'Język i Kraj',
    reportProblemHeading: 'Czy masz problem z aplikacją serwisową?',
    reportProblemNote: 'Proszę wyślij nam e-mail. Z przyjemnością Ci pomożemy.',
    reportProblemSideNote:
      'Proszę napisać e-mail na adres serwis@wolf-polska.pl. W przypadku pytań dotyczących produktów lub cen prosimy o kontakt z Działem Sprzedaży Wolf Polska. (https://www.wolf-polska.pl)',
    sendEmail: 'Wyślij e-mail',
    developedBy: 'Opracowany przez Josef Brandl Software',
    privacyPolicy: 'Polityka prywatności',
    errorCodeInspector: 'Kody błędów',
    errorCodeInspectorSegmented: ['Kody błędów-', 'Zestawienie'],
    sparePartsCatalogSegmented: ['Części zamienne-', 'Katalog'],
    slogan: 'PERFEKCYJNIE DOPASOWANY DO CIEBIE.',
    errorCode: 'Kod błędu',
    product: 'Produkt',
    issue: 'Problem',
    cause: 'Przyczyna',
    solution: 'Rozwiązanie',
    loadingErrorCodeInspector: 'Pobierz Kody błedów',
    discontinuedSparePartNotes: {
      unchanged: 'Ta sama część zamienna, zmieniony tylko numer katalogowy artykułu',
      changed:
        'Artykuł zmienił się technicznie, proszę zastąpić go nowym artykułem (nowa część zamienna)',
      discontinued: 'Artykuł wycofany z dystrybucji, brak zamiennika',
      unchangedPlusAccessories:
        'Artykuł pozostał taki sam, tylko numer katalogowy części zamiennej został zmieniony. Nowy numer katalogowy zawiera akcesoria',
    },
    wolfYouTubeChannel: null,
    wolfYouTubeChannelLink: null,
    discontinuedSparePart: 'Ta część zapasowa nie jest już dostępna',
    sparePartUnavailable: 'Część zapasowa jest niedostępna',
    stats:
      'Katalog części zamiennych z {{sparePartCount}} Części zamienne, {{sparePartPhotoCount}} Zdjęcia Części Zamiennych, {{folderCount}} Folder {explodedViewCount}} rysunki złożeniowe',
    yourPhoneNumber: 'Twój numer telefonu',
    yourCustomerIdAtTheVendor: 'Twój numer klienta u sprzedawcy',
    additionalInformation: 'Dalsze informacje',
    customerId: 'Numer klienta',
    phoneNumber: 'Numer telefonu',
    notAllMandatoryFieldsFilledOut: 'Nie wszystkie pola obowiązkowe zostały wypełnione.',
    profiPortal: 'Do pobrania',
    profiPortalLink: 'http://www.wolf-polska.pl/pobierz/do-pobrania/',
    usedIn: 'Używany w',
    numFolders: '{{numFolders}} Urządzenia',
    numFolders1: '1 Urządzenie',
    orderEmailRecipientButton: 'Zresetować do {{emailRecipient}}',
    definition: 'Definition',
    definition: 'Definition',
    loading: 'Ładowanie...',
    folders: 'Folder',
    errorCodes: 'Kody błędów',
    backToHome: 'Powrót do strony głównej',
    noResults: 'Brak wyników',
    maySendAnalytics: 'Dane mogą być przekazane, używane są pliki cookie (opcja)',
    assemblyInstructions: 'Instrukcje instalacji',
    assemblyInstructionsLink:
      'https://www.wolf.eu/pl-pl/dla-profesjonalistow/pobierz/technika-grzewcza',
    settings: 'Ustawienia',
    exclVAT: 'netto',
    searchedRecently: 'Przeszukany ostatnio',
    edit: 'Edycja',
    done: 'Gotowe',
    deleteAll: 'Skasuj wszystko',
    favorites: 'Ulubione',
    addToFavorites: 'Dodaj do ulubionych',
    inFavorites: 'W ulubionych',
    noFavoritesYet: 'Ulubionych jeszcze nie ma',
    favoritesNote:
      'Foldery i części zapasowe można zaznaczyć jako Ulubione za pomocą przycisku z gwiazdką.',
    openCart: 'Otwórz kosz',
    openCart: 'Otwarty koszyk',
    restoreCartContent: 'Przywróć zawartość kosza',
    searchingWithin: 'Wyszukaj w',
    back: 'Wstecz',
    share: 'Udostępnij',
    cookieDialog: {
      title: 'Używamy ciasteczek',
      note: 'Do personalizacji treści i reklam, dostarczania funkcji mediów społecznościowych i analizowania ruchu na naszej stronie. Dzielimy się również informacjami na temat korzystania z naszej strony z naszymi partnerami w zakresie mediów społecznościowych, reklamy i analizy. Nasi partnerzy mogą łączyć te informacje z innymi informacjami, które zostały im przekazane przez użytkownika lub które zebrali w ramach korzystania z usług. Zgodę na korzystanie z plików cookie można w każdej chwili odwołać w <a href="#{{privacyPolicyLink}}">polityce prywatności</a>.',
      onlyNecessaryCookies: 'Odrzucenie wszystkich',
      acceptAllCookies: 'Akceptuj wszystkie pliki cookie',
    },
  },
  'sk-SK': {
    localeTitle: 'Slovensko - Slovensko',
    autoLocale: 'Auto (Slovensko - Slovensko)',
    orderEmailRecipient: 'servis@wolfsr.sk',
    formatPrice: (price, currency) => formatPrice(price, currency, ','),
  },
  sk: {
    feedbackEmail: 'servis@wolfsr.sk',
    appName: 'Wolf Service App',
    appDescription: 'Katalóg náhradných dielov Wolf do vášho mobilného telefónu, tabletu alebo PC.',
    compatibilityNote:
      'Verzia pre iOS vyžaduje iPhone 4S, iPad 2 alebo novší typ. Verzia systému Android vyžaduje systém Android 4.3 alebo novší typ. Webová verzia vyžaduje prehliadač Chrome, Firefox alebo Safari.',
    tryItOut: 'Vyskúšajte si to ...',
    spareParts: 'náhradné diely',
    explodedView: 'celkové zobrazenie',
    noImage: 'Žiadny obrázok',
    sku: 'Obj. č.',
    ean: 'EAN',
    price: 'cena',
    searchCatalog: 'Prezrite si katalóg ...',
    cancel: 'zrušiť',
    cart: 'nákupný košík',
    clear: 'prázdny',
    cartIsEmpty: 'Váš košík je prázdny',
    order: 'objednávka',
    orderNote: 'e-mailom u svojho predajcu',
    proceedToEmailApp: 'Pokračujte do e-mailovej aplikácie',
    sparePartsCatalog: 'Katalóg náhradných dielov',
    imprintAndPolicies: 'Tiráž a právne informácie',
    termsOfUse: 'Podmienky používania',
    imprint: 'Tiráž',
    generalTermsAndConditions: 'Podmienky',
    needToAcceptTermsOfUse:
      'Používaním servisnej aplikácie súhlasíte s nasledujúcimi podmienkami používania.',
    accept: 'súhlasiť',
    decline: 'odmietnuť',
    readTermsOfUse: 'Prečítajte si Podmienky používania',
    scanBarcode: 'Prečítajte čiarový kód',
    aboutServiceApp: 'Čo je to servisná aplikácia?',
    createdWith: 'Vytvorené pomocou aplikácie Wolf Service App.',
    search: 'vyhľadávanie',
    pieces: 'počet kusov',
    personalInfo: 'Osobné údaje',
    personalInfoNote: 'Osobné údaje sú uvedené v e-mailovom texte nad zoznamom náhradných dielov',
    recipientEmailAddress: 'E-mailová adresa príjemcu',
    emailSubject: 'Objednávka náhradných dielov Wolf',
    optional: 'voliteľný',
    loadingCatalog: 'Stiahnite si katalóg',
    reportProblem: 'Nahláste problém',
    priceNote:
      'Všetky ceny uviedol nezáväzne výrobca. Ceny si určuje každý predajca zvlášť. Stav {{date}}.',
    since: 'od',
    until: 'do',
    january: 'január',
    february: 'február',
    march: 'marec',
    april: 'apríl',
    may: 'máj',
    june: 'jún',
    july: 'júl',
    august: 'august',
    september: 'september',
    october: 'október',
    november: 'november',
    december: 'december',
    languageAndRegion: 'Jazyk a región',
    reportProblemHeading: 'Máte problém s aplikáciou?',
    reportProblemNote: 'Pošlite nám e-mail. Radi Vám pomôžeme.',
    reportProblemSideNote:
      'Napíšte nám e-mail. Ak sa Vaše otázky týkajú výrobkov alebo cien, kontaktujte oddelenie služieb popredajnej podpory (https://slovensko.wolf.eu/kontaktny-formular/).',
    sendEmail: 'Pošlite e-mail',
    developedBy: 'Vyvinutý softvérom Josef Brandl',
    privacyPolicy: 'Ochrana osobných údajov',
    errorCodeInspector: 'Inšpektor kódov porúch',
    errorCodeInspectorSegmented: ['Kód poruchy', 'inšpektor'],
    sparePartsCatalogSegmented: ['Katalóg ', 'náhradných dielov'],
    slogan: 'NASTAVENÝ NA MŇA.',
    errorCode: 'kód poruchy',
    product: 'výrobok',
    issue: 'problém',
    cause: 'príčina',
    solution: 'riešenie',
    loadingErrorCodeInspector: 'Stiahnite si inšpektora kódu porúch',
    discontinuedSparePartNotes: {
      unchanged: 'Materiál zostal rovnaký, zmenilo sa iba číslo položky',
      changed: 'Výrobok sa technicky zmenil, vymažte ho a prípadne ho nahraďte novým výrobkom.',
      discontinued: 'Materiál bol bez náhrady stiahnutý z obehu.',
      unchangedPlusAccessories:
        'Materiál zostal rovnaký, zmenilo sa iba číslo položky. Nové  obj. č. bolo priradené príslušenstvu.}',
    },
    wolfYouTubeChannel: 'Wolf YouTube kanál',
    wolfYouTubeChannelLink: 'https://www.youtube.com/user/wolfmainburg',
    discontinuedSparePart: 'Tento náhradný diel už neexistuje',
    sparePartUnavailable: 'Náhradný diel nie je k dispozícii',
    stats:
      'Katalóg náhradných dielov so {{sparePartCount}} súčiastkami a náhradnými dielmi, {{sparePartPhotoCount}} fotografiami náhradných dielov, {{folderCount}} a {{explodedViewCount}} množstvom obrázkov',
    yourPhoneNumber: 'Vaše telefónne číslo',
    yourCustomerIdAtTheVendor: 'Vaše zákaznícke číslo u predajcu',
    additionalInformation: 'Doplňujúce informácie',
    customerId: 'zákaznícke číslo',
    phoneNumber: 'telefónne číslo',
    notAllMandatoryFieldsFilledOut: 'Nie všetky povinné kolónky boli vyplnené.',
    profiPortal: 'WOLF Profi-Portal',
    profiPortalLink: 'https://slovensko.wolf.eu/portalprofi/',
    usedIn: 'Používa sa v',
    numFolders: '{{numFolders}} zariadeniach',
    numFolders1: '1 zariadenie',
    orderEmailRecipientButton: 'Späť na {{emailRecipient}}',
    loading: 'nahrať...',
    definition: 'definíciu',
    folders: 'súbory',
    errorCodes: 'poruchové kódy',
    backToHome: 'Späť na domovskú stránku',
    noResults: 'Nenašlo sa',
    maySendAnalytics: 'Povoliť prenos údajov o používaní, používajú sa cookies (voliteľné)',
    assemblyInstructions: 'Návod na montáž a obsluhu',
    assemblyInstructionsLink:
      'https://www.wolf.eu/sk-sk/profi/na-stiahnutie',
    settings: 'Nastavenie',
    exclVAT: 'bez DPH',
    searchedRecently: 'Nedávno zobrazené',
    edit: 'editovať',
    done: 'hotovo',
    deleteAll: 'všetko vymazať',
    favorites: 'obľúbené',
    addToFavorites: 'pridať k obľúbeným',
    inFavorites: 'V obľúbených',
    noFavoritesYet: 'Zatiaľ sa nenašli žiadne obľúbené položky',
    favoritesNote:
      'Jednotlivé položky a náhradné diely sa dajú označiť ako obľúbené tlačidlom s hviezdičkou',
    openCart: 'Otvorte nákupný košík',
    restoreCartContent: 'Obnovte obsah nákupného košíka',
    searchingWithin: 'Hľadať v:',
    back: 'späť',
    share: 'zdieľať',
    orderViaShop: 'Objednať v obchode',
    continueToShop: 'Pokračujte do obchodu',
    orderViaEmail: 'Objednávka e-mailom',
    cookieDialog: {
      title: 'Používame cookies',
      note: 'Na optimálne prispôsobenie nášho obsahu a reklamy, ponuky funkcií pre sociálne médiá a analýzu prístupov na našu webovú stránku. Informácie o vašom používaní našich webových stránok môžu byť zdieľané aj s našimi partnermi v oblasti sociálnych médií, reklamy a analýz. Naši partneri môžu kombinovať tieto informácie s inými údajmi, ktoré ste im poskytli, alebo ktoré zhromaždili ako súčasť vášho používania iných služieb. V našom vyhlásení o ochrane údajov môžete kedykoľvek odvolať svoj súhlas s používaním súborov cookies <a href="#{{privacyPolicyLink}}">Ochrana údajov</a>.',
      onlyNecessaryCookies: 'Povoliť iba potrebné súbory cookie',
      acceptAllCookies: 'Povoľte všetky súbory cookie',
    },
  },
  'cs-CZ': {
    localeTitle: 'Český - Česká republika',
    autoLocale: 'Auto (Český - Česká republika)',
    orderEmailRecipient: 'servis@wolfcr.cz',
    formatPrice: (price, currency) => formatPrice(price, currency, ','),
  },
  cs: {
    appName: 'Wolf Service App',
    appDescription: 'Katalog náhradních dílů Wolf pro váš mobilní telefon, tablet nebo PC',
    compatibilityNote:
      'Verze iOS vyžaduje iPhone 4S, iPad 2 nebo vyšší. Verze Android vyžaduje Android 4.3 nebo vyšší. Verze pro web vyžaduje Chrome, Firefox nebo Safari.',
    tryItOut: 'zkuste to …',
    spareParts: 'náhradní díly',
    explodedView: 'zobrazení dílů',
    noImage: 'bez zobrazení',
    sku: 'Objednací č.',
    ean: 'EAN',
    price: 'cena',
    searchCatalog: 'procházet katalog...',
    cancel: 'přerušit',
    cart: 'nákupní koš',
    clear: 'vymazat',
    cartIsEmpty: 'Váš nákupní koš je prázdný',
    order: 'objednat',
    orderNote: 'přes email u vašeho obchodníka',
    proceedToEmailApp: 'dále k aplikaci Email ',
    sparePartsCatalog: 'Katalog náhradních dílů',
    imprintAndPolicies: 'tiráž a právní informace',
    termsOfUse: 'podmínky používání',
    imprint: 'tiráž',
    generalTermsAndConditions: 'Všeobecné obchodní podmínky',
    needToAcceptTermsOfUse: 'Musíte podmínky používání odsouhlasit.',
    accept: 'Souhlasím',
    decline: 'Odmítám',
    readTermsOfUse: 'Přečtěte si podmínky používání',
    scanBarcode: 'Naskenujte čárový kód',
    aboutServiceApp: 'Co to je Servisní aplikace?',
    createdWith: 'Vytvořeno pomocí aplikace Wolf Service App.',
    search: 'vyhledávání',
    pieces: 'kusů',
    personalInfo: 'Osobní údaje',
    personalInfoNote: 'Osobní údaje jsou uvedeny v textu e-mailu nad seznamem náhradních dílů',
    recipientEmailAddress: 'e-mailová adresa příjemce',
    emailSubject: 'Objednávka náhradních dílů Wolf',
    optional: 'volitelný',
    loadingCatalog: 'Nahrajte si katalog',
    reportProblem: 'Nahlásit problém',
    priceNote:
      'Všechny ceny jsou nezávazné informace výrobce. Za stanovení ceny odpovídá příslušný prodejce. Stav k {{date}}.',
    since: 'od',
    until: 'do',
    january: 'leden',
    february: 'únor',
    march: 'březen',
    april: 'duben',
    may: 'květen',
    june: 'červen',
    july: 'červenec',
    august: 'srpen',
    september: 'září',
    october: 'říjen',
    november: 'listopad',
    december: 'prosinec',
    sparePartsCatalog: 'Katalog náhradních dílů',
    languageAndRegion: 'Jazyk a region',
    reportProblemHeading: 'Máte nějaký problém s aplikací?',
    reportProblemNote: 'Pošlete nám e-mail. Rádi vám pomůžeme',
    reportProblemSideNote:
      'Napište e-mail nebo se obraťte na [zákaznický servis společnosti Wolf](https://czech.wolf.eu/portalprofi/servisproprofesionaly/kontakthotline/)',

    sendEmail: 'Pošlete e-mail',
    developedBy: 'Vypracoval Josef Brandl Software',
    privacyPolicy: 'Prohlášení o ochraně osobních údajů',
    errorCodeInspector: 'Inspektor chybových kódů',
    errorCodeInspectorSegmented: ['kód chyby', 'inspektor'],
    sparePartsCatalogSegmented: ['náhradní díly', 'katalog'],
    slogan: 'NASTAVENÝ NA MĚ.',
    errorCode: 'kód chyby',
    product: 'výrobek',
    issue: 'problém',
    cause: 'příčina',
    solution: 'řešení',
    loadingErrorCodeInspector: 'Nahrajte inspektora chybových kódů',
    discontinuedSparePartNotes: {
      unchanged: 'Náhradní díl zůstal stejný, změnilo se pouze číslo položky',
      changed: 'Zboží se technicky změnilo, je vyřazeno, případně jej nahraďte novým zbožím',
      discontinued: 'Náhradní díl je vyřazen bez náhrady',
      unchangedPlusAccessories:
        ' Náhradní díl zůstal stejný, změnilo se pouze obj. číslo. Nová položka č. obsahuje příslušenství',
    },
    wolfYouTubeChannel: 'Wolf YouTube kanál',
    wolfYouTubeChannelLink: ' https://www.youtube.com/channel/UCucayglLNDHQBlHNjkOIuuw',
    discontinuedSparePart: 'Tento náhradní díl již neexistuje',
    sparePartUnavailable: 'Náhradní díl není k dispozici',
    stats:
      'Katalog náhradních dílů s {{sparePartCount}} náhradními díly, {{sparePartPhotoCount}} fotografiemi náhradních dílů, {{folderCount}} složka a {{explodedViewCount}} rozkreslení dílů ',
    yourPhoneNumber: 'Vaše telefonní číslo',
    yourCustomerIdAtTheVendor: 'Vaše jméno/název firmy',
    additionalInformation: 'Další údaje (osobní odběr/zaslání, ... , )',
    customerId: 'Jméno/název firmy',
    phoneNumber: 'Telefonní číslo',
    notAllMandatoryFieldsFilledOut: 'Nebyla vyplněna všechna povinná pole.',
    profiPortal: 'Profi portál vytápění',
    profiPortalLink: ' https://czech.wolf.eu/portalprofi/ ',
    usedIn: 'používá se u',
    numFolders: '{{numFolders}} přístroje',
    numFolders1: '1 přístroj',
    orderEmailRecipientButton: 'resetujte {{emailRecipient}} ',
    loading: 'načítá se...',
    definition: 'definice',
    folders: 'složka',
    errorCodes: 'kód chyby',
    backToHome: 'Zpět na úvodní stránku',
    noResults: 'bez výsledku',
    maySendAnalytics: 'Data o použití mohou být přenesena, používat soubory cookie (volitelné)',
    assemblyInstructions: 'Návody k montáži',
    assemblyInstructionsLink:
      'https://www.wolf.eu/cs-cz/profi/ke-stazeni',
    settings: 'Nastavení',
    exclVAT: 'bez DPH',
    searchedRecently: 'Hledáno nedávno',
    edit: 'Přepracováno',
    done: 'Hotovo',
    deleteAll: 'Všechno zrušit',
    favorites: 'Oblíbené',
    addToFavorites: 'Vložit do oblíbených',
    inFavorites: 'Uloženo do oblíbených',
    noFavoritesYet: 'Zatím žádné oblíbené',
    favoritesNote: 'Složky a náhradní díly lze označit jako oblíbené pomocí tlačítka s hvězdičkou',
    openCart: 'Otevřete nákupní košík',
    restoreCartContent: 'Obnovte obsah nákupního košíku',
    searchingWithin: 'Hledejte uvnitř:',
    back: 'Zpět',
    share: 'Odeslat',
    orderViaShop: 'Objednávejte přes obchod',
    continueToShop: 'Pokračujte do obchodu',
    orderViaEmail: 'Objednat přes e-mail',
    cookieDialog: {
      title: 'Používáme cookies',
      note: 'abychom přizpůsobili obsah našich stránek, personalizovali reklamní sdělení, zpřístupnili funkce sociálních médií a lépe mohli analyzovat jejich provoz. Informace o používání našich webových stránek také sdílíme s našimi partnery v oblasti sociálních médií, reklamy a analytiky. Naši partneři mohou tyto informace kombinovat s dalšími informacemi, které jste jim poskytli nebo které nashromáždili od vás v rámci využívání jejich služeb. Souhlas s používáním cookies můžete kdykoli odvolat v prohlášení o <a href="#{{privacyPolicyLink}}">ochraně údajů</a>.',
      onlyNecessaryCookies: 'Povolit pouze nezbytné soubory cookie',
      acceptAllCookies: 'Přijmout všechny soubory cookie',
    },
  },
  'nl-NL': {
    localeTitle: 'Nederlands - Nederland',
    autoLocale: 'Auto (Nederlands - Nederland)',
    formatPrice: (price, currency) => formatPrice(price, currency, ','),
  },
  'nl-BE': {
    localeTitle: 'Nederlands - Belgie',
    autoLocale: 'Auto (Nederlands - Belgie)',
    formatPrice: (price, currency) => formatPrice(price, currency, ','),
  },
  nl: {
    appName: 'WOLF Service App',
    appDescription: 'De WOLF onderdelen catalogus voor uw Smartphone, tablet of PC.',
    compatibilityNote:
      'iOS-versie vereist iPhone 4S, iPad 2 of hoger. Android-versie vereist Android 4.3 of hoger. Voor webversie is Chrome, Firefox of Safari vereist.',
    tryItOut: 'Probeer maar…',
    spareParts: 'Onderdelen',
    explodedView: 'Exploded view',
    noImage: 'geen afbeelding',
    sku: 'Art.-Nr.',
    ean: 'EAN',
    price: 'Prijs',
    searchCatalog: 'Catalogus doorzoeken...',
    cancel: 'Afbreken',
    cart: 'Winkelwagen',
    clear: 'Leeg maken',
    cartIsEmpty: 'Uw winkelwagen is leeg',
    order: 'Bestellen',
    orderNote: 'Per E-Mail naar uw groothandel',
    proceedToEmailApp: 'Verder naar Email-App',
    sparePartsCatalog: 'Onderdelen catalogus',
    imprintAndPolicies: 'Imprint en juridische informatie',
    termsOfUse: 'Gebruiksvoorwaarden',
    imprint: 'Imprint',
    generalTermsAndConditions: 'Leveringsvoorwaarden',
    needToAcceptTermsOfUse: 'U moet akkoord gaan met de gebruiksvoorwaarden.',
    accept: 'Accepteren',
    decline: 'Weigeren',
    readTermsOfUse: 'Gebruiksvoorwaarden lezen',
    scanBarcode: 'Streepjescode lezen',
    aboutServiceApp: 'Wat is de Service App?',
    createdWith: 'Gemaakt met de WOLF Service App.',
    search: 'Zoeken',
    pieces: 'Stuk',
    personalInfo: 'Persoonlijke gegevens',
    personalInfoNote:
      'De persoonlijke gegevens worden weergegeven in de E-Mail tekst van de onderdelen lijst',
    recipientEmailAddress: 'E-Mail adres van de ontvanger',
    emailSubject: 'WOLF onderdeken bestelling',
    optional: 'optioneel',
    loadingCatalog: 'Laden van de catalogus',
    reportProblem: 'Feedback geven',
    priceNote:
      'Alle prijzen zijn vrijblijvende informatie van de fabrikant. De prijsstelling is de verantwoordelijkheid van de geselecteerde groothandel. Stand {{date}}.',
    since: 'vanaf',
    until: 'tot',
    january: 'Januari',
    february: 'Februari',
    march: 'Maart',
    april: 'April',
    may: 'Mei',
    june: 'Juni',
    july: 'Juli',
    august: 'August',
    september: 'September',
    october: 'Oktober',
    november: 'November',
    december: 'December',
    sparePartsCatalog: 'Onderdelen catalogus',
    languageAndRegion: 'Taal en regio',
    reportProblemHeading: 'Heeft u een probleem met de App?',
    reportProblemNote: 'Stuur ons een E-Mail. Wij helpen u graag verder.',
    reportProblemSideNote:
      'Stuur uw mail bij voorkeur in het Duits of Engels. Bij vragen over producten of prijzen, neem contact op met de [WOLF service afdeling](https://nl.wolf.eu/verwarming/service-voor-professionals/).',
    sendEmail: 'E-Mail sturen',
    developedBy: 'Ontwikkeld met Josef Brandl Software',
    privacyPolicy: 'Gegevensbescherming',
    errorCodeInspector: 'Foutcode inspecteur',
    errorCodeInspectorSegmented: ['Foutcode-', 'inspecteur'],
    sparePartsCatalogSegmented: ['Onderdeel-', 'Catalogus'],
    slogan: 'VOLLEDIG OP MIJ AFGESTEMD.',
    errorCode: 'Foutcode',
    product: 'Product',
    issue: 'Probleem',
    cause: 'Oorzaak',
    solution: 'Oplossing',
    loadingErrorCodeInspector: 'Laden van de foutcode inspecteur',
    discontinuedSparePartNotes: {
      unchanged: 'Materiaal is gelijk gebleven, alleen het artikelnummer is aangepast',
      changed: 'Artikel is technisch aangepast, graag door het nieuwe artikel vervangen',
      discontinued: 'Materiaal zonder opvolger uit het assortiment',
      unchangedPlusAccessories:
        'Materiaal is gelijk gebleven, alleen het artikelnummer is aangepast. Nieuw Artnr. bevat accessoires',
    },
    wolfYouTubeChannel: 'WOLF YouTube Kanal',
    wolfYouTubeChannelLink: 'https://www.youtube.com/channel/UCSiCSkUgFNJCiTDyJFD9bVQ',
    discontinuedSparePart: 'Dit onderdeel is niet meer leverbaar',
    sparePartUnavailable: 'Onderdeel niet beschikbaar',
    stats:
      'Onderdelen catalogus met {{sparePartCount}} Onderdelen, {{sparePartPhotoCount}} Onderdeel afbeeldingen, {{folderCount}} Mappen en {{explodedViewCount}} Exploded views',
    yourPhoneNumber: 'Uw telefoonnummer',
    yourCustomerIdAtTheVendor: 'Uw klantnummer bij de groothandel',
    additionalInformation: 'Meer informatie',
    customerId: 'Klantnummer',
    phoneNumber: 'Telefoonnummer',
    notAllMandatoryFieldsFilledOut: 'Niet alle verplichte velden zijn ingevuld.',
    profiPortal: 'Installateurs portaal',
    profiPortalLink: 'https://nl.wolf.eu/verwarming/',
    usedIn: 'Gebruikt in',
    numFolders: '{{numFolders}} Producten',
    numFolders1: '1 product',
    orderEmailRecipientButton: 'Op {{emailRecipient}} terugzetten',
    loading: 'Laden...',
    definition: 'Definitie',
    folders: 'Map',
    errorCodes: 'Foutcodes',
    backToHome: 'Terug naar de startpagina',
    noResults: 'Geen overeenkomsten',
    maySendAnalytics: 'Gebruiksgegevens kunnen worden overgedragen (optioneel)',
    assemblyInstructions: 'Montage handleidingen',
    assemblyInstructionsLink:
      'https://www.wolf.eu/nl-nl/professional/downloads/verwarming',
    settings: 'Instellingen',
    exclVAT: 'excl. BTW',
    searchedRecently: 'Recent gezocht',
    edit: 'Bewerken',
    done: 'Gereed',
    deleteAll: 'Alle verwijderen',
    favorites: 'Favorieten',
    addToFavorites: 'Toevoegen aan favorieten',
    inFavorites: 'In favorieten',
    noFavoritesYet: 'Nog geen favorieten',
    favoritesNote:
      'Mappen en onderdelen kunnen via de knop met de ster als favoriet worden geselecteerd',
    openCart: 'Winkelwagen openen',
    restoreCartContent: 'Winkelwagen inhoud herstellen',
    searchingWithin: 'Zoeken binnen:',
    back: 'Terug',
    share: 'Delen',
    orderViaShop: 'Via de shop bestellen',
    continueToShop: 'Naar de shop',
    shopButtonTitle: 'Online-Shop',
    orderViaEmail: 'Per Email bestellen',
    cookieDialog: {
      title: 'We gebruiken cookies',
      note: 'om inhoud en advertenties te personaliseren, social media functies te bieden en verkeer naar onze site te analyseren. We delen ook informatie over uw gebruik van onze site met onze social media, reclame- en analysepartners. Onze partners kunnen deze informatie combineren met andere informatie die u aan hen hebt verstrekt of die zij hebben verzameld in het kader van uw gebruik van de diensten. U kunt uw toestemming voor het gebruik van cookies te allen tijde intrekken in het <a href="#{{privacyPolicyLink}}">privacybeleid</a>.',
      onlyNecessaryCookies: 'Alleen noodzakelijke cookies',
      acceptAllCookies: 'Alle cookies accepteren',
    },
  },
  'zh-CN': {
    localeTitle: '英语-英国',
    autoLocale: '自动（英语-英国）',
    formatPrice: (price, currency) => formatPrice(price, currency, ','),
  },
  zh: {
    appName: '沃乐夫服务应用程序',
    appDescription: '手机、平板电脑或台式机使用的沃乐夫备件目录。',
    compatibilityNote:
      'iOS 版本需要 iPhone 4S、iPad 2 或更好配置。Android 版本需要 Android 4.3 或更高系统。网页版本需要 Chrome、Firefox、Edge 或 Safari 浏览器。',
    tryItOut: '试一试…',
    spareParts: '备件',
    explodedView: '分解视图',
    noImage: '没有图像',
    sku: '物料编',
    ean: 'EAN码',
    price: '价格',
    searchCatalog: '检索目录...',
    cancel: '取消',
    cart: '购物车',
    clear: '清除',
    cartIsEmpty: '您的购物车是空的',
    order: '订货’',
    orderNote: '给您的供应商发送电子邮件',
    proceedToEmailApp: '利用电子邮件应用继续',
    sparePartsCatalog: '备件目录',
    imprintAndPolicies: '版权说明和政策',
    termsOfUse: '使用条款',
    imprint: '版权说明',
    generalTermsAndConditions: '一般条款和条件',
    needToAcceptTermsOfUse: '您需要阅读并接受使用条款。',
    accept: '接受',
    decline: '拒绝',
    readTermsOfUse: '阅读使用条款',
    scanBarcode: '扫描条形码',
    aboutServiceApp: '什么是服务应用程序?',
    createdWith: '利用沃乐夫服务应用程序去创建',
    search: '搜索',
    pieces: '标题',
    personalInfo: '个人信息',
    personalInfoNote: '您的个人信息将添加到备件清单上方的电子邮件中。',
    recipientEmailAddress: '收件人的电子邮件地址',
    emailSubject: '沃乐夫备件订单',
    optional: '可选项',
    loadingCatalog: '加载目录',
    reportProblem: '发送反馈',
    priceNote: '价格无约束力。定价由各个经销商负责。定价截止期 {{日期}}.',
    since: '起始时间',
    until: '截止时间',
    january: '一月',
    february: '二月',
    march: '三月',
    april: '四月',
    may: '五月',
    june: '六月',
    july: '七月',
    august: '八月',
    september: '九月',
    october: '十月',
    november: '十一月',
    december: '十二月',
    sparePartsCatalog: '备件目录',
    languageAndRegion: '语言和地区',
    reportProblemHeading: '如对应用程序有疑问？',
    reportProblemNote: '发送电子邮件来告知我们。我们将很高兴为您提供帮助。',
    reportProblemSideNote:
      '请使用英语或德语编写您的电子邮件。有关产品或价格的问题，请联系我们的[客服] (https://www.wolf.eu/service/kundendienstbeauftragung/).',
    sendEmail: '发送电子邮件',
    developedBy: '由 Josef Brandl 软件公司开发',
    privacyPolicy: '隐私条款',
    errorCodeInspector: '错误代码检查器',
    errorCodeInspectorSegmented: ['错误代码', '检查器'],
    sparePartsCatalogSegmented: ['备件-', '目录'],
    slogan: '致力于完美满足您的需求',
    errorCode: '错误代码',
    product: '产品',
    issue: '疑问',
    cause: '原因',
    solution: '解决方案',
    loadingErrorCodeInspector: '下载错误代码检查器',
    discontinuedSparePartNotes: {
      unchanged: '材料相同，只是SKU有变化',
      changed: '产品进行了技术改进。请使用文本的修订版。',
      discontinued: '产品已停产并且没有替代品',
      unchangedPlusAccessories: '材料相同，只是SKU有变化。新 SKU 中包含配件。',
    },
    wolfYouTubeChannel: '沃乐夫优兔视频（YouTube Kanal）',
    wolfYouTubeChannelLink: 'https://www.youtube.com/user/wolfmainburg',
    discontinuedSparePart: '该零备件已停产',
    sparePartUnavailable: '备件已不再销售',
    stats:
      '目录包含 {{sparePartCount}} 个备件，{{sparePartPhotoCount}}个备件照片，{{folderCount}} 个文件夹以及 {{explodedViewCount}} 个分解视图',
    yourPhoneNumber: '您的电话号码',
    yourCustomerIdAtTheVendor: '您在供应商处的客户 ID',
    additionalInformation: '其他信息',
    customerId: '客户 ID',
    phoneNumber: '电话号码',
    notAllMandatoryFieldsFilledOut: '有必填处没有填写。',
    profiPortal: 'SHK-Profi',
    profiPortalLink: 'https//www.wolf.eu/shk-profi/',
    usedIn: '适用于',
    numFolders: '{{numFolders}} 台设备',
    numFolders1: '1 台设备',
    orderEmailRecipientButton: '重置为 {{emailRecipient}}',
    loading: '正在加载...',
    definition: '定义',
    folders: '文件夹',
    errorCodes: '错误代码',
    backToHome: '返回主页',
    noResults: '无结果',
    maySendAnalytics: '允许通过 cookies 传送使用情况的数据 (可选)',
    assemblyInstructions: '手册',
    assemblyInstructionsLink:
      'https://www.wolf.eu/shk-profi/downloads-fuer-profis/montage-und-bedienungsanleitungen/',
    settings: '设置',
    exclVAT: '不包括增值税',
    searchedRecently: '最近搜索过',
    edit: '编辑',
    done: '已完成',
    deleteAll: '删除所有内容',
    favorites: '收藏夹',
    addToFavorites: '添加到收藏夹',
    inFavorites: '已添加到收藏夹',
    noFavoritesYet: '收藏夹内暂无内容',
    favoritesNote: '点击星号键将文件夹和备件标记为需收藏内容。',
    openCart: '打开购物车',
    restoreCartContent: '还原购物车',
    searchingWithin: '在以下位置搜索：',
    back: '返回',
    share: '分享',
    orderViaShop: '通过商店订购',
    continueToShop: '继续购物',
    orderViaEmail: '通过邮件订购',
    proTip: '专家提示',
    cookieDialog: {
      title: '我们使用 cookies（储存在用户本地终端上的数据）',
      note: '以提升网站功能，并为你显示感兴趣的内容。点击“接受所有 cookies”，即表示您同意使用 cookies。若您想获取更多关于 cookies 使用的信息或更改/撤消您的设置，请单击<a href=”#{{privacyPolicyLink}}”>privacy policy</a>。',
      onlyNecessaryCookies: '仅允许必要的 cookies',
      acceptAllCookies: '接受所有  cookies',
    },
  },
}

function formatPrice(price, currency, decimalSeparator) {
  if (price) {
    let currencySymbol = currency.toUpperCase()
    if (currency === 'EUR') {
      currencySymbol = '€'
    }

    return ('' + price.toFixed(2)).replace('.', decimalSeparator) + ' ' + currencySymbol
  }
}

const months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
]

function formatDate(dateString) {
  const parts = dateString.split('-')
  const year = parseInt(parts[0], 10)
  const month = parseInt(parts[1], 10)
  const day = parseInt(parts[2], 10)
  let result = ''
  if (day) {
    result += day + '. '
  }
  if (month) {
    result += this[months[parseInt(month - 1, 10)]] + ' '
  }
  if (year) {
    result += year
  }
  return result
}
