import { PolymerElement, html } from '@polymer/polymer/polymer-element.js'
import '@polymer/paper-progress/paper-progress.js'

class WolfProgress extends PolymerElement {
  static get template() {
    return html([require('./wolf-progress.html')])
  }

  static get is() {
    return 'wolf-progress'
  }

  static get properties() {
    return {
      label: { type: String, value: '' },
      progress: { type: Number, value: 0 },
      active: { type: Boolean },
    }
  }
}
customElements.define(WolfProgress.is, WolfProgress)
