import { Capacitor } from '@capacitor/core'
import '@polymer/app-layout/app-toolbar/app-toolbar.js'
import '@polymer/marked-element/marked-element.js'
import '@polymer/paper-icon-button/paper-icon-button.js'
import { html, PolymerElement } from '@polymer/polymer/polymer-element.js'
import translated from '../../mixins/translated.js'
import '../../utils/wolf-app-toolbar'
import '../../utils/wolf-colors.js'
import '../../utils/wolf-icons.js'

class AboutDocumentSection extends translated(PolymerElement) {
  static get template() {
    return html([require('./about-document-section.html')])
  }

  static get is() {
    return 'about-document-section'
  }

  static get properties() {
    return {
      document: { type: String },
      _text: { type: String },
      acceptedCookies: { type: Boolean },
      _isIOS: {
        type: Boolean,
        value() {
          return !!(Capacitor.getPlatform() === 'ios')
        },
      },
    }
  }

  static get observers() {
    return ['_onDocumentChange(document)']
  }

  _showIndex(event) {
    this.dispatchEvent(
      new CustomEvent('wolf-show-about-index', {
        bubbles: true,
        composed: true,
      })
    )
  }

  _onDocumentChange(document) {
    var keys = {
      'terms-of-use': 'termsOfUse',
      'privacy-policy': 'privacyPolicy',
      'general-terms-and-conditions': 'generalTermsAndConditions',
      imprint: 'imprint',
    }
    this.title = this.t[keys[document]]

    import(`../../translations/${this.language}/${document}.md`).then((text) => {
      this._text = text.default
    })
  }

  _toggleCookieConsent() {
    const eventOptions = { bubbles: true, composed: true }
    if (this.acceptedCookies) {
      this.dispatchEvent(new CustomEvent('wolf-deny-cookies', eventOptions))
    } else {
      this.dispatchEvent(new CustomEvent('wolf-accept-cookies', eventOptions))
    }
  }

  _equals(a, b) {
    return a === b
  }
}
customElements.define(AboutDocumentSection.is, AboutDocumentSection)
