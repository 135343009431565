import { PolymerElement } from '@polymer/polymer/polymer-element.js'

class WolfText extends PolymerElement {
  static get is() {
    return 'wolf-text'
  }

  static get properties() {
    return {
      text: { type: String, observer: '_onTextChange' },
      noEscape: { type: Boolean, value: false },
    }
  }

  _onTextChange(text) {
    text = text || ''
    if (!this.noEscape) {
      text = escapeHTML(text)
    }
    this.innerHTML = text.replace(/\n/g, '<br>')
  }
}
customElements.define(WolfText.is, WolfText)

function escapeHTML(text) {
  return text.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')
}
