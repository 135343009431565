import '@polymer/app-layout/app-toolbar/app-toolbar.js';
import '@polymer/app-storage/app-localstorage/app-localstorage-document.js';
import '@polymer/iron-collapse/iron-collapse.js';
import '@polymer/iron-icon/iron-icon.js';
import '@polymer/paper-icon-button/paper-icon-button.js';
import '@polymer/paper-input/paper-input.js';
import '@polymer/paper-input/paper-textarea.js';
import { html, PolymerElement } from '@polymer/polymer/polymer-element.js';
import navigate from '../../../mixins/navigate.js';
import translated from '../../../mixins/translated.js';
import openExternalLink from '../../../utils/open-external-link.js';
import '../../../utils/wolf-app-toolbar';
import '../../../utils/wolf-colors.js';
import '../../../utils/wolf-icons.js';

class CatalogOrderPage extends navigate(translated(PolymerElement)) {
  static get template() {
    return html([require('./catalog-order-page.html')]);
  }

  static get is() {
    return 'catalog-order-page';
  }

  static get properties() {
    return {
      store: Object,
      _isNotFilledOutWarningShown: { type: Boolean, value: false },
      _maySync: { type: Boolean, value: false },
      _recipient: { type: String, value: '' },
      _customerIdAtVendor: { type: String, value: '' },
      _phoneNumber: { type: String, value: '' },
      _note: { type: String, value: '' },
      _loading: {
        type: Boolean,
        reflectToAttribute: true,
        value: true,
      },
      _areMultipleOrderMethodsAvailable: {
        type: Boolean,
        reflectToAttribute: true,
        computed: '_and(store.shop, store.isEmailOrderMethodAvailable)',
      },
    };
  }

  ready() {
    super.ready();
    this.store.load(['folders', 'spareParts']).then(() => (this._loading = false));
    this.addEventListener('wolf-back', this._onBack);
  }

  _onSend() {
    const addSpaces = (id) => {
      const totLength = 15;
      let spaces = '';
      if (id.length < totLength) {
        for (let i = id.length; i < totLength; i++) {
          spaces += ' ';
        }
      }
      return spaces;
    };

    this.$.phoneNumberInput.validate();
    if (this.$.phoneNumberInput.invalid) {
      this._isNotFilledOutWarningShown = true;
      return;
    } else {
      this._isNotFilledOutWarningShown = false;
    }

    var subject = this.t.emailSubject;

    var body = '';
    if (this._phoneNumber || this._customerIdAtVendor || this._note) {
      if (this._phoneNumber) {
        body += `${this.t.phoneNumber}: ${this._phoneNumber}\n`;
      }
      if (this._customerIdAtVendor) {
        body += `${this.t.customerId}: ${this._customerIdAtVendor}\n`;
      }
      if (this._note) {
        body += this._note + '\n';
      }
      body += '\n\n\n';
    }

    var cartSpareParts = this.store.cartSpareParts;
    for (var i = 0; i < cartSpareParts.length; ++i) {
      var sparePart = cartSpareParts[i];
      body += `${sparePart.quantityInCart}x ${sparePart.id}, ${sparePart.name.replace(
        '\n',
        ' '
      )}\n\n`;
    }

    body += '\n';

    for (var i = 0; i < cartSpareParts.length; ++i) {
      var sparePart = cartSpareParts[i];
      body += `${sparePart.id}\n`;
    }

    body += '\n';

    for (var i = 0; i < cartSpareParts.length; ++i) {
      var sparePart = cartSpareParts[i];
      body += `${sparePart.quantityInCart}\n`;
    }

    body += '\n\n\n' + this.t.createdWith;

    var recipient = this._recipient || this.t.orderEmailRecipient || '';
    var link =
      'mailto:' +
      encodeURIComponent(recipient) +
      '?subject=' +
      encodeURIComponent(subject) +
      '&body=' +
      encodeURIComponent(body);

    openExternalLink(link);

    this._afterOrder();
  }

  _sendToShop() {
    const cartContent = {};

    for (let sparePart of this.store.cartSpareParts) {
      cartContent[sparePart.id] = sparePart.quantityInCart;
    }

    const encodedCartContent = encodeURIComponent(JSON.stringify(cartContent));
    const url = this.store.shop.makeURL(encodedCartContent);

    openExternalLink(url, { openInSameTab: true });

    this._afterOrder();
  }

  _afterOrder() {
    this.store.clearCart();
    this.navigateTo('/catalog/cart');
  }

  _computePriceNote(catalog, t) {
    if (!catalog || !t) {
      return '';
    }
    return t.priceNote.replace('{{date}}', catalog.pricesAsOfDateString);
  }

  _computeEmailRecipientButtonText(t) {
    return t.orderEmailRecipientButton.replace('{{emailRecipient}}', t.orderEmailRecipient);
  }

  _computeIsEmailRecipientButtonShown(t, recipient) {
    if (!t) {
      return false;
    }
    if (t.orderEmailRecipient) {
      return recipient !== t.orderEmailRecipient && recipient !== '';
    } else {
      return false;
    }
  }

  _onBack() {
    this.navigateTo('/catalog/cart');
  }

  _clearEmailRecipient() {
    this._recipient = '';
  }

  _equals(a, b) {
    return a === b;
  }

  _and(a, b) {
    return a && b;
  }

  _equalsIfElse(a, b, valueA, valueB) {
    return a === b ? valueA : valueB;
  }

  _chooseShopOrderMethod() {
    this.store.orderMethod = 'shop';
  }

  _chooseEmailOrderMethod() {
    this.store.orderMethod = 'email';
  }
}
customElements.define(CatalogOrderPage.is, CatalogOrderPage);
