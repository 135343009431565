import { PolymerElement } from '@polymer/polymer/polymer-element.js'

class WolfWatchObject extends PolymerElement {
  static get is() {
    return 'wolf-watch-object'
  }

  static get properties() {
    return {
      object: { type: Object, observer: '_onChange', notify: true },
    }
  }

  constructor() {
    super()
    this._callback = (changedPaths) => {
      for (let path of changedPaths) {
        const combinedPath = 'object.' + path
        this.notifyPath(combinedPath, this.get(combinedPath))
      }
    }
  }

  connectedCallback() {
    super.connectedCallback()
    if (this.object && this.object.watch) {
      this.object.watch(this._callback)
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    if (this.object && this.object.unwatch) {
      this.object.unwatch(this._callback)
    }
  }

  _onChange(newObject, oldObject) {
    if (newObject && newObject.watch) {
      newObject.watch(this._callback)
    }
    if (oldObject && oldObject.unwatch) {
      oldObject.unwatch(this._callback)
    }
  }
}
customElements.define(WolfWatchObject.is, WolfWatchObject)
