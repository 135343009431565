import { PolymerElement } from '@polymer/polymer/polymer-element.js'
import { RadiantRoute } from 'radiant-router/radiant-router.js'

export default function createRoutes() {
  return new RadiantRoute('')
    .stringQueryParam({ variableName: 'fromShop', queryParamName: 'from-shop' })
    .stringQueryParam({ variableName: 'fromShopURL', queryParamName: 'from-shop-url' })
    .add(
      new RadiantRoute('catalog')
        .add(
          new RadiantRoute('folders')
            .booleanQueryParam({
              variableName: 'isExplodedViewShown',
              queryParamName: 'exploded-view',
            })
            .stringQueryParam({ variableName: 'sparePartId', queryParamName: 'spare-part' })
            .capturePath()
        )
        .add(
          new RadiantRoute('spare-parts')
            .stringQueryParam({ variableName: 'from', queryParamName: 'from' })
            .capturePath()
        )
        .add(
          new RadiantRoute('search')
            .stringQueryParam({ variableName: 'searchTerm', queryParamName: 'q' })
            .stringQueryParam({ variableName: 'folder', queryParamName: 'folder' })
        )
        .add(new RadiantRoute('cart'))
        .add(new RadiantRoute('order'))
        .add(new RadiantRoute('favorites'))
    )

    .add(new RadiantRoute('error-code-inspector'))

    .add(new RadiantRoute('report-problem'))

    .add(
      new RadiantRoute('about')
        .add(new RadiantRoute('terms-of-use'))
        .add(new RadiantRoute('privacy-policy'))
        .add(new RadiantRoute('general-terms-and-conditions'))
        .add(new RadiantRoute('imprint'))
    )
    .add(new RadiantRoute('catalog-search'))
}
