import alphanumSort from '../../utils/alphanum-sort.js'
import watchable from '../../mixins/watchable.js'

export default class Folder extends watchable(class {}) {
  constructor(store, data, parent, parentId, basePath) {
    super()

    this.store = store
    this.parent = parent

    this.childId = data.childId
    this.id = parentId ? parentId + '/' + data.childId : data.childId

    this.title = data.title || ''
    this._subtitle = data.subtitle || ''
    this.infoSubtitle = data.infoSubtitle || ''
    this.warning = data.warning || ''
    this.since = data.since || null
    this.until = data.until || null
    this.style = {
      depth: (data.style && data.style.depth) || 1,
    }
    this.type = data.type || 'default'
    this.barcodes = data.barcodes

    this.explodedViewMapping = data.explodedViewMapping

    if (data.explodedView) {
      data.explodedView.image.path = basePath + '/' + data.explodedView.image.path
    }
    this.explodedView = data.explodedView || (parent ? parent.explodedView : null)

    if (this.explodedViewMapping && this.explodedView) {
      store.explodedViewCount += 1
    }

    store._folders.push(this)

    this.children = data.children.map(
      (childData) => new Folder(store, childData, this, this.id, basePath)
    )

    const sparePartMap = store._sparePartMap
    this._spareParts = (data.spareParts || [])
      .map((sparePartId) => sparePartMap.get(sparePartId))
      .filter((x) => x) // Remove undefined spare parts

    // usedIn
    for (let sparePart of this._spareParts) {
      sparePart.usedIn.push(this)
    }

    store._folderMap.set(this.id, this)
  }

  get path() {
    return this.id.split('/').slice(1).join('/')
  }

  hasAnchestor(anchestor) {
    let current = this
    while (current) {
      current = current.parent
      if (current === anchestor) {
        return true
      }
    }
    return false
  }

  get(path) {
    const segments = path ? path.split('/') : []
    const numSegments = segments.length
    let current = this

    for (let i = 0; i < numSegments; ++i) {
      const segment = segments[i]
      const children = current.children
      const numChildren = children.length
      let foundChild = null
      for (let c = 0; c < numChildren; ++c) {
        const child = children[c]
        if (child.childId === segment) {
          foundChild = child
          break
        }
      }
      if (!foundChild) {
        return
      }
      current = foundChild
    }
    return current
  }

  pathRelativeTo(anchestor) {
    if (this === anchestor) {
      return ''
    }
    let path = ''
    let current = this
    while (current) {
      path = path ? current.childId + '/' + path : current.childId
      current = current.parent
      if (current === anchestor) {
        return path
      }
    }
    return
  }

  get subtitle() {
    const parts = []
    const t = this.store.translations
    if (this._subtitle) {
      parts.push(this._subtitle)
    }
    if (this.since) {
      parts.push(t['since'] + ' ' + t.formatDate(this.since))
    }
    if (this.until) {
      parts.push(t['until'] + ' ' + t.formatDate(this.until))
    }
    return parts.join(', ')
  }

  get spareParts() {
    if (!this._hasSortedSpareParts) {
      this._spareParts.sort(function (a, b) {
        return alphanumSort(a.name, b.name)
      })
      this._hasSortedSpareParts = true
    }
    return this._spareParts
  }

  get parentFoldersUntilProduct() {
    let ret = []
    let parentFolder = this.parent
    let productFound = false
    while (parentFolder) {
      ret.unshift(parentFolder)
      if (parentFolder.type === 'product') {
        productFound = true
        break
      }
      parentFolder = parentFolder.parent
    }
    if (!productFound) {
      ret = []
    }
    return ret
  }

  get parentFoldersAboveProduct() {
    const ret = []
    let parentFolder = this.parent
    let productFound = false
    while (parentFolder) {
      if (productFound) {
        ret.unshift(parentFolder)
      }
      if (parentFolder.type === 'product') {
        productFound = true
      }
      parentFolder = parentFolder.parent
    }

    if (!productFound) {
      parentFolder = this.parent
      while (parentFolder) {
        ret.unshift(parentFolder)
        parentFolder = parentFolder.parent
      }
    }

    return ret
  }

  get parentFoldersAboveProductWithoutRoot() {
    return this.parentFoldersAboveProduct.slice(1)
  }

  get isFavorite() {
    return this.store._getIsFavorite('folder', this.id)
  }

  addToFavorites() {
    this.store._addToFavorites('folder', this.id)
    this.notifyChange('isFavorite')
  }

  deleteFromFavorites() {
    this.store._deleteFromFavorites('folder', this.id)
    this.notifyChange('isFavorite')
  }

  *iter() {
    yield this
    if (this.children) {
      for (let child of this.children) {
        yield* child.iter()
      }
    }
  }
}
