//import downloadExpansionFiles from '../../utils/download-expansion-files.js'
import { Capacitor } from '@capacitor/core'
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem'
import xhrGet from "../../utils/xhr-get"
export default async function loadDefinition(store, progressCallback) {
  let dataPath = 'data'
  let json
  if (Capacitor.getPlatform() === 'android') {
    //await downloadExpansionFiles()
    dataPath = await Filesystem.getUri({ 
      path: 'data', 
      directory: Directory.Data 
    })
    
    dataPath = dataPath.uri
    json = JSON.parse((await Filesystem.readFile({
      path: 'data/data.json',
      directory: Directory.Data,
      encoding: Encoding.UTF8
      })).data)
  } else {
    json = JSON.parse(await xhrGet(dataPath + '/data.json', progressCallback))
  }

  store._files = {
    spareParts: Capacitor.convertFileSrc(dataPath + '/' + json.spareParts[store.locale]),
    folders: Capacitor.convertFileSrc(dataPath + '/' + json.folders[store.locale]),
    errorCodes: Capacitor.convertFileSrc(dataPath + '/' + json.errorCodes[store.locale]),
  }
  store.createdOn = json.createdOn
  store.notifyChange('createOn')
}
