import { Capacitor } from '@capacitor/core'
import '@polymer/iron-icon/iron-icon.js'
import '@polymer/iron-image/iron-image.js'
import '@polymer/paper-button/paper-button.js'
import '@polymer/paper-icon-button/paper-icon-button.js'
import '@polymer/paper-menu-button/paper-menu-button.js'
import '@polymer/paper-spinner/paper-spinner-lite.js'
import { html, PolymerElement } from '@polymer/polymer/polymer-element.js'
import 'animated-pages/lib/animated-pages.js'
import { PopSegue, PushSegue } from 'animated-pages/lib/segues/stack-segues.js'
import '../../../data/elements/get-spare-part.js'
import '../../../elements/rows/folder-row.js'
import '../../../elements/rows/spare-part-row.js'
import '../../../elements/zoom-view/zoom-view.js'
import navigate from '../../../mixins/navigate.js'
import translated from '../../../mixins/translated.js'
import { logEvent } from '../../../utils/facebook-tracking/facebook-tracking.js'
import '../../../utils/wolf-app-toolbar'
import '../../../utils/wolf-colors.js'
import '../../../utils/wolf-heading-bar.js'
import '../../../utils/wolf-icons.js'
import '../../../utils/wolf-selectable-text.js'
import '../../../utils/wolf-text.js'
import '../../../utils/wolf-watch-object.js'

class CatalogSparePartPage extends navigate(translated(PolymerElement)) {
  static get template() {
    return html([require('./catalog-spare-part-page.html')])
  }

  static get is() {
    return 'catalog-spare-part-page'
  }

  static get properties() {
    return {
      from: String,
      sparePart: { type: Object },
      sparePartId: { type: String },
      _sparePartExists: {
        type: Boolean,
        reflectToAttribute: true,
        value: false,
        computed: '_truthy(sparePart)',
      },
      _discontinuedClass: {
        type: String,
        value: false,
        computed: '_computedDiscontinuedClass(sparePart)',
      },
      _isPriceNoteShown: {
        value: false,
      },
      _loading: {
        type: Boolean,
        reflectToAttribute: true,
        value: true,
      },
      _actionBarPage: {
        type: String,
        value: 'action-buttons',
      },
      _isShareButtonShown: {
        type: Boolean,
        value: (Capacitor.getPlatform() !== 'web'), 
      },
    }
  }

  ready() {
    super.ready()
    this.store.load(['spareParts', 'folders']).then(() => (this._loading = false))
    this.addEventListener('wolf-back', this._onBack)
    this.addEventListener('animated-pages-page-did-enter', this._didEnter)
    this.addEventListener('animated-pages-page-did-leave', this._didLeave)
  }

  _didEnter() {
    logEvent('visit_spare_part')
  }

  _didLeave() {
    this._actionBarPage = 'action-buttons'
  }

  _incrementQuantityInCart() {
    this.sparePart.quantityInCart += 1
    // this._actionBarPage = 'open-cart-button'
  }

  _decrementQuantityInCart() {
    this.sparePart.quantityInCart -= 1

    // if (this.sparePart.quantityInCart > 0) {
    //   this._actionBarPage = 'open-cart-button'
    // }

    if (this.sparePart.quantityInCart === 0 && this._actionBarPage === 'open-cart-button') {
      this._actionBarPage = 'action-buttons'
    }
  }

  _onZoomViewAppear() {
    var zoomView = this.$.zoomView
    zoomView.contentScale = zoomView.fitScaleFactor
    zoomView.centerContent()
  }

  _onZoomViewResize(vw, vh, cw, ch) {
    var zoomView = this.$.zoomView
    var scaleFactors = [1, zoomView.fitScaleFactor].sort()
    zoomView.minContentScale = scaleFactors[0]
    zoomView.maxContentScale = scaleFactors[1]
  }

  _computePriceNote(store, t) {
    return t.priceNote.replace('{{date}}', store.pricesAsOfDateString)
  }

  _computeDisconituedNote(noteKey, t) {
    return t.discontinuedSparePartNotes[noteKey]
  }

  _computedDiscontinuedClass(sparePart) {
    return sparePart && sparePart.discontinued ? 'discontinued' : ''
  }

  _togglePriceNoteVisibility() {
    this._isPriceNoteShown = !this._isPriceNoteShown
  }

  _computedUsedInText(count, t) {
    if (count === 1) {
      return t.numFolders1
    } else {
      return t.numFolders.replace('{{numFolders}}', count)
    }
  }

  _onWolfShowFolder() {
    this.$['used-in-button'].close()
  }

  _truthy(x) {
    return !!x
  }

  _onBack() {
    if (this.from === 'search') {
      this.navigateTo('/catalog/search', true)
    } else if (this.from === 'favorites') {
      this.navigateTo('/catalog/favorites', true)
    } else if (this.from === 'cart') {
      this.navigateTo('/catalog/cart')
    } else if (this.from === 'error-code-inspector') {
      this.navigateTo('/error-code-inspector')
    } else {
      this.navigateTo('/catalog')
    }
  }

  _onTappedSparePart(event) {
    const sparePart = event.currentTarget.sparePart
    this.navigateToSparePart(sparePart)
  }

  _onTappedFolder(event) {
    const folder = event.currentTarget.folder
    this.navigateToFolder(folder, this.sparePart)
  }

  _addToFavorites() {
    if (!this.sparePart) {
      return
    }
    this.sparePart.addToFavorites()
  }

  _deleteFromFavorites() {
    if (!this.sparePart) {
      return
    }
    this.sparePart.deleteFromFavorites()
  }

  _createSegue({ templateNameB, initialRender }) {
    if (initialRender || !templateNameB) {
      return
    }
     else if (templateNameB === 'open-cart-button') {
      return new PushSegue()
    }
    return new PopSegue()
  }

  _openCart() {
    this.navigateTo('/catalog/cart')
  }

  _showActionButtons() {
    this._actionBarPage = 'action-buttons'
  }

  _share() {
    const baseUrl = 'https://www.wolfserviceapp.com/#'
    const link = baseUrl + this.linkToSparePart(this.sparePart)
    window.plugins.socialsharing.share(null, null, null, link)
  }

  replaceTextPlaceholders(text) {
    if (typeof text !== 'string') {
      return
    }

    return text.replace(/{{sku ([^\s]*)}}/g, (_match, sku) => {
      return `<a href="#${this.linkToSparePart(sku)}">${sku}</a>`
    })
  }
}
customElements.define(CatalogSparePartPage.is, CatalogSparePartPage)
