import { PolymerElement, html } from '@polymer/polymer/polymer-element.js'
import { mixinBehaviors } from '@polymer/polymer/lib/legacy/class'
import '../../../utils/wolf-circle.js'
import './wolf-home-menu-button-shared-styles.js'
import './wolf-home-menu-button-behavior.js'
import HomeMenuButtonBehavior from './wolf-home-menu-button-behavior'
import translated from '../../../mixins/translated.js'

const SuperClass = mixinBehaviors([HomeMenuButtonBehavior], PolymerElement)

class WolfHomeErrorCodeInspectorMenuButton extends translated(SuperClass) {
  static get template() {
    return html([require('./wolf-home-error-code-inspector-menu-button.html')])
  }

  static get is() {
    return 'wolf-home-error-code-inspector-menu-button'
  }
}
customElements.define(WolfHomeErrorCodeInspectorMenuButton.is, WolfHomeErrorCodeInspectorMenuButton)
