import { PolymerElement, html } from '@polymer/polymer/polymer-element.js'
import '@polymer/paper-icon-button/paper-icon-button.js'
import '@polymer/iron-dropdown/iron-dropdown.js'
import '@polymer/paper-dropdown-menu/paper-dropdown-menu.js'
import '@polymer/paper-listbox/paper-listbox.js'
import '@polymer/paper-item/paper-item.js'
import '../../utils/wolf-icons.js'
import translated from '../../mixins/translated.js'
import navigate from '../../mixins/navigate.js'
import at from './images/at.svg'
import be from './images/be.svg'
import ch from './images/ch.svg'
import cz from './images/cz.svg'
import de from './images/de.svg'
import enDE from './images/en-DE.svg'
import es from './images/es.svg'
import fr from './images/fr.svg'
import it from './images/it.svg'
import nl from './images/nl.svg'
import pl from './images/pl.svg'
import ru from './images/ru.svg'
import sk from './images/sk.svg'
import uk from './images/uk.svg'
import cn from './images/cn.svg'

const localeToFlagImageMapping = {
  'cs-CZ': cz,
  'de-AT': at,
  'de-BE': be,
  'de-CH': ch,
  'de-DE': de,
  'en-DE': enDE,
  'es-ES': es,
  'en-UK': uk,
  'fr-BE': be,
  'fr-CH': ch,
  'fr-FR': fr,
  'it-CH': ch,
  'it-IT': it,
  'nl-BE': be,
  'nl-NL': nl,
  'pl-PL': pl,
  'ru-RU': ru,
  'sk-SK': sk,
  'zh-CN': cn,
}

class WolfHomeOverflowMenu extends navigate(translated(PolymerElement)) {
  static get template() {
    return html([require('./wolf-home-overflow-menu.html')])
  }

  static get is() {
    return 'wolf-home-overflow-menu'
  }

  static get properties() {
    return {
      appInfo: {
        type: String,
        value: window.WolfServiceAppInfo,
      },
      opened: {
        type: Boolean,
        value: false,
        notify: true,
        reflectToAttribute: true,
      },
    }
  }

  open() {
    this.opened = true
  }

  _openAboutPage() {
    this.$.overflowMenuButton.close()
    this.navigateTo('/about')
  }

  _openReportProblemPage() {
    this.$.overflowMenuButton.close()
    this.navigateTo('/report-problem')
  }

  _onLocaleSelect() {
    var selectedLocale = this.$.localeListbox.selected
    if (selectedLocale !== this.chosenLocale) {
      this.chooseLocaleAndReload(selectedLocale)
    }
  }

  _getFlagImageForLocale(locale) {
    return localeToFlagImageMapping[locale]
  }
}
customElements.define(WolfHomeOverflowMenu.is, WolfHomeOverflowMenu)
