import SparePart from '../models/spare-part.js'
import xhrGet from '../../utils/xhr-get.js'
import dirname from '../../utils/dirname.js'

export default async function loadSpareParts(store, progressCallback) {
  await store.load('definition')

  const file = store._files.spareParts
  const json = JSON.parse(await xhrGet(file, progressCallback))
  const basePath = dirname(file)

  for (let data of json.spareParts) {
    new SparePart(store, data, basePath, json.meta.currency, json.meta.pricesIncludeVAT)
  }

  // Discontinued property of spare parts
  store._sparePartMap.forEach((sparePart) => {
    const replacedWith = sparePart.discontinued && sparePart.discontinued.replacedWith
    if (replacedWith) {
      sparePart.discontinued.replacedWith = replacedWith.map((sparePartId) =>
        store._sparePartMap.get(sparePartId)
      )
    }
  })

  if (json.meta.pricesAsOf) {
    store.pricesAsOfDateString = store.t.formatDate(json.meta.pricesAsOf)
  }

  store.notifyChange('pricesAsOfDateString')
  store.notifyChange('sparePartCount')
  store.notifyChange('cartSpareParts')
  store.notifyChange('favorites')
}
