import { PolymerElement, html } from '@polymer/polymer/polymer-element.js'
import '@polymer/app-layout/app-toolbar/app-toolbar.js'
import '@polymer/paper-button/paper-button.js'
import translated from '../../../mixins/translated.js'
import '../../../elements/rows/recent-search-term-row.js'
import '../../../utils/wolf-app-toolbar'

class CatalogSearchRecentSearchTerms extends translated(PolymerElement) {
  static get template() {
    return html([require('./catalog-search-recent-search-terms.html')])
  }

  static get is() {
    return 'catalog-search-recent-search-terms'
  }

  static get properties() {
    return {
      store: { type: Object },
      searchTerm: { type: String, notify: true },
      _editMode: { value: false },
    }
  }

  _onTappedRecentSearchTerm(event) {
    if (this._editMode) {
      return
    }
    this.searchTerm = event.currentTarget.searchTerm
  }

  _deleteSearchTerm(event) {
    this.store.deleteRecentSearchTerm(event.currentTarget.searchTerm)
  }

  _onEditButtonTapped() {
    this._editMode = true
  }

  _onDoneButtonTapped() {
    this._editMode = false
  }

  _onDeleteAllButtonTapped() {
    this.store.deleteAllRecentSearchTerms()
  }
}
customElements.define(CatalogSearchRecentSearchTerms.is, CatalogSearchRecentSearchTerms)
