import { BarcodeScanner, SupportedFormat } from '@capacitor-community/barcode-scanner'
import { Capacitor } from '@capacitor/core'
/**
 * Function to apply style to an element
 *
 * @param {*} element - Document Element
 * @param {*} style - Style to apply
 */
function css(element, style) {
  for (const property in style) element.style[property] = style[property]
}
/**
 * Function to stop the Barcode Scanner
 */
export function stopScanner() {
  BarcodeScanner.stopScan()
}
/**
 * Function to create Document Element and apply style
 *
 * @param {string} element - Element to create (string)
 * @param {string} innerHtml - innerHTML of the element
 * @param {object} style  - style to apply to the element
 * @returns the element created
 */
function createDocumentElement(element, innerHtml, style) {
  const htmlElement = document.createElement(element)
  htmlElement.innerHTML = innerHtml
  css(htmlElement, style)
  return htmlElement
}
/**
 * Function to read the Barcode
 *
 * @returns the code read from the barcode or Error if Permission Denied
 */
export async function readBarcode() {
  const permission = await didUserGrantPermission()
  if (permission) {
    // Use the default hide Background function from Barcode Scanner
    BarcodeScanner.hideBackground()
    // Apply class scanner-active to Body to set Opacity to 0
    document.querySelector('body').classList.add('scanner-active')
    // Create a button to close Scanner
    const closeButton = createDocumentElement(
      'button',
      '<iron-icon icon="wolf:back"></iron-icon>',
      {
        color: 'var(--paper-deep-orange-a700)',
        margin: '0 0 0 10px',
        top: '20px',
        left: '5px',
        'z-index': '10',
        position: 'absolute',
      }
    )
    if(Capacitor.getPlatform() === 'ios') {
      css(closeButton, {
        top: '40px',
      })
    }
    // Add stop Scanner function to the element via click event
    // Remove the Elements needed for Scanner view from the DOM
    closeButton.addEventListener('click', () => {
      stopScanner()
      document.querySelector('body').classList.remove('scanner-active')
      closeButton.remove()
      overlayContainer.remove()
    })
    /**
     * Create Box with transparent area to scan the barcode and semi transprarent border
     */
    const scannerOverlay = createDocumentElement('div', '', {
      border: '2000px solid rgba(255, 255, 255, .3)',
      height: '15%',
      "min-height": '150px',
      width: '75%',
      position: 'fixed',
    })
    /**
     * Create a container to hold the scannerOverlay
     */
    const overlayContainer = createDocumentElement('div', '', {
      display: 'flex',
      width: '100vw',
      height: '100vh',
      'align-items': 'center',
      'justify-content': 'center',
    })

    // Append the close button to HTMl
    document.querySelector('html').appendChild(closeButton)
    // Append the scannerOverlay to the overlayContainer
    overlayContainer.appendChild(scannerOverlay)
    // Append the overlayContainer to the HTML
    document.querySelector('html').appendChild(overlayContainer)
    // Start the Scanner
    const result = await BarcodeScanner.startScan({ targetedFormats: [SupportedFormat.CODE_128] })
    // Remove class scanner-active
    document.querySelector('body').classList.remove('scanner-active')
    // Remove the close button
    document.querySelector('html').removeChild(closeButton)
    // Remove the overlayContainer
    document.querySelector('html').removeChild(overlayContainer)
    let code = result && result.content
    if (!code) {
      return new Error('Invalid Barcode')
    }
    if (result.length !== 13) {
      code = code.match(/^[^123456789]*(.{0,7})/)[1]
    }
    return code
  }
  return new Error('Pls grant permission to access camera')
}
/**
 * Function to check if user granted permission to use the camera
 *
 * @returns true if user granted permission, false if user denied permission
 */
const didUserGrantPermission = async () => {
  // check if user already granted permission
  const status = await BarcodeScanner.checkPermission({ force: false })

  if (status.granted) {
    // user granted permission
    return true
  }

  if (status.denied) {
    // user denied permission
    return false
  }

  // user has not been requested this permission before
  if (status.neverAsked) {
    const confirmation = confirm(
      'We need your permission to use your camera to be able to scan barcodes'
    )
    if (confirmation) {
      await BarcodeScanner.openAppSettings()
    }
  }
  if (status.restricted || status.unknown) {
    // ios only
    // probably means the permission has been denied
    return false
  }

  // user has not denied permission
  // but the user also has not yet granted the permission -> request permission
  const statusRequest = await BarcodeScanner.checkPermission({ force: true })

  if (statusRequest.granted) {
    // the user did grant the permission
    return true
  }

  // user did not grant the permission
  return false
}
/**
 *
 * @returns true if App is used on Mobile and the BarcodeScanner plugin is available
 */
export function canReadBarcode() {
  return !!(Capacitor.getPlatform() !== 'web' && Capacitor.isPluginAvailable('BarcodeScanner'))
}
