let physics
// Physics powered by the Famous phyiscs engine
!(function t(i, s, e) {
  function n(o, h) {
    if (!s[o]) {
      if (!i[o]) {
        var u = 'function' == typeof require && require
        if (!h && u) return u(o, !0)
        if (r) return r(o, !0)
        var a = new Error("Cannot find module '" + o + "'")
        throw ((a.code = 'MODULE_NOT_FOUND'), a)
      }
      var c = (s[o] = { exports: {} })
      i[o][0].call(
        c.exports,
        function (t) {
          var s = i[o][1][t]
          return n(s ? s : t)
        },
        c,
        c.exports,
        t,
        i,
        s,
        e
      )
    }
    return s[o].exports
  }
  for (var r = 'function' == typeof require && require, o = 0; o < e.length; o++) n(e[o])
  return n
})(
  {
    1: [
      function (t, i, s) {
        'use strict'
        function e(t) {
          this.values = t || [1, 0, 0, 0, 1, 0, 0, 0, 1]
        }
        ;(e.prototype.get = function () {
          return this.values
        }),
          (e.prototype.set = function (t) {
            return (this.values = t), this
          }),
          (e.prototype.copy = function (t) {
            var i = this.values,
              s = t.values
            return (
              (i[0] = s[0]),
              (i[1] = s[1]),
              (i[2] = s[2]),
              (i[3] = s[3]),
              (i[4] = s[4]),
              (i[5] = s[5]),
              (i[6] = s[6]),
              (i[7] = s[7]),
              (i[8] = s[8]),
              this
            )
          }),
          (e.prototype.vectorMultiply = function (t, i) {
            var s = this.values,
              e = t.x,
              n = t.y,
              r = t.z
            return (
              (i.x = s[0] * e + s[1] * n + s[2] * r),
              (i.y = s[3] * e + s[4] * n + s[5] * r),
              (i.z = s[6] * e + s[7] * n + s[8] * r),
              i
            )
          }),
          (e.prototype.multiply = function (t) {
            var i = this.values,
              s = t.values,
              e = i[0],
              n = i[1],
              r = i[2],
              o = i[3],
              h = i[4],
              u = i[5],
              a = i[6],
              c = i[7],
              p = i[8],
              y = s[0],
              l = s[1],
              f = s[2],
              v = s[3],
              m = s[4],
              x = s[5],
              z = s[6],
              g = s[7],
              d = s[8]
            return (
              (i[0] = e * y + n * v + r * z),
              (i[1] = e * l + n * m + r * g),
              (i[2] = e * f + n * x + r * d),
              (i[3] = o * y + h * v + u * z),
              (i[4] = o * l + h * m + u * g),
              (i[5] = o * f + h * x + u * d),
              (i[6] = a * y + c * v + p * z),
              (i[7] = a * l + c * m + p * g),
              (i[8] = a * f + c * x + p * d),
              this
            )
          }),
          (e.prototype.transpose = function () {
            var t = this.values,
              i = t[1],
              s = t[2],
              e = t[3],
              n = t[5],
              r = t[6],
              o = t[7]
            return (t[1] = e), (t[2] = r), (t[3] = i), (t[5] = o), (t[6] = s), (t[7] = n), this
          }),
          (e.prototype.getDeterminant = function () {
            var t = this.values,
              i = t[3],
              s = t[4],
              e = t[5],
              n = t[6],
              r = t[7],
              o = t[8],
              h = t[0] * (s * o - e * r) - t[1] * (i * o - e * n) + t[2] * (i * r - s * n)
            return h
          }),
          (e.prototype.inverse = function () {
            var t = this.values,
              i = t[0],
              s = t[1],
              e = t[2],
              n = t[3],
              r = t[4],
              o = t[5],
              h = t[6],
              u = t[7],
              a = t[8],
              c = i * (r * a - o * u) - s * (n * a - o * h) + e * (n * u - r * h)
            return Math.abs(c) < 1e-40
              ? null
              : ((c = 1 / c),
                (t[0] = (r * a - o * u) * c),
                (t[3] = (-n * a + o * h) * c),
                (t[6] = (n * u - r * h) * c),
                (t[1] = (-s * a + e * u) * c),
                (t[4] = (i * a - e * h) * c),
                (t[7] = (-i * u + s * h) * c),
                (t[2] = (s * o - e * r) * c),
                (t[5] = (-i * o + e * n) * c),
                (t[8] = (i * r - s * n) * c),
                this)
          }),
          (e.clone = function (t) {
            return new e(t.values.slice())
          }),
          (e.inverse = function (t, i) {
            var s = t.values,
              e = i.values,
              n = s[0],
              r = s[1],
              o = s[2],
              h = s[3],
              u = s[4],
              a = s[5],
              c = s[6],
              p = s[7],
              y = s[8],
              l = n * (u * y - a * p) - r * (h * y - a * c) + o * (h * p - u * c)
            return Math.abs(l) < 1e-40
              ? null
              : ((l = 1 / l),
                (e[0] = (u * y - a * p) * l),
                (e[3] = (-h * y + a * c) * l),
                (e[6] = (h * p - u * c) * l),
                (e[1] = (-r * y + o * p) * l),
                (e[4] = (n * y - o * c) * l),
                (e[7] = (-n * p + r * c) * l),
                (e[2] = (r * a - o * u) * l),
                (e[5] = (-n * a + o * h) * l),
                (e[8] = (n * u - r * h) * l),
                i)
          }),
          (e.transpose = function (t, i) {
            var s = t.values,
              e = i.values,
              n = s[0],
              r = s[1],
              o = s[2],
              h = s[3],
              u = s[4],
              a = s[5],
              c = s[6],
              p = s[7],
              y = s[8]
            return (
              (e[0] = n),
              (e[1] = h),
              (e[2] = c),
              (e[3] = r),
              (e[4] = u),
              (e[5] = p),
              (e[6] = o),
              (e[7] = a),
              (e[8] = y),
              i
            )
          }),
          (e.add = function (t, i, s) {
            var e = t.values,
              n = i.values,
              r = s.values,
              o = e[0],
              h = e[1],
              u = e[2],
              a = e[3],
              c = e[4],
              p = e[5],
              y = e[6],
              l = e[7],
              f = e[8],
              v = n[0],
              m = n[1],
              x = n[2],
              z = n[3],
              g = n[4],
              d = n[5],
              w = n[6],
              M = n[7],
              I = n[8]
            return (
              (r[0] = o + v),
              (r[1] = h + m),
              (r[2] = u + x),
              (r[3] = a + z),
              (r[4] = c + g),
              (r[5] = p + d),
              (r[6] = y + w),
              (r[7] = l + M),
              (r[8] = f + I),
              s
            )
          }),
          (e.subtract = function (t, i, s) {
            var e = t.values,
              n = i.values,
              r = s.values,
              o = e[0],
              h = e[1],
              u = e[2],
              a = e[3],
              c = e[4],
              p = e[5],
              y = e[6],
              l = e[7],
              f = e[8],
              v = n[0],
              m = n[1],
              x = n[2],
              z = n[3],
              g = n[4],
              d = n[5],
              w = n[6],
              M = n[7],
              I = n[8]
            return (
              (r[0] = o - v),
              (r[1] = h - m),
              (r[2] = u - x),
              (r[3] = a - z),
              (r[4] = c - g),
              (r[5] = p - d),
              (r[6] = y - w),
              (r[7] = l - M),
              (r[8] = f - I),
              s
            )
          }),
          (e.multiply = function (t, i, s) {
            var e = t.values,
              n = i.values,
              r = s.values,
              o = e[0],
              h = e[1],
              u = e[2],
              a = e[3],
              c = e[4],
              p = e[5],
              y = e[6],
              l = e[7],
              f = e[8],
              v = n[0],
              m = n[1],
              x = n[2],
              z = n[3],
              g = n[4],
              d = n[5],
              w = n[6],
              M = n[7],
              I = n[8]
            return (
              (r[0] = o * v + h * z + u * w),
              (r[1] = o * m + h * g + u * M),
              (r[2] = o * x + h * d + u * I),
              (r[3] = a * v + c * z + p * w),
              (r[4] = a * m + c * g + p * M),
              (r[5] = a * x + c * d + p * I),
              (r[6] = y * v + l * z + f * w),
              (r[7] = y * m + l * g + f * M),
              (r[8] = y * x + l * d + f * I),
              s
            )
          }),
          (i.exports = e)
      },
      {},
    ],
    2: [
      function (t, i, s) {
        'use strict'
        function e(t, i, s, e) {
          ;(this.w = t || 1), (this.x = i || 0), (this.y = s || 0), (this.z = e || 0)
        }
        var n = Math.sin,
          r = Math.cos,
          o = Math.asin,
          h = Math.acos,
          u = Math.atan2,
          a = Math.sqrt
        ;(e.prototype.multiply = function (t) {
          var i = this.x,
            s = this.y,
            e = this.z,
            n = this.w,
            r = t.x,
            o = t.y,
            h = t.z,
            u = t.w || 0
          return (
            (this.w = n * u - i * r - s * o - e * h),
            (this.x = i * u + r * n + o * e - s * h),
            (this.y = s * u + o * n + i * h - r * e),
            (this.z = e * u + h * n + r * s - i * o),
            this
          )
        }),
          (e.prototype.leftMultiply = function (t) {
            var i = t.x,
              s = t.y,
              e = t.z,
              n = t.w || 0,
              r = this.x,
              o = this.y,
              h = this.z,
              u = this.w
            return (
              (this.w = n * u - i * r - s * o - e * h),
              (this.x = i * u + r * n + o * e - s * h),
              (this.y = s * u + o * n + i * h - r * e),
              (this.z = e * u + h * n + r * s - i * o),
              this
            )
          }),
          (e.prototype.rotateVector = function (t, i) {
            var s = this.w,
              e = -this.x,
              n = -this.y,
              r = -this.z,
              o = t.x,
              h = t.y,
              u = t.z,
              a = -e * o - n * h - r * u,
              c = o * s + h * r - n * u,
              p = h * s + e * u - o * r,
              y = u * s + o * n - e * h,
              l = s,
              f = -e,
              v = -n,
              m = -r
            return (
              (i.x = c * l + f * a + v * y - p * m),
              (i.y = p * l + v * a + c * m - f * y),
              (i.z = y * l + m * a + f * p - c * v),
              i
            )
          }),
          (e.prototype.invert = function () {
            return (
              (this.w = -this.w), (this.x = -this.x), (this.y = -this.y), (this.z = -this.z), this
            )
          }),
          (e.prototype.conjugate = function () {
            return (this.x = -this.x), (this.y = -this.y), (this.z = -this.z), this
          }),
          (e.prototype.length = function () {
            var t = this.w,
              i = this.x,
              s = this.y,
              e = this.z
            return a(t * t + i * i + s * s + e * e)
          }),
          (e.prototype.normalize = function () {
            var t = this.w,
              i = this.x,
              s = this.y,
              e = this.z,
              n = a(t * t + i * i + s * s + e * e)
            return 0 === n
              ? this
              : ((n = 1 / n), (this.w *= n), (this.x *= n), (this.y *= n), (this.z *= n), this)
          }),
          (e.prototype.set = function (t, i, s, e) {
            return (
              null != t && (this.w = t),
              null != i && (this.x = i),
              null != s && (this.y = s),
              null != e && (this.z = e),
              this
            )
          }),
          (e.prototype.copy = function (t) {
            return (this.w = t.w), (this.x = t.x), (this.y = t.y), (this.z = t.z), this
          }),
          (e.prototype.clear = function () {
            return (this.w = 1), (this.x = 0), (this.y = 0), (this.z = 0), this
          }),
          (e.prototype.dot = function (t) {
            return this.w * t.w + this.x * t.x + this.y * t.y + this.z * t.z
          }),
          (e.prototype.slerp = function (t, i, s) {
            var e,
              r,
              o,
              u,
              a,
              c = this.w,
              p = this.x,
              y = this.y,
              l = this.z,
              f = t.w,
              v = t.x,
              m = t.y,
              x = t.z
            return (
              (r = c * f + p * v + y * m + l * x),
              1 - r > 1e-5
                ? ((e = h(r)), (o = n(e)), (u = n((1 - i) * e) / o), (a = n(i * e) / o))
                : ((u = 1 - i), (a = i)),
              (s.w = c * u + f * a),
              (s.x = p * u + v * a),
              (s.y = y * u + m * a),
              (s.z = l * u + x * a),
              s
            )
          }),
          (e.prototype.toMatrix = function (t) {
            var i = this.w,
              s = this.x,
              e = this.y,
              n = this.z,
              r = s * s,
              o = e * e,
              h = n * n,
              u = s * e,
              a = s * n,
              c = e * n
            return t.set([
              1 - 2 * (o + h),
              2 * (u - i * n),
              2 * (a + i * e),
              2 * (u + i * n),
              1 - 2 * (r + h),
              2 * (c - i * s),
              2 * (a - i * e),
              2 * (c + i * s),
              1 - 2 * (r + o),
            ])
          }),
          (e.prototype.toEuler = function (t) {
            var i = this.w,
              s = this.x,
              e = this.y,
              n = this.z,
              r = s * s,
              h = e * e,
              a = n * n,
              c = 2 * (s * n + e * i)
            return (
              (c = -1 > c ? -1 : c > 1 ? 1 : c),
              (t.x = u(2 * (s * i - e * n), 1 - 2 * (r + h))),
              (t.y = o(c)),
              (t.z = u(2 * (n * i - s * e), 1 - 2 * (h + a))),
              t
            )
          }),
          (e.prototype.fromEuler = function (t, i, s) {
            var e = 0.5 * t,
              o = 0.5 * i,
              h = 0.5 * s,
              u = n(e),
              a = n(o),
              c = n(h),
              p = r(e),
              y = r(o),
              l = r(h)
            return (
              (this.w = p * y * l - u * a * c),
              (this.x = u * y * l + p * a * c),
              (this.y = p * a * l - u * y * c),
              (this.z = p * y * c + u * a * l),
              this
            )
          }),
          (e.prototype.fromAngleAxis = function (t, i, s, e) {
            var o = a(i * i + s * s + e * e)
            if (0 === o) (this.w = 1), (this.x = this.y = this.z = 0)
            else {
              o = 1 / o
              var h = 0.5 * t,
                u = n(h)
              ;(this.w = r(h)), (this.x = u * i * o), (this.y = u * s * o), (this.z = u * e * o)
            }
            return this
          }),
          (e.multiply = function (t, i, s) {
            var e = t.w || 0,
              n = t.x,
              r = t.y,
              o = t.z,
              h = i.w || 0,
              u = i.x,
              a = i.y,
              c = i.z
            return (
              (s.w = e * h - n * u - r * a - o * c),
              (s.x = n * h + u * e + a * o - r * c),
              (s.y = r * h + a * e + n * c - u * o),
              (s.z = o * h + c * e + u * r - n * a),
              s
            )
          }),
          (e.normalize = function (t, i) {
            var s = t.w,
              e = t.x,
              n = t.y,
              r = t.z,
              o = a(s * s + e * e + n * n + r * r)
            return 0 === o ? this : ((o = 1 / o), (i.w *= o), (i.x *= o), (i.y *= o), (i.z *= o), i)
          }),
          (e.conjugate = function (t, i) {
            return (i.w = t.w), (i.x = -t.x), (i.y = -t.y), (i.z = -t.z), i
          }),
          (e.clone = function (t) {
            return new e(t.w, t.x, t.y, t.z)
          }),
          (e.dot = function (t, i) {
            return t.w * i.w + t.x * i.x + t.y * i.y + t.z * i.z
          }),
          (i.exports = e)
      },
      {},
    ],
    3: [
      function (t, i, s) {
        'use strict'
        var e = function (t, i, s) {
          ;(this.x = t || 0), (this.y = i || 0), (this.z = s || 0)
        }
        ;(e.prototype.set = function (t, i, s) {
          return (
            null != t && (this.x = t), null != i && (this.y = i), null != s && (this.z = s), this
          )
        }),
          (e.prototype.add = function (t) {
            return (this.x += t.x), (this.y += t.y), (this.z += t.z), this
          }),
          (e.prototype.subtract = function (t) {
            return (this.x -= t.x), (this.y -= t.y), (this.z -= t.z), this
          }),
          (e.prototype.rotateX = function (t) {
            var i = this.y,
              s = this.z,
              e = Math.cos(t),
              n = Math.sin(t)
            return (this.y = i * e - s * n), (this.z = i * n + s * e), this
          }),
          (e.prototype.rotateY = function (t) {
            var i = this.x,
              s = this.z,
              e = Math.cos(t),
              n = Math.sin(t)
            return (this.x = s * n + i * e), (this.z = s * e - i * n), this
          }),
          (e.prototype.rotateZ = function (t) {
            var i = this.x,
              s = this.y,
              e = Math.cos(t),
              n = Math.sin(t)
            return (this.x = i * e - s * n), (this.y = i * n + s * e), this
          }),
          (e.prototype.dot = function (t) {
            return this.x * t.x + this.y * t.y + this.z * t.z
          }),
          (e.prototype.cross = function (t) {
            var i = this.x,
              s = this.y,
              e = this.z,
              n = t.x,
              r = t.y,
              o = t.z
            return (
              (this.x = s * o - e * r), (this.y = e * n - i * o), (this.z = i * r - s * n), this
            )
          }),
          (e.prototype.scale = function (t) {
            return (this.x *= t), (this.y *= t), (this.z *= t), this
          }),
          (e.prototype.invert = function () {
            return (this.x = -this.x), (this.y = -this.y), (this.z = -this.z), this
          }),
          (e.prototype.map = function (t) {
            return (this.x = t(this.x)), (this.y = t(this.y)), (this.z = t(this.z)), this
          }),
          (e.prototype.length = function () {
            var t = this.x,
              i = this.y,
              s = this.z
            return Math.sqrt(t * t + i * i + s * s)
          }),
          (e.prototype.lengthSq = function () {
            var t = this.x,
              i = this.y,
              s = this.z
            return t * t + i * i + s * s
          }),
          (e.prototype.copy = function (t) {
            return (this.x = t.x), (this.y = t.y), (this.z = t.z), this
          }),
          (e.prototype.clear = function () {
            return (this.x = 0), (this.y = 0), (this.z = 0), this
          }),
          (e.prototype.isZero = function () {
            return 0 === this.x && 0 === this.y && 0 === this.z
          }),
          (e.prototype.toArray = function () {
            return [this.x, this.y, this.z]
          }),
          (e.prototype.normalize = function () {
            var t = this.x,
              i = this.y,
              s = this.z,
              e = Math.sqrt(t * t + i * i + s * s) || 1
            return (e = 1 / e), (this.x *= e), (this.y *= e), (this.z *= e), this
          }),
          (e.prototype.applyRotation = function (t) {
            var i = t.w,
              s = -t.x,
              e = -t.y,
              n = -t.z,
              r = this.x,
              o = this.y,
              h = this.z,
              u = -s * r - e * o - n * h,
              a = r * i + o * n - e * h,
              c = o * i + s * h - r * n,
              p = h * i + r * e - s * o,
              y = i,
              l = -s,
              f = -e,
              v = -n
            return (
              (this.x = a * y + l * u + f * p - c * v),
              (this.y = c * y + f * u + a * v - l * p),
              (this.z = p * y + v * u + l * c - a * f),
              this
            )
          }),
          (e.prototype.applyMatrix = function (t) {
            var i = t.get(),
              s = this.x,
              e = this.y,
              n = this.z
            return (
              (this.x = i[0] * s + i[1] * e + i[2] * n),
              (this.y = i[3] * s + i[4] * e + i[5] * n),
              (this.z = i[6] * s + i[7] * e + i[8] * n),
              this
            )
          }),
          (e.normalize = function (t, i) {
            var s = t.x,
              e = t.y,
              n = t.z,
              r = Math.sqrt(s * s + e * e + n * n) || 1
            return (r = 1 / r), (i.x = s * r), (i.y = e * r), (i.z = n * r), i
          }),
          (e.applyRotation = function (t, i, s) {
            var e = i.w,
              n = -i.x,
              r = -i.y,
              o = -i.z,
              h = t.x,
              u = t.y,
              a = t.z,
              c = -n * h - r * u - o * a,
              p = h * e + u * o - r * a,
              y = u * e + n * a - h * o,
              l = a * e + h * r - n * u,
              f = e,
              v = -n,
              m = -r,
              x = -o
            return (
              (s.x = p * f + v * c + m * l - y * x),
              (s.y = y * f + m * c + p * x - v * l),
              (s.z = l * f + x * c + v * y - p * m),
              s
            )
          }),
          (e.clone = function (t) {
            return new e(t.x, t.y, t.z)
          }),
          (e.add = function (t, i, s) {
            return (s.x = t.x + i.x), (s.y = t.y + i.y), (s.z = t.z + i.z), s
          }),
          (e.subtract = function (t, i, s) {
            return (s.x = t.x - i.x), (s.y = t.y - i.y), (s.z = t.z - i.z), s
          }),
          (e.scale = function (t, i, s) {
            return (s.x = t.x * i), (s.y = t.y * i), (s.z = t.z * i), s
          }),
          (e.dot = function (t, i) {
            return t.x * i.x + t.y * i.y + t.z * i.z
          }),
          (e.cross = function (t, i, s) {
            var e = t.x,
              n = t.y,
              r = t.z,
              o = i.x,
              h = i.y,
              u = i.z
            return (s.x = n * u - r * h), (s.y = r * o - e * u), (s.z = e * h - n * o), s
          }),
          (e.project = function (t, i, s) {
            var e = t.x,
              n = t.y,
              r = t.z,
              o = i.x,
              h = i.y,
              u = i.z,
              a = e * o + n * h + r * u
            return (a /= o * o + h * h + u * u), (s.x = o * a), (s.y = h * a), (s.z = u * a), s
          }),
          (i.exports = e)
      },
      {},
    ],
    4: [
      function (t, i, s) {
        'use strict'
        function e(t) {
          ;(this.events = new p()),
            (t = t || {}),
            (this.bodies = []),
            (this.forces = []),
            (this.constraints = []),
            (this.step = t.step || 1e3 / 60),
            (this.iterations = t.iterations || 10),
            (this._indexPools = { bodies: [], forces: [], constraints: [] }),
            (this._entityMaps = { bodies: {}, forces: {}, constraints: {} }),
            (this.speed = t.speed || 1),
            (this.time = 0),
            (this.delta = 0),
            (this.origin = t.origin || new y()),
            (this.orientation = t.orientation ? t.orientation.normalize() : new l()),
            (this.frameDependent = t.frameDependent || !1),
            (this.transformBuffers = { position: [0, 0, 0], rotation: [0, 0, 0, 1] })
        }
        function n(t, i, s) {
          var e = t._entityMaps[s]
          if (null == e[i._ID]) {
            var n = t[s],
              r = t._indexPools[s]
            r.length ? (e[i._ID] = r.pop()) : (e[i._ID] = n.length), (n[e[i._ID]] = i)
          }
        }
        function r(t, i, s) {
          var e = t._entityMaps[s],
            n = e[i._ID]
          null != n && (t._indexPools[s].push(n), (t[s][n] = null), (e[i._ID] = null))
        }
        function o(t, i) {
          t.momentum.add(y.scale(t.force, i, m)),
            t.angularMomentum.add(y.scale(t.torque, i, m)),
            y.scale(t.momentum, t.inverseMass, t.velocity),
            t.inverseInertia.vectorMultiply(t.angularMomentum, t.angularVelocity),
            t.force.clear(),
            t.torque.clear()
        }
        function h(t, i) {
          if (0 !== t.restrictions) {
            var s = t.restrictions,
              e = null,
              n = null,
              r = null,
              o = null,
              h = null,
              u = null
            32 & s && (e = 0),
              16 & s && (n = 0),
              8 & s && (r = 0),
              4 & s && (o = 0),
              2 & s && (h = 0),
              1 & s && (u = 0),
              (null === e && null === n && null === r) || t.setVelocity(e, n, r),
              (null === o && null === h && null === u) || t.setAngularVelocity(o, h, u)
          }
          t.position.add(y.scale(t.velocity, i, m))
          var a = t.angularVelocity,
            c = t.orientation,
            p = a.x,
            l = a.y,
            f = a.z,
            v = c.w,
            x = c.x,
            z = c.y,
            g = c.z,
            d = 0.5 * i
          ;(c.w += (-p * x - l * z - f * g) * d),
            (c.x += (p * v + l * g - f * z) * d),
            (c.y += (l * v + f * x - p * g) * d),
            (c.z += (f * v + p * z - l * x) * d),
            c.normalize(),
            t.updateInertia()
        }
        var u = t('./bodies/Particle'),
          a = t('./constraints/Constraint'),
          c = t('./forces/Force'),
          p = t('../utilities/CallbackStore'),
          y = t('../math/Vec3'),
          l = t('../math/Quaternion'),
          f = new y(),
          v = new l(),
          m = new y()
        ;(e.prototype.on = function (t, i) {
          return this.events.on(t, i), this
        }),
          (e.prototype.off = function (t, i) {
            return this.events.off(t, i), this
          }),
          (e.prototype.trigger = function (t, i) {
            return this.events.trigger(t, i), this
          }),
          (e.prototype.setOrigin = function (t, i, s) {
            return this.origin.set(t, i, s), this
          }),
          (e.prototype.setOrientation = function (t, i, s, e) {
            return this.orientation.set(t, i, s, e).normalize(), this
          }),
          (e.prototype.add = function () {
            for (var t = 0, i = arguments.length; i > t; t++) {
              var s = arguments[t]
              if (s instanceof Array)
                for (var e = 0, n = s.length; n > e; e++) {
                  var r = s[e]
                  this.add(r)
                }
              else
                s instanceof u
                  ? this.addBody(s)
                  : s instanceof a
                  ? this.addConstraint(s)
                  : s instanceof c && this.addForce(s)
            }
            return this
          }),
          (e.prototype.remove = function () {
            for (var t = 0, i = arguments.length; i > t; t++) {
              var s = arguments[t]
              if (s instanceof Array)
                for (var e = 0, n = s.length; n > e; e++) {
                  var r = s[e]
                  this.add(r)
                }
              else
                s instanceof u
                  ? this.removeBody(s)
                  : s instanceof a
                  ? this.removeConstraint(s)
                  : s instanceof c && this.removeForce(s)
            }
            return this
          }),
          (e.prototype.addBody = function (t) {
            n(this, t, 'bodies')
          }),
          (e.prototype.addForce = function (t) {
            n(this, t, 'forces')
          }),
          (e.prototype.addConstraint = function (t) {
            n(this, t, 'constraints')
          }),
          (e.prototype.removeBody = function (t) {
            r(this, t, 'bodies')
          }),
          (e.prototype.removeForce = function (t) {
            r(this, t, 'forces')
          }),
          (e.prototype.removeConstraint = function (t) {
            r(this, t, 'constraints')
          }),
          (e.prototype.update = function (t) {
            0 === this.time && (this.time = t)
            var i = this.bodies,
              s = this.forces,
              e = this.constraints,
              n = this.frameDependent,
              r = this.step,
              u = 0.001 * r,
              a = this.speed,
              c = this.delta
            ;(c += (t - this.time) * a), (this.time = t)
            for (var p, y, l, f, v; c > r; ) {
              for (this.events.trigger('prestep', t), p = 0, y = s.length; y > p; p++)
                (l = s[p]), null !== l && l.update(t, u)
              for (p = 0, y = i.length; y > p; p++) (f = i[p]), null !== f && o(f, u)
              for (p = 0, y = e.length; y > p; p++) (v = e[p]), null !== v && v.update(t, u)
              for (var m = 0, x = this.iterations; x > m; m++)
                for (p = 0, y = e.length; y > p; p++) (v = e[p]), null !== v && v.resolve(t, u)
              for (p = 0, y = i.length; y > p; p++) (f = i[p]), null !== f && h(f, u)
              this.events.trigger('poststep', t), n ? (c = 0) : (c -= r)
            }
            this.delta = c
          }),
          (e.prototype.getTransform = function (t) {
            var i = this.origin,
              s = this.orientation,
              e = this.transformBuffers,
              n = t.position,
              r = t.orientation,
              o = r,
              h = n
            return (
              1 !== s.w && ((o = l.multiply(r, s, v)), (h = s.rotateVector(n, f))),
              (e.position[0] = i.x + h.x),
              (e.position[1] = i.y + h.y),
              (e.position[2] = i.z + h.z),
              (e.rotation[0] = o.x),
              (e.rotation[1] = o.y),
              (e.rotation[2] = o.z),
              (e.rotation[3] = o.w),
              e
            )
          }),
          (i.exports = e)
      },
      {
        '../math/Quaternion': 2,
        '../math/Vec3': 3,
        '../utilities/CallbackStore': 10,
        './bodies/Particle': 5,
        './constraints/Constraint': 6,
        './forces/Force': 8,
      },
    ],
    5: [
      function (t, i, s) {
        'use strict'
        function e(t) {
          ;(this.events = new h()),
            (t = t || {}),
            (this.position = t.position || new n()),
            (this.orientation = t.orientation || new r()),
            (this.velocity = new n()),
            (this.momentum = new n()),
            (this.angularVelocity = new n()),
            (this.angularMomentum = new n()),
            (this.mass = t.mass || 1),
            (this.inverseMass = 1 / this.mass),
            (this.force = new n()),
            (this.torque = new n()),
            (this.restitution = null != t.restitution ? t.restitution : 0.4),
            (this.friction = null != t.friction ? t.friction : 0.2),
            (this.inverseInertia = new o([0, 0, 0, 0, 0, 0, 0, 0, 0])),
            (this.localInertia = new o([0, 0, 0, 0, 0, 0, 0, 0, 0])),
            (this.localInverseInertia = new o([0, 0, 0, 0, 0, 0, 0, 0, 0])),
            (this.size = t.size || [0, 0, 0])
          var i = t.velocity
          i && this.setVelocity(i.x, i.y, i.z),
            (this.restrictions = 0),
            this.setRestrictions.apply(this, t.restrictions || []),
            (this.collisionMask = t.collisionMask || 1),
            (this.collisionGroup = t.collisionGroup || 1),
            (this.type = 1),
            (this._ID = c++)
        }
        var n = t('../../math/Vec3'),
          r = t('../../math/Quaternion'),
          o = t('../../math/Mat33'),
          h = t('../../utilities/CallbackStore'),
          u = new n(),
          a = new o(),
          c = 0
        ;(e.prototype.on = function (t, i) {
          return this.events.on(t, i), this
        }),
          (e.prototype.off = function (t, i) {
            return this.events.off(t, i), this
          }),
          (e.prototype.trigger = function (t, i) {
            return this.events.trigger(t, i), this
          }),
          (e.prototype.getRestrictions = function () {
            var t = '',
              i = '',
              s = this.restrictions
            return (
              32 & s && (t += 'x'),
              16 & s && (t += 'y'),
              8 & s && (t += 'z'),
              4 & s && (i += 'x'),
              2 & s && (i += 'y'),
              1 & s && (i += 'z'),
              [t, i]
            )
          }),
          (e.prototype.setRestrictions = function (t, i) {
            return (
              (t = t || ''),
              (i = i || ''),
              (this.restrictions = 0),
              t.indexOf('x') > -1 && (this.restrictions |= 32),
              t.indexOf('y') > -1 && (this.restrictions |= 16),
              t.indexOf('z') > -1 && (this.restrictions |= 8),
              i.indexOf('x') > -1 && (this.restrictions |= 4),
              i.indexOf('y') > -1 && (this.restrictions |= 2),
              i.indexOf('z') > -1 && (this.restrictions |= 1),
              this
            )
          }),
          (e.prototype.getMass = function () {
            return this.mass
          }),
          (e.prototype.setMass = function (t) {
            return (this.mass = t), (this.inverseMass = 1 / t), this
          }),
          (e.prototype.getInverseMass = function () {
            return this.inverseMass
          }),
          (e.prototype.updateLocalInertia = function () {
            return (
              this.localInertia.set([0, 0, 0, 0, 0, 0, 0, 0, 0]),
              this.localInverseInertia.set([0, 0, 0, 0, 0, 0, 0, 0, 0]),
              this
            )
          }),
          (e.prototype.updateInertia = function () {
            var t = this.localInverseInertia,
              i = this.orientation
            if ((t[0] === t[4] && t[4] === t[8]) || 1 === i.w) return this
            var s = i.toMatrix(a)
            return (
              o.multiply(s, this.inverseInertia, this.inverseInertia),
              o.multiply(this.localInverseInertia, s.transpose(), this.inverseInertia),
              this
            )
          }),
          (e.prototype.getPosition = function () {
            return this.position
          }),
          (e.prototype.setPosition = function (t, i, s) {
            return this.position.set(t, i, s), this
          }),
          (e.prototype.getVelocity = function () {
            return this.velocity
          }),
          (e.prototype.setVelocity = function (t, i, s) {
            return (
              this.velocity.set(t, i, s), n.scale(this.velocity, this.mass, this.momentum), this
            )
          }),
          (e.prototype.getMomentum = function () {
            return this.momentum
          }),
          (e.prototype.setMomentum = function (t, i, s) {
            return (
              this.momentum.set(t, i, s),
              n.scale(this.momentum, this.inverseMass, this.velocity),
              this
            )
          }),
          (e.prototype.getOrientation = function () {
            return this.orientation
          }),
          (e.prototype.setOrientation = function (t, i, s, e) {
            return this.orientation.set(t, i, s, e).normalize(), this.updateInertia(), this
          }),
          (e.prototype.getAngularVelocity = function () {
            return this.angularVelocity
          }),
          (e.prototype.setAngularVelocity = function (t, i, s) {
            this.angularVelocity.set(t, i, s)
            var e = o.inverse(this.inverseInertia, a)
            return (
              e
                ? e.vectorMultiply(this.angularVelocity, this.angularMomentum)
                : this.angularMomentum.clear(),
              this
            )
          }),
          (e.prototype.getAngularMomentum = function () {
            return this.angularMomentum
          }),
          (e.prototype.setAngularMomentum = function (t, i, s) {
            return (
              this.angularMomentum.set(t, i, s),
              this.inverseInertia.vectorMultiply(this.angularMomentum, this.angularVelocity),
              this
            )
          }),
          (e.prototype.getForce = function () {
            return this.force
          }),
          (e.prototype.setForce = function (t, i, s) {
            return this.force.set(t, i, s), this
          }),
          (e.prototype.getTorque = function () {
            return this.torque
          }),
          (e.prototype.setTorque = function (t, i, s) {
            return this.torque.set(t, i, s), this
          }),
          (e.prototype.applyForce = function (t) {
            return this.force.add(t), this
          }),
          (e.prototype.applyTorque = function (t) {
            return this.torque.add(t), this
          }),
          (e.prototype.applyImpulse = function (t) {
            return (
              this.momentum.add(t), n.scale(this.momentum, this.inverseMass, this.velocity), this
            )
          }),
          (e.prototype.applyAngularImpulse = function (t) {
            return (
              this.angularMomentum.add(t),
              this.inverseInertia.vectorMultiply(this.angularMomentum, this.angularVelocity),
              this
            )
          }),
          (e.prototype.support = function () {
            return u
          }),
          (e.prototype.updateShape = function () {}),
          (i.exports = e)
      },
      {
        '../../math/Mat33': 1,
        '../../math/Quaternion': 2,
        '../../math/Vec3': 3,
        '../../utilities/CallbackStore': 10,
      },
    ],
    6: [
      function (t, i, s) {
        'use strict'
        function e(t) {
          ;(t = t || {}), this.setOptions(t), (this._ID = n++)
        }
        var n = 0
        ;(e.prototype.setOptions = function (t) {
          for (var i in t) this[i] = t[i]
          this.init(t)
        }),
          (e.prototype.init = function (t) {}),
          (e.prototype.update = function (t, i) {}),
          (e.prototype.resolve = function (t, i) {}),
          (i.exports = e)
      },
      {},
    ],
    7: [
      function (t, i, s) {
        'use strict'
        function e(t, i) {
          n.call(this, t, i)
        }
        var n = t('./Force'),
          r = t('../../math/Vec3'),
          o = new r()
        ;(e.prototype = Object.create(n.prototype)),
          (e.prototype.constructor = e),
          (e.QUADRATIC = function (t) {
            return t * t
          }),
          (e.LINEAR = function (t) {
            return t
          }),
          (e.prototype.init = function () {
            ;(this.max = this.max || 1 / 0),
              (this.strength = this.strength || 1),
              (this.type = this.type || e.LINEAR)
          }),
          (e.prototype.update = function () {
            for (
              var t = this.targets,
                i = this.type,
                s = o,
                e = this.max,
                n = this.strength,
                h = 0,
                u = t.length;
              u > h;
              h++
            ) {
              var a = t[h],
                c = a.velocity,
                p = c.length(),
                y = p ? 1 / p : 0,
                l = -n * i(p)
              r.scale(c, (-e > l ? -e : l) * y, s), a.applyForce(s)
            }
          }),
          (i.exports = e)
      },
      { '../../math/Vec3': 3, './Force': 8 },
    ],
    8: [
      function (t, i, s) {
        'use strict'
        function e(t, i) {
          t
            ? t instanceof Array
              ? (this.targets = t)
              : (this.targets = [t])
            : (this.targets = []),
            (i = i || {}),
            this.setOptions(i),
            (this._ID = n++)
        }
        var n = 0
        ;(e.prototype.setOptions = function (t) {
          for (var i in t) this[i] = t[i]
          this.init(t)
        }),
          (e.prototype.addTarget = function (t) {
            this.targets.push(t)
          }),
          (e.prototype.removeTarget = function (t) {
            var i = this.targets.indexOf(t)
            0 > i || this.targets.splice(i, 1)
          }),
          (e.prototype.init = function (t) {}),
          (e.prototype.update = function (t, i) {}),
          (i.exports = e)
      },
      {},
    ],
    9: [
      function (t, i, s) {
        'use strict'
        function e(t, i, s) {
          ;(this.source = t || null), n.call(this, i, s)
        }
        var n = t('./Force'),
          r = t('../../math/Vec3'),
          o = new r(),
          h = new r()
        ;(e.prototype = Object.create(n.prototype)), (e.prototype.constructor = e)
        var u = Math.PI
        ;(e.FENE = function (t, i) {
          var s = 0.99 * i,
            e = Math.max(Math.min(t, s), -s)
          return e / (1 - (e * e) / (i * i))
        }),
          (e.HOOKE = function (t) {
            return t
          }),
          (e.prototype.init = function (t) {
            ;(this.max = this.max || 1 / 0),
              (this.length = this.length || 0),
              (this.type = this.type || e.HOOKE),
              (this.maxLength = this.maxLength || 1 / 0),
              t.stiffness || t.damping
                ? ((this.stiffness = this.stiffness || 100),
                  (this.damping = this.damping || 0),
                  (this.period = null),
                  (this.dampingRatio = null))
                : (t.period || t.dampingRatio) &&
                  ((this.period = this.period || 1),
                  (this.dampingRatio = this.dampingRatio || 0),
                  (this.stiffness = (2 * u) / this.period),
                  (this.stiffness *= this.stiffness),
                  (this.damping = (4 * u * this.dampingRatio) / this.period))
          }),
          (e.prototype.update = function () {
            for (
              var t = this.source,
                i = this.targets,
                s = o,
                e = h,
                n = this.max,
                u = this.stiffness,
                a = this.damping,
                c = this.length,
                p = this.maxLength,
                y = this.anchor || t.position,
                l = this.anchor ? 0 : t.inverseMass,
                f = this.type,
                v = 0,
                m = i.length;
              m > v;
              v++
            ) {
              var x = i[v]
              r.subtract(y, x.position, s)
              var z = s.length(),
                g = z - c
              if (!(Math.abs(g) < 1e-6)) {
                var d = 1 / (x.inverseMass + l)
                null !== this.period && ((u *= d), (a *= d)),
                  s.scale((u * f(g, p)) / g),
                  0 !== a &&
                    (t
                      ? s.add(r.subtract(x.velocity, t.velocity, e).scale(-a))
                      : s.add(r.scale(x.velocity, -a, e)))
                var w = s.length(),
                  M = w ? 1 / w : 0
                r.scale(s, (w > n ? n : w) * M, s), x.applyForce(s), t && t.applyForce(s.invert())
              }
            }
          }),
          (i.exports = e)
      },
      { '../../math/Vec3': 3, './Force': 8 },
    ],
    10: [
      function (t, i, s) {
        'use strict'
        function e() {
          this._events = {}
        }
        ;(e.prototype.on = function (t, i) {
          this._events[t] || (this._events[t] = [])
          var s = this._events[t]
          return (
            s.push(i),
            function () {
              s.splice(s.indexOf(i), 1)
            }
          )
        }),
          (e.prototype.off = function (t, i) {
            var s = this._events[t]
            return s && s.splice(s.indexOf(i), 1), this
          }),
          (e.prototype.trigger = function (t, i) {
            var s = this._events[t]
            if (s) for (var e = 0, n = s.length; n > e; e++) s[e](i)
            return this
          }),
          (i.exports = e)
      },
      {},
    ],
    11: [
      function (t, i, s) {
        'use strict'
        physics = {
          PhysicsEngine: t('famous/physics/PhysicsEngine'),
          Particle: t('famous/physics/bodies/Particle'),
          Drag: t('famous/physics/forces/Drag'),
          Spring: t('famous/physics/forces/Spring'),
          Vec3: t('famous/math/Vec3'),
        }
      },
      {
        'famous/math/Vec3': 3,
        'famous/physics/PhysicsEngine': 4,
        'famous/physics/bodies/Particle': 5,
        'famous/physics/forces/Drag': 7,
        'famous/physics/forces/Spring': 9,
      },
    ],
  },
  {},
  [11]
)

const { Particle, Vec3, Drag, Spring, PhysicsEngine } = physics

export default class Snappy {
  constructor() {
    this._resetPhysics()
  }

  get position() {
    return this._particle.getPosition().x
  }
  set position(x) {
    this._particle.setPosition(x, 0, 0)
  }

  get velocity() {
    return this._particle.getVelocity().x
  }
  set velocity(v) {
    this._particle.setVelocity(v, 0, 0)
  }

  reset(position, velocity) {
    this._resetPhysics()
    if (position != null) {
      this.position = position
    }
    if (velocity != null) {
      this.velocity = velocity
    }
    return this
  }

  _resetPhysics() {
    this._particle = new Particle({ mass: 1 })
    const drag = new Drag([this._particle], { type: Drag.LINEAR, strength: 10 })
    this._physicsEngine = new PhysicsEngine().add(this._particle, drag)
    this._spring = new Spring(null, [], { dampingRatio: 1, period: 0.2 })
    this._isSnapping = false
  }

  update(viewportSize, contentSize) {
    this._physicsEngine.update(performance.now())
    return this
  }

  get snapPosition() {
    return this._isSnapping ? this._spring.anchor.x : null
  }

  set snapPosition(position) {
    if (position == null) {
      if (this._isSnapping) {
        this._spring.removeTarget(this._particle)
        this._physicsEngine.remove(this._spring)
        this._isSnapping = false
      }
    } else {
      if (!this._isSnapping) {
        this._physicsEngine.add(this._spring)
        this._spring.addTarget(this._particle)
        this._isSnapping = true
      }
      this._spring.anchor = new Vec3(position, 0, 0)
    }
  }
}
