import { PolymerElement } from '@polymer/polymer/polymer-element.js'

class GetSparePart extends PolymerElement {
  static get is() {
    return 'get-spare-part'
  }

  static get properties() {
    return {
      store: { type: Object },
      sparePartId: { type: String, notify: true },
      sparePart: { type: Object, notify: true },
    }
  }

  static get observers() {
    return [
      '_onSparePartIdChange(sparePartId, store.status.spareParts.loaded, store.status.folders.loaded)',
      '_onSparePartChange(sparePart)',
    ]
  }

  _onSparePartIdChange(sparePartId, sparePartsLoaded, foldersLoaded) {
    if (this.store && sparePartsLoaded && foldersLoaded) {
      this.sparePart = this.store.getSparePart(sparePartId) || undefined
    }
  }

  _onSparePartChange(sparePart) {
    let sparePartId = null
    if (sparePart) {
      sparePartId = sparePart.id
    }
    this.sparePartId = sparePartId
  }
}
customElements.define(GetSparePart.is, GetSparePart)
