const $_documentContainer = document.createElement('div')
$_documentContainer.setAttribute('style', 'display: none;')

$_documentContainer.innerHTML = `<dom-module id="wolf-selectable-text">
  <template>
    <style is="custom-style">
      .wolf-selectable-text {
        cursor: text;
        -webkit-user-select: text;  /* Chrome all / Safari all */
        -moz-user-select: text;     /* Firefox all */
        -ms-user-select: text;      /* IE 10+ */
        user-select: text;
      }
    </style>
  </template>
</dom-module>`

document.head.appendChild($_documentContainer)
