import loadDefinition from './sections/definition.js'
import loadSpareParts from './sections/spare-parts.js'
import loadFolders from './sections/folders.js'
import loadErrorCodes from './sections/error-codes.js'
import watchable from '../mixins/watchable.js'
import translated from '../mixins/translated.js'
import shops, { allowedFromShopURLDomains } from './shops.js'

export default class Store extends translated(watchable(class {})) {
  constructor() {
    super()

    this._sections = {
      definition: {
        loadFn: loadDefinition,
        promise: undefined,
      },
      spareParts: {
        loadFn: loadSpareParts,
        promise: undefined,
      },
      folders: {
        loadFn: loadFolders,
        promise: undefined,
      },
      errorCodes: {
        loadFn: loadErrorCodes,
        promise: undefined,
      },
    }
    this.status = {
      definition: {
        loading: false,
        loaded: false,
        progress: undefined,
      },
      spareParts: {
        loading: false,
        loaded: false,
        progress: undefined,
      },
      folders: {
        loading: false,
        loaded: false,
        progress: undefined,
      },
      errorCodes: {
        loading: false,
        loaded: false,
        progress: undefined,
      },
    }

    this.createdOn = undefined // Date
    this.filePaths = undefined // { spareParts: 'data/spare-pa...', ... }

    this._sparePartMap = new Map()
    this.sparePartPhotoCount = 0
    this.pricesAsOfDateString = undefined

    this._folderMap = new Map()
    this.rootFolder = undefined
    this._folders = []
    this.explodedViewCount = 0

    this._quantitiesInCart = JSON.parse(localStorage.getItem('cart') || '{}')
    this._recentSearchTerms = JSON.parse(localStorage.getItem('recentSearchTerms') || '[]')
    this._favorites = JSON.parse(localStorage.getItem('favorites') || '[]')
    this._favoriteSparePartIds = new Set(
      this._favorites.filter((x) => x.type === 'sparePart').map((x) => x.id)
    )
    this._favoriteFolderIds = new Set(
      this._favorites.filter((x) => x.type === 'folder').map((x) => x.id)
    )

    this._orderMethod = JSON.parse(localStorage.getItem('orderMethod') || '"shop"')

    this._selectedShopForSession = JSON.parse(sessionStorage.getItem('selectedShop'))
    if (this._selectedShopForSession) {
      console.log(
        `Continuing to use shop "${JSON.stringify(
          this._selectedShopForSession
        )}". Email order method is disabled.`
      )
    }
  }

  async load(sectionNames) {
    if (typeof sectionNames === 'string') {
      sectionNames = [sectionNames]
    }

    const promises = []
    for (let sectionName of sectionNames) {
      const section = this._sections[sectionName]
      const sectionStatus = this.status[sectionName]

      if (!section || !sectionStatus) {
        throw new Error(`Section "${sectionName}" does not exist`)
      }

      if (!sectionStatus.loaded) {
        if (!section._promise) {
          sectionStatus.loading = true
          this.notifyChange(`status.${sectionName}.loading`)

          const progressCallback = (progress) => {
            sectionStatus.progress = progress
            this.notifyChange(`status.${sectionName}.progress`)
          }

          section._promise = section.loadFn(this, progressCallback).then(() => {
            sectionStatus.loaded = true
            sectionStatus.loading = false
            sectionStatus.progress = 100
            this.notifyChange(`status.${sectionName}.loaded`)
            this.notifyChange(`status.${sectionName}.loading`)
            this.notifyChange(`status.${sectionName}.progress`)
          })
        }

        promises.push(section._promise)
      }
    }

    if (promises.length > 0) {
      this.loading = true
      this.notifyChange('loading')

      await Promise.all(promises)

      let loading = false
      for (let sectionName of Object.keys(this.status)) {
        loading = loading || this.status[sectionName].loading
      }
      if (this.loading !== loading) {
        this.loading = loading
        this.notifyChange('loading')
      }
    }
  }

  getSparePart(id) {
    return this._sparePartMap.get(id)
  }

  getFolder(id) {
    return this._folderMap.get(id)
  }

  search(searchTerm, folder) {
    if (!this.rootFolder) {
      return []
    }

    const results = []

    const escapedSearchTerm = escapeRegExp(searchTerm)
    const startsWithRegExp = RegExp('^' + escapedSearchTerm + '.*', 'i')
    const wordInsideRegExp = RegExp('.*[ -/]' + escapedSearchTerm + '.*', 'i')
    const anywhereInsideRegExp = RegExp('.*' + escapedSearchTerm + '.*', 'i')

    const isSearchingInsideFolder = !!folder
    const searchFolder = isSearchingInsideFolder ? folder : this.rootFolder
    const sparePartsInsideFolder = new Set()

    for (let folder of searchFolder.iter()) {
      let weight = 0

      const title = folder.title // ToDo: Subtitle

      for (let keyword of folder.barcodes || []) {
        if (keyword.match(startsWithRegExp)) {
          weight = 0.2
          break
        }
      }

      if (weight < 0.5 && title.match(anywhereInsideRegExp)) {
        weight = 0.5
        if (title.match(startsWithRegExp)) {
          weight = 0.9
        } else if (title.match(wordInsideRegExp)) {
          weight = 0.8
        }
      }

      if (weight > 0) {
        results.push({ weight: weight, model: folder })
      }

      if (isSearchingInsideFolder) {
        for (let sparePart of folder.spareParts) {
          sparePartsInsideFolder.add(sparePart)
        }
      }
    }

    for (let sparePart of isSearchingInsideFolder
      ? sparePartsInsideFolder
      : this._sparePartMap.values()) {
      let weight = 0

      const name = sparePart.name
      if (weight < 0.3 && name.match(anywhereInsideRegExp)) {
        // Name
        if (name.match(startsWithRegExp)) {
          weight = 0.7
        } else if (name.match(wordInsideRegExp)) {
          weight = 0.4
        } else {
          weight = 0.3
        }
      }
      if (weight < 0.2 && sparePart.id.match(startsWithRegExp)) {
        // SKU
        weight = 0.2
      }
      if (weight < 0.2 && sparePart.ean && sparePart.ean.match(startsWithRegExp)) {
        // EAN
        weight = 0.2
      }

      if (sparePart.discontinued) {
        weight *= 0.1
      }

      if (weight > 0) {
        results.push({ weight: weight, model: sparePart })
      }
    }

    results.sort(function (a, b) {
      return b.weight - a.weight
    })

    return Array.from(new Set(results.map((x) => x.model)))
  }

  _getQuantityInCart(id) {
    return this._quantitiesInCart[id] || 0
  }

  _setQuantityInCart(id, count) {
    if (count > 0) {
      this._quantitiesInCart[id] = count
    } else {
      delete this._quantitiesInCart[id]
    }

    localStorage.setItem('cart', JSON.stringify(this._quantitiesInCart))

    this._previousQuantitiesInCart = undefined

    this.notifyChange('cartSpareParts')
    this.notifyChange('numItemsInCart')
    this.notifyChange('canRestoreCart')
  }

  get numItemsInCart() {
    return Object.keys(this._quantitiesInCart).reduce((sum, k) => {
      return sum + this._quantitiesInCart[k]
    }, 0)
  }

  get cartSpareParts() {
    return Object.keys(this._quantitiesInCart).map((id) => this.getSparePart(id))
  }

  clearCart() {
    this._previousQuantitiesInCart = this._quantitiesInCart

    const formerCartSpareParts = this.cartSpareParts

    this._quantitiesInCart = {}
    localStorage.setItem('cart', JSON.stringify(this._quantitiesInCart))
    this.notifyChange('cartSpareParts')
    this.notifyChange('numItemsInCart')
    this.notifyChange('canRestoreCart')

    formerCartSpareParts.forEach((sparePart) => {
      sparePart.notifyChange('quantityInCart')
    })
  }

  restoreCart() {
    if (!this._previousQuantitiesInCart) {
      return
    }

    this._quantitiesInCart = this._previousQuantitiesInCart
    this._previousQuantitiesInCart = undefined
    localStorage.setItem('cart', JSON.stringify(this._quantitiesInCart))

    this.notifyChange('cartSpareParts')
    this.notifyChange('numItemsInCart')
    this.notifyChange('canRestoreCart')

    this.cartSpareParts.forEach((sparePart) => {
      sparePart.notifyChange('quantityInCart')
    })
  }

  get canRestoreCart() {
    return !!this._previousQuantitiesInCart
  }

  _getIsFavorite(type, id) {
    switch (type) {
      case 'sparePart':
        return this._favoriteSparePartIds.has(id)
      case 'folder':
        return this._favoriteFolderIds.has(id)
      default:
        return false
    }
  }

  _addToFavorites(type, id, value) {
    switch (type) {
      case 'sparePart':
        this._favoriteSparePartIds.add(id)
        break
      case 'folder':
        this._favoriteFolderIds.add(id)
        break
      default:
        return
    }

    // Remove from favorites
    this._favorites = this._favorites.filter((x) => x.type !== type || x.id !== id)

    // Add to top of favorties
    this._favorites.unshift({ type, id })

    localStorage.setItem('favorites', JSON.stringify(this._favorites))

    this.notifyChange('favorites')
  }

  _deleteFromFavorites(type, id, value) {
    switch (type) {
      case 'sparePart':
        this._favoriteSparePartIds.delete(id)
        break
      case 'folder':
        this._favoriteFolderIds.delete(id)
        break
      default:
        return
    }

    // Remove from favorites
    this._favorites = this._favorites.filter((x) => x.type !== type || x.id !== id)

    localStorage.setItem('favorites', JSON.stringify(this._favorites))

    this.notifyChange('favorites')
  }

  deleteAllFavorites() {
    const formerFavorites = this.favorites

    this._favoriteSparePartIds = new Set()
    this._favoriteFolderIds = new Set()
    this._favorites = []

    localStorage.setItem('favorites', JSON.stringify(this._favorites))
    this.notifyChange('favorites')

    formerFavorites.forEach((sparePart) => {
      sparePart.notifyChange('isFavorite')
    })
  }

  get favorites() {
    return this._favorites.map(({ type, id }) => {
      switch (type) {
        case 'sparePart':
          return this.getSparePart(id)
        case 'folder':
          return this.getFolder(id)
      }
    })
  }

  get sparePartCount() {
    return this._sparePartMap.size
  }

  get folderCount() {
    return this._folders.length
  }

  get recentSearchTerms() {
    return this._recentSearchTerms
  }

  addRecentSearchTerm(searchTerm) {
    this._recentSearchTerms = [
      searchTerm,
      ...this._recentSearchTerms.filter((x) => x !== searchTerm),
    ]
    localStorage.setItem('recentSearchTerms', JSON.stringify(this._recentSearchTerms))
    this.notifyChange('recentSearchTerms')
  }

  deleteRecentSearchTerm(searchTerm) {
    this._recentSearchTerms = this._recentSearchTerms.filter((x) => x !== searchTerm)

    localStorage.setItem('recentSearchTerms', JSON.stringify(this._recentSearchTerms))
    this.notifyChange('recentSearchTerms')
  }

  deleteAllRecentSearchTerms() {
    this._recentSearchTerms = []

    localStorage.setItem('recentSearchTerms', JSON.stringify(this._recentSearchTerms))
    this.notifyChange('recentSearchTerms')
  }

  get isEmailOrderMethodAvailable() {
    return !this._selectedShopForSession
  }

  selectedShopAndDisableEmailOrderMethodForCurrentSession(selectedShop) {
    if (selectedShop.id) {
      const foundShop = shops.find((shop) => shop.id === selectedShop.id)
      if (!foundShop) {
        console.error(`Could not find defintion for shop id "${selectedShop.id}"`)
        return
      }
    } else if (selectedShop.url) {
      const errorMsg = `The url "${selectedShop.url}" in the from-shop-url param is invalid. Make sure it starts with "https://", ends in "/" or "=", and that the value is URL encoded. Example: https://www.wolfserviceapp.com/#/catalog?from-shop-url=https%3A%2F%2Fwww.example.com%2F`

      if (
        !selectedShop.url.startsWith('https://') ||
        !(selectedShop.url.endsWith('/') || selectedShop.url.endsWith('='))
      ) {
        console.error(errorMsg)
        return
      }

      let parsedURL
      try {
        parsedURL = new URL(selectedShop.url)
      } catch (e) {
        console.error(errorMsg)
        return
      }

      const foundDomain = allowedFromShopURLDomains.find((domain) => domain == parsedURL.hostname)
      if (!foundDomain) {
        console.error(`The domain of "${selectedShop.url}" is not allowed`)
        return
      }
    } else {
      return
    }

    this._selectedShopForSession = selectedShop
    sessionStorage.setItem('selectedShop', JSON.stringify(this._selectedShopForSession))
    this.notifyChange('isEmailOrderMethodAvailable')
    this.notifyChange('shop')
    this.notifyChange('orderMethod')
  }

  get shop() {
    if (this._selectedShopForSession) {
      if (this._selectedShopForSession.id) {
        return shops.find((shop) => shop.id === this._selectedShopForSession.id)
      } else if (this._selectedShopForSession.url) {
        return {
          id: undefined,
          img: undefined,
          makeURL: (order) => `${this._selectedShopForSession.url}${order}`,
        }
      }
    } else {
      return shops.find((shop) => (shop.automaticallyShownInLocales || []).includes(this.locale))
    }
  }

  get orderMethod() {
    if (this._orderMethod === 'shop' && this.shop) {
      return 'shop'
    }
    if (this._orderMethod === 'email' && this.isEmailOrderMethodAvailable) {
      return 'email'
    }
    if (this.shop) {
      return 'shop'
    }
    return 'email'
  }

  set orderMethod(orderMethod) {
    this._orderMethod = orderMethod
    localStorage.setItem('orderMethod', JSON.stringify(this._orderMethod))
    this.notifyChange('orderMethod')
  }
}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}
