export default class ErrorCodeInstruction {
  constructor(store, data) {
    this.store = store

    this.errorCode = data.errorCode
    this.products = data.products

    this.issue = data.issue
    this.cause = data.cause
    this.solution = data.solution

    this.productsString = data.products.join(', ')
  }
}
