import { PolymerElement, html } from '@polymer/polymer/polymer-element.js'
import '@polymer/iron-media-query/iron-media-query.js'
import '@polymer/app-layout/app-toolbar/app-toolbar.js'
import '@polymer/paper-icon-button/paper-icon-button.js'
import '../../../utils/wolf-colors.js'
import '../../../utils/wolf-watch-object.js'
import '../../../utils/wolf-icons.js'
import '../../../utils/wolf-app-toolbar'
import './catalog-toolbar-breadcrumbs.js'
import navigate from '../../../mixins/navigate.js'

class CatalogTooblar extends navigate(PolymerElement) {
  static get template() {
    return html([require('./catalog-toolbar.html')])
  }

  static get is() {
    return 'catalog-toolbar'
  }

  static get properties() {
    return {
      store: Object,
      folder: Object,
      wide: { type: Boolean, reflectToAttribute: true },
      loading: { type: Boolean, value: true, reflectToAttribute: true },
    }
  }

  _computeMiddleButtonClass(wide) {
    return wide ? '' : 'middle'
  }

  _computeToolbarClass(wide) {
    return wide ? '' : 'medium-tall'
  }

  _computeDisableBack(folder) {
    return !folder || !folder.parent
  }

  _openSearch() {
    if (this.folder.parent) {
      this.navigateToSearch(this.folder)
    } else {
      this.navigateToSearch()
    }
  }

  _showHome() {
    this.navigateTo('/')
  }

  _openCart() {
    this.navigateTo('/catalog/cart')
  }

  _openFavorites() {
    this.navigateTo('/catalog/favorites')
  }

  _onBack() {
    if (this.folder && this.folder.parent) {
      var backFolder = this.folder.parent
      if (backFolder.parent && backFolder.parent.style.depth === 2) {
        backFolder = backFolder.parent
      }
      this.navigateToFolder(backFolder)
    }
  }
}
customElements.define(CatalogTooblar.is, CatalogTooblar)
