import { PolymerElement, html } from '@polymer/polymer/polymer-element.js'
import '@polymer/app-layout/app-toolbar/app-toolbar.js'
import '@polymer/paper-icon-button/paper-icon-button.js'
import '@polymer/paper-button/paper-button.js'
import '@polymer/paper-input/paper-input.js'
import '../../../utils/wolf-colors.js'
import '../../../utils/wolf-icons.js'
import '../../../utils/wolf-app-toolbar'
import { canReadBarcode, readBarcode } from '../../../utils/barcode-reading.js'
import '../../../elements/rows/folder-row.js'
import '../../../elements/rows/spare-part-row.js'
import SparePart from '../../../data/models/spare-part.js'
import Folder from '../../../data/models/folder.js'
import translated from '../../../mixins/translated.js'
import navigate from '../../../mixins/navigate.js'
import './catalog-search-recent-search-terms.js'
import '../../../data/elements/get-folder.js'

class CatalogSearchPage extends navigate(translated(PolymerElement)) {
  static get template() {
    return html([require('./catalog-search-page.html')])
  }

  static get is() {
    return 'catalog-search-page'
  }

  static get properties() {
    return {
      store: { type: Object },
      _loading: { type: Boolean, value: true },
      searchTerm: { type: String, value: '', notify: true },
      searchResults: {
        type: Array,
        value() {
          return []
        },
      },
      showBarcodeButton: {
        value() {
          return canReadBarcode()
        },
        reflectToAttribute: true,
      },
      _folder: { type: Object },
      folderPath: { type: String, notify: true },
    }
  }

  ready() {
    super.ready()

    this.store.load(['folders', 'spareParts']).then(() => (this._loading = false))

    this.addEventListener('animated-pages-page-will-enter', this._onWillEnter)
    this.addEventListener('wolf-back', this._onBack)
  }

  static get observers() {
    return ['_onSearchTermChanged(searchTerm, _loading, _folder)']
  }

  _onSearchTermChanged(searchTerm) {
    if (searchTerm) {
      this.searchResults = this.store.search(searchTerm, this._folder).slice(0, 100)
    } else {
      this.searchResults = []
    }
  }

  _onWillEnter() {
    Promise.resolve().then(() => this.$.searchField.focus())
  }

  _onTappedClearButton() {
    this.searchTerm = ''
    this.$.searchField.focus()
  }

  _onListTouchStart() {
    this.$.searchField.blur()
  }

  _onTappedBarcodeButton() {
    readBarcode()
      .then((code) => (this.searchTerm = code))
      .catch((error) => console.error(error))
  }

  _isSparePart(model) {
    return model instanceof SparePart
  }
  _isFolder(model) {
    return model instanceof Folder
  }

  _onTappedSparePart(event) {
    this.store.addRecentSearchTerm(this.searchTerm)
    const sparePart = event.currentTarget.sparePart
    this.navigateToSparePart(sparePart, 'search')
  }

  _onTappedFolder(event) {
    this.store.addRecentSearchTerm(this.searchTerm)
    const folder = event.currentTarget.folder
    this.navigateToFolder(folder)
  }

  _onBack() {
    this.navigateTo('/catalog')
  }

  _computeShowRecentSearchTerms(searchTerm, recentSearchTerms) {
    return !searchTerm && recentSearchTerms.length > 0
  }

  _removeSearchLimit() {
    this._folder = null
  }

  _computePathWithSplitChars(path) {
    return path.replace(/\//g, '/\u200B')
  }
}
customElements.define(CatalogSearchPage.is, CatalogSearchPage)
