import { PolymerElement, html } from '@polymer/polymer/polymer-element.js'
import '../../utils/wolf-colors.js'
import '../../utils/wolf-icons.js'

class ExplodedViewPoint extends PolymerElement {
  static get template() {
    return html([require('./exploded-view-point.html')])
  }

  static get is() {
    return 'exploded-view-point'
  }

  static get properties() {
    return {
      x: { type: Number },
      y: { type: Number },
      diameter: { type: Number },
      sparePart: { type: Object },
      disabled: {
        type: Boolean,
        computed: '_computeDisabled(sparePart)',
        reflectToAttribute: true,
        value: true,
        readOnly: true,
      },
      active: { type: Boolean, reflectToAttribute: true, value: false },
    }
  }

  static get observers() {
    return ['_updateStyle(x, y, diameter)']
  }

  ready() {
    super.ready()
    this.addEventListener('tap', this._onTap)
  }

  _updateStyle(x, y, diameter) {
    this.style.left = x + 'px'
    this.style.top = y + 'px'
    this.style.width = diameter + 'px'
    this.style.height = diameter + 'px'
  }

  _computeDisabled(sparePart) {
    return !sparePart
  }

  _onTap(event) {
    if (!this.sparePart) {
      event.stopPropagation()
    }
  }
}
customElements.define(ExplodedViewPoint.is, ExplodedViewPoint)
