import { PolymerElement, html } from '@polymer/polymer/polymer-element.js'
import '@polymer/app-layout/app-toolbar/app-toolbar.js'
import '@polymer/paper-icon-button/paper-icon-button.js'
import '@polymer/paper-ripple/paper-ripple.js'
import translated from '../../mixins/translated.js'
import '../../utils/wolf-colors.js'
import '../../utils/wolf-icons.js'
import '../../utils/wolf-app-toolbar'
import navigate from '../../mixins/navigate.js'

class AboutIndexSection extends navigate(translated(PolymerElement)) {
  static get template() {
    return html([require('./about-index-section.html')])
  }

  static get is() {
    return 'about-index-section'
  }

  _showDocument(event) {
    const page = event.currentTarget.getAttribute('data-document')

    this.dispatchEvent(
      new CustomEvent('wolf-show-about-document', {
        detail: page,
        bubbles: true,
        composed: true,
      })
    )
  }

  _showHome() {
    this.navigateTo('/home')
  }
}
customElements.define(AboutIndexSection.is, AboutIndexSection)
