import { PolymerElement, html } from '@polymer/polymer/polymer-element'
import './wolf-colors.js'

function calcTransforms(startAngle, endAngle) {
  return {
    circleContainer: 'rotate(' + (-180 + 0.5 * (startAngle + endAngle)) + 'deg)',
    leftHalfCircle: 'rotate(' + (225 - 0.5 * (startAngle - endAngle)) + 'deg)',
    rightHalfCircle: 'rotate(' + (45 + 0.5 * (startAngle - endAngle)) + 'deg)',
  }
}

class WolfCircle extends PolymerElement {
  static get template() {
    return html([require('./wolf-circle.html')])
  }

  static get is() {
    return 'wolf-circle'
  }

  static get properties() {
    return {
      startAngle: { type: Number, value: 0 },
      endAngle: { type: Number, value: 360 },
      active: { type: Boolean, observer: '_onActiveChange' },
    }
  }

  static get observers() {
    return ['_update(startAngle, endAngle)']
  }

  _update(startAngle, endAngle) {
    var t = calcTransforms(startAngle, endAngle)
    this.$.circleContainer.style.transform = t.circleContainer
    this.$.leftHalfCircle.style.transform = t.leftHalfCircle
    this.$.rightHalfCircle.style.transform = t.rightHalfCircle

    var t2 = calcTransforms(startAngle, startAngle)
    this.$.circleContainerActive.style.transform = t2.circleContainer
    this.$.leftHalfCircleActive.style.transform = t2.leftHalfCircle
    this.$.rightHalfCircleActive.style.transform = t2.rightHalfCircle
  }

  _onActiveChange(active) {
    if (this._activeAnimation) {
      this._activeAnimation.reverse()
    } else {
      if (active) {
        this._activeAnimation = document.timeline.play(this.createActiveEffect())
      }
    }
  }

  createActiveEffect() {
    var effects = [
      this._createCircleEffect(
        [
          { startAngle: this.startAngle, endAngle: this.endAngle },
          { startAngle: this.endAngle, endAngle: this.endAngle },
        ],
        { duration: 250, easing: 'ease-in', fill: 'both' },
        ''
      ),

      this._createCircleEffect(
        [
          { startAngle: this.startAngle, endAngle: this.startAngle },
          { startAngle: this.startAngle, endAngle: this.endAngle },
        ],
        { duration: 250, easing: 'ease-out', fill: 'both' },
        'Active'
      ),
    ]
    return new SequenceEffect(effects)
  }

  _createCircleEffect(keyframes, options, suffix) {
    var t0 = calcTransforms(keyframes[0].startAngle, keyframes[0].endAngle)
    var t1 = calcTransforms(keyframes[1].startAngle, keyframes[1].endAngle)
    var names = ['circleContainer', 'leftHalfCircle', 'rightHalfCircle']
    var keyframeEffects = names.map(
      function (x) {
        return new KeyframeEffect(
          this.$[x + suffix],
          [{ transform: t0[x] }, { transform: t1[x] }],
          options
        )
      }.bind(this)
    )
    return new GroupEffect(keyframeEffects)
  }
}
customElements.define(WolfCircle.is, WolfCircle)
