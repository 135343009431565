import { PolymerElement, html } from '@polymer/polymer/polymer-element.js'
import '@polymer/app-layout/app-toolbar/app-toolbar.js'
import '@polymer/paper-button/paper-button.js'
import '@polymer/paper-icon-button/paper-icon-button.js'
import '../../../utils/wolf-colors.js'
import '../../../utils/wolf-icons.js'
import '../../../utils/wolf-app-toolbar'
import '../../../elements/rows/spare-part-row.js'
import '../../../elements/rows/folder-row.js'
import SparePart from '../../../data/models/spare-part.js'
import Folder from '../../../data/models/folder.js'
import translated from '../../../mixins/translated.js'
import navigate from '../../../mixins/navigate.js'

class CatalogFavoritesPage extends navigate(translated(PolymerElement)) {
  static get template() {
    return html([require('./catalog-favorites-page.html')])
  }

  static get is() {
    return 'catalog-favorites-page'
  }

  static get properties() {
    return {
      store: Object,
      _loading: {
        type: Boolean,
        reflectToAttribute: true,
        value: true,
      },
      _editMode: { value: false },
    }
  }

  ready() {
    super.ready()
    this.store.load(['folders', 'spareParts']).then(() => (this._loading = false))
    this.addEventListener('wolf-back', this._onBack)
    this.addEventListener('animated-pages-page-will-enter', this._onWillEnter)
  }

  _onWillEnter() {
    this._editMode = false
  }

  _onTappedClearButton() {
    if (this.store) {
      this.store.clearCart()
    }
  }

  _onOrder() {
    this.navigateTo('/catalog/order')
  }

  _onBack() {
    this.navigateTo('/catalog')
  }

  _onTappedSparePart(event) {
    if (this._editMode) {
      return
    }
    const sparePart = event.currentTarget.sparePart
    this.navigateToSparePart(sparePart, 'favorites')
  }

  _onTappedFolder(event) {
    if (this._editMode) {
      return
    }
    const folder = event.currentTarget.folder
    this.navigateToFolder(folder, 'favorites')
  }

  _isSparePart(model) {
    return model instanceof SparePart
  }
  _isFolder(model) {
    return model instanceof Folder
  }

  _deleteFolderFromFavorites(event) {
    const folder = event.currentTarget.folder
    folder.deleteFromFavorites()
  }

  _deleteSparePartFromFavorites(event) {
    const sparePart = event.currentTarget.sparePart
    sparePart.deleteFromFavorites()
  }

  _onEditButtonTapped() {
    this._editMode = true
  }

  _onDoneButtonTapped() {
    this._editMode = false
  }

  _onDeleteAllButtonTapped() {
    this.store.deleteAllFavorites()
  }
}
customElements.define(CatalogFavoritesPage.is, CatalogFavoritesPage)
