const $_documentContainer = document.createElement('div')
$_documentContainer.setAttribute('style', 'display: none;')

$_documentContainer.innerHTML = `<iron-iconset-svg name="wolf" size="24">
<svg><defs>
<g id="no-photo"><path d="M23.653,22.239L1.761,0.347L0.347,1.761l2.776,2.776C3.079,4.701,3.054,4.874,3.054,5.054v14c0,1.1,0.899,2,2,2h13.999 c0.18,0,0.353-0.025,0.517-0.07l2.669,2.67L23.653,22.239z M5.054,18.054l3.5-4.5l2.5,3.01l1.79-2.307l3.795,3.797H5.054z"></path><path d="M21.053,5.054c0-1.101-0.899-2-2-2H6.575l14.478,14.478V5.054z"></path></g>
<g id="hollow-cart"><path d="M18.3,5.463l-0.716-0.078l0.246-2.297c0.021-0.195,0.197-0.338,0.395-0.32l4.133,0.412 c0.185,0.021,0.324,0.174,0.324,0.359v1.58h-0.721V3.865l-3.452-0.344L18.3,5.463z"></path> <circle cx="14.896" cy="19.614" r="1.62"></circle><circle cx="5.54" cy="19.614" r="1.62"></circle><path d="M16.689,17.355H3.249v-0.722h13.44c0.799,0,1.201-0.45,1.238-0.872c0.03-0.351-0.188-0.806-0.908-0.935l0.125-0.707 c1.123,0.198,1.563,0.998,1.502,1.702C18.58,16.563,17.941,17.355,16.689,17.355z"></path> <path d="M17.082,14.834c-0.006,0-0.014,0-0.021,0L2.522,13.99c-0.177-0.01-0.32-0.148-0.337-0.324L1.32,4.793 C1.31,4.691,1.344,4.592,1.411,4.518c0.068-0.076,0.165-0.119,0.267-0.119h16.33c0.102,0,0.197,0.043,0.266,0.119 c0.068,0.074,0.103,0.174,0.093,0.275l-0.927,9.717C17.423,14.695,17.268,14.834,17.082,14.834z M2.871,13.289l13.886,0.807 l0.854-8.977H2.075L2.871,13.289z"></path></g>
<g id="spare-part"><circle cx="12.041" cy="11.922" r="1.878"></circle><path d="M12.94,4.002c-0.624,-0.074 -1.256,-0.074 -1.88,0l-0.48,1.823c-0.48,0.112 -0.945,0.281 -1.386,0.504l-1.538,-1.088c-0.527,0.345 -1.01,0.75 -1.441,1.209l0.804,1.704c-0.296,0.395 -0.543,0.824 -0.737,1.277l-1.878,0.156c-0.182,0.602 -0.292,1.224 -0.327,1.852l1.711,0.789c0.028,0.493 0.114,0.98 0.257,1.452l-1.339,1.327c0.248,0.578 0.564,1.125 0.941,1.629l1.817,-0.496c0.338,0.359 0.717,0.677 1.13,0.948l-0.173,1.876c0.562,0.284 1.155,0.5 1.768,0.643l1.074,-1.548c0.49,0.058 0.984,0.058 1.474,0l1.074,1.548c0.613,-0.143 1.206,-0.359 1.768,-0.643l-0.173,-1.876c0.413,-0.271 0.792,-0.589 1.13,-0.948l1.817,0.496c0.377,-0.504 0.693,-1.051 0.941,-1.629l-1.339,-1.327c0.143,-0.472 0.229,-0.959 0.257,-1.452l1.711,-0.789c-0.035,-0.628 -0.145,-1.25 -0.327,-1.852l-1.878,-0.156c-0.194,-0.453 -0.441,-0.882 -0.737,-1.277l0.804,-1.704c-0.431,-0.459 -0.914,-0.864 -1.441,-1.209l-1.538,1.088c-0.441,-0.223 -0.906,-0.392 -1.386,-0.504l-0.48,-1.823Zm-0.94,3.38c2.483,0 4.499,2.016 4.499,4.5c0,2.483 -2.016,4.499 -4.499,4.499c-2.483,0 -4.499,-2.016 -4.499,-4.499c0,-2.484 2.016,-4.5 4.499,-4.5Z"></path></g>
<g id="catalog"><path d="M21.885,3.92l-0.466-0.315c-0.377-0.208-2.393-1.241-5.051-1.241c-1.985,0-3.454,0.452-4.316,0.817 C11.124,2.82,9.603,2.364,7.84,2.364c-3.268,0-5.125,1.207-5.329,1.347l-0.811,0.56L1.704,20.57l2.754-1.477 c0.162-0.085,1.627-0.821,3.346-0.821c0.001,0,0.002,0,0.003,0c1.822,0.03,3.417,0.826,3.428,0.83l0.88,0.459l0.85-0.471 c0.16-0.084,1.605-0.818,3.319-0.818c0,0,0.001,0,0.002,0c1.808,0.03,3.353,0.817,3.354,0.817l2.659,1.488V4.207L21.885,3.92z M7.713,16.349c-1.927,0-3.611,0.772-4.087,1.011L3.624,5.281C3.822,5.157,5.298,4.288,7.84,4.288c1.282,0,2.415,0.293,3.198,0.569 v12.095c-0.792-0.276-1.933-0.58-3.199-0.602L7.713,16.349z M20.376,17.312c-0.553-0.26-2.161-0.93-4.057-0.962l-0.127-0.001 c-1.286,0-2.451,0.344-3.231,0.646V4.888c0.71-0.282,1.852-0.601,3.407-0.601c1.978,0,3.539,0.705,4.008,0.941V17.312z"></path></g>
<g id="error-code-inspector"><path d="M24.036,9.641c-0.072,0.344-0.393,0.833-0.732,0.958c-0.168,0.062-0.331,0.042-0.505,0.036 c-0.185-0.005-0.331-0.165-0.515-0.199c-0.356-0.064-0.752-0.163-1.103-0.168c-0.471-0.008-0.96,0.091-1.388,0.292 c-0.809,0.38-1.364,1.189-1.856,1.903c-0.57,0.828-1.131,1.662-1.722,2.475c-1.167,1.611-2.379,3.204-3.725,4.672 c-0.639,0.695-1.271,1.382-2.061,1.91c-0.344,0.23-0.717,0.397-1.083,0.59c-0.198,0.081-0.387,0.14-0.598,0.183 c-0.144,0.027-0.41,0.145-0.547,0.125c0.134-0.072-0.131-0.006-0.111-0.009c-0.126,0.022-0.252,0.047-0.38,0.065 c-0.223,0.035-0.417-0.03-0.635-0.014c-0.485,0.027-0.957-0.102-1.405-0.275c-0.806-0.31-1.593-0.974-2.009-1.732 c-0.938-1.711-0.539-3.787,0.017-5.56c0.139-0.444,0.285-0.892,0.498-1.307c0.246-0.483,0.77-0.461,1.263-0.473 c1.025-0.021,2.057,0.067,3.066,0.246c0.408,0.074,0.903,0.119,1.261,0.346c0.168,0.105,0.19,0.427,0.229,0.604 c0.062,0.285,0.098,0.574,0.146,0.862c0.07,0.423,0.147,1.003,0.37,1.377c0.141,0.236,0.236,0.163,0.451,0.069 c0.23-0.101,0.374-0.301,0.527-0.489c0.855-1.049,1.737-2.065,2.618-3.092c0.214-0.248,0.427-0.496,0.64-0.743 c0.225-0.261,0.459-0.611,0.739-0.813c0.267-0.192,0.638,0.049,0.933,0.018c0.3-0.032,0.538-0.33,0.752-0.515 c1.002-0.865,2.095-1.531,3.416-1.759c0.683-0.118,1.351-0.097,2.037-0.028c0.344,0.034,0.705,0.04,1.044,0.09 C23.896,9.318,24.092,9.386,24.036,9.641C24.021,9.716,24.078,9.451,24.036,9.641z M5.054,9.276 c1.385,0.509,3.162,0.331,4.275-0.698C10.3,7.679,10.324,6.287,9.362,5.37c-0.504-0.482-1.17-0.778-1.849-0.913 C7.349,4.423,7.182,4.4,7.016,4.386c-0.071-0.151-0.164-0.29-0.273-0.415C6.51,3.706,6.206,3.509,5.875,3.388 C4.609,2.923,2.666,3.69,2.915,5.277c-1.585,0.1-3.058,1.623-2.312,3.241C1.365,10.171,3.763,10.446,5.054,9.276 C5.521,9.449,4.563,9.721,5.054,9.276z M9.758,3.24c0.518,0,1.689-0.174,1.689-0.9c0-0.727-1.17-0.901-1.689-0.901 c-0.519,0-1.69,0.175-1.69,0.901C8.068,3.065,9.24,3.24,9.758,3.24z"></path></g>
<g id="folder"><path d="M3.6,19.2l0,-9.6l1.8,0l0,7.8l13.2,0l0,-9l-15,0l0,-3.6l5.4,0l1.2,1.8l10.2,0l0,12.6l-16.8,0Z"></path></g>
<g id="back"><polygon points="13.942,22.336 7.006,12 13.942,1.664 16.433,3.336 10.619,12 16.433,20.664 	"></polygon></g>
<g id="expanded"><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path></g>
<g id="expand"><path d="M8.295,7.705l4.58,4.59l-4.58,4.59l1.41,1.41l6,-6l-6,-6l-1.41,1.41Z"></path></g>

<!-- From iron-icons -->
<g id="arrow-back"><path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path></g>
<g id="chevron-right"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></g>
<g id="camera-alt"><circle cx="12" cy="12" r="3.2"></circle><path d="M9 2L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"></path></g>
<g id="home"><path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"></path></g>
<g id="search"><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path></g>
<g id="shopping-cart"><path d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"></path></g>
<g id="clear"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></g>
<g id="menu"><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path></g>
<g id="add"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path></g>
<g id="remove"><path d="M19 13H5v-2h14v2z"></path></g>
<g id="info"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path></g>
<g id="warning"><path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"></path></g>
<g id="history"><path d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.28 2.54.72-1.21-3.5-2.08V8H12z"></path></g>
<g id="delete"><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"></path></g>
<g id="star"><path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path></g>
<g id="star-border"><path d="M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"></path></g>
<g id="share"><path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z"></path></g>
</defs></svg>
</iron-iconset-svg>`

document.head.appendChild($_documentContainer)
