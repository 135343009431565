import { PolymerElement, html } from '@polymer/polymer/polymer-element.js'
import './wolf-colors.js'

const headElement = document.createElement('div')
headElement.setAttribute('style', 'display: none;')
headElement.innerHTML = `
<custom-style>
  <style is="custom-style">
    :root {
      --wolf-heading-bar: {
        width: 65px;
        height: 3px;
        background: var(--wolf-red);
      };
    }
  </style>
</custom-style>`
document.head.appendChild(headElement)

class WolfHeadingBar extends PolymerElement {
  static get template() {
    return html`
      <style>
        :host {
          @apply --wolf-heading-bar;
          display: block;
          margin: 15px 0 22px;
        }
      </style>
    `
  }

  static get is() {
    return 'wolf-heading-bar'
  }
}
customElements.define(WolfHeadingBar.is, WolfHeadingBar)
